
@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after{font-family:Flaticon;font-style:normal;}

.wt-icon-box-xl [class^="flaticon-"]:before, .wt-icon-box-xl [class*=" flaticon-"]:before,
.wt-icon-box-xl [class^="flaticon-"]:after, .wt-icon-box-xl [class*=" flaticon-"]:after{font-size:96px;}
.wt-icon-box-lg [class^="flaticon-"]:before, .wt-icon-box-lg [class*=" flaticon-"]:before,
.wt-icon-box-lg [class^="flaticon-"]:after, .wt-icon-box-lg [class*=" flaticon-"]:after{font-size:72px;}
.wt-icon-box-md [class^="flaticon-"]:before, .wt-icon-box-md [class*=" flaticon-"]:before,
.wt-icon-box-md [class^="flaticon-"]:after, .wt-icon-box-md [class*=" flaticon-"]:after{font-size:48px;}
.wt-icon-box-sm [class^="flaticon-"]:before, .wt-icon-box-sm [class*=" flaticon-"]:before,
.wt-icon-box-sm [class^="flaticon-"]:after, .wt-icon-box-sm [class*=" flaticon-"]:after{font-size:36px;}
.wt-icon-box-xs [class^="flaticon-"]:before, .wt-icon-box-xs [class*=" flaticon-"]:before,
.wt-icon-box-xs [class^="flaticon-"]:after, .wt-icon-box-xs [class*=" flaticon-"]:after{font-size:24px;}




.flaticon-drone:before { content: "\f100"; }
.flaticon-remote-control:before { content: "\f101"; }
.flaticon-location:before { content: "\f102"; }
.flaticon-email:before { content: "\f103"; }
.flaticon-call:before { content: "\f104"; }
.flaticon-mobile-phone:before { content: "\f105"; }
.flaticon-power-supply:before { content: "\f106"; }
.flaticon-drone-1:before { content: "\f107"; }
.flaticon-drone-2:before { content: "\f108"; }
.flaticon-drone-3:before { content: "\f109"; }
.flaticon-drone-4:before { content: "\f10a"; }
.flaticon-drone-5:before { content: "\f10b"; }
.flaticon-drone-6:before { content: "\f10c"; }
.flaticon-drone-7:before { content: "\f10d"; }
.flaticon-remote-control-1:before { content: "\f10e"; }
.flaticon-control:before { content: "\f10f"; }
.flaticon-viewfinder:before { content: "\f110"; }
.flaticon-quality:before { content: "\f111"; }
.flaticon-number-one:before { content: "\f111"; }
.flaticon-shield:before { content: "\f112"; }
.flaticon-premium:before { content: "\f113"; }
.flaticon-heart:before { content: "\f114"; }
.flaticon-shopping-cart:before { content: "\f115"; }
.flaticon-quote:before { content: "\f116"; }
.flaticon-arrows:before { content: "\f117"; }
.flaticon-panorama:before { content: "\f118"; }
.flaticon-point-to:before { content: "\f119"; }
.flaticon-null:before { content: "\f11a"; }
.flaticon-chain-links:before { content: "\f11b"; }
.flaticon-drone-8:before { content: "\f11c"; }
.flaticon-virtual-reality:before { content: "\f11d"; }
.flaticon-drone-9:before { content: "\f11e"; }
.flaticon-worker:before { content: "\f11f"; }




