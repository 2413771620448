/*
==========================
Author: thewebmax team
Version: 1.0  
==========================
*/

/*======================================
1. GENERAL 
======================================*/
/*
@import url("skin/skin-1.css");
@import url("skin/skin-2.css");
@import url("skin/skin-3.css");
@import url("skin/skin-4.css");
@import url("skin/skin-5.css");
@import url("skin/skin-6.css");
@import url("skin/skin-7.css");
@import url("skin/skin-8.css");
@import url("skin/skin-9.css");
@import url("skin/skin-10.css");
@import url("skin/skin-11.css");
@import url("skin/skin-12.css");
*/

body {
  background-color: #fff;
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  color: #404040;
  padding: 0px;
  margin: 0px;
  overflow-x: hidden;
  font-size: 16px;
}

/* For WebKit browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  background-color: transparent;
}

/* The track (background of the scrollbar) */
::-webkit-scrollbar-track {
  background: transparent; /* Background color of the track */
  border-radius: 3px; /* Optional: rounded edges */
}

/* The scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: red; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Optional: rounded edges */
  border: 2px solid #f1f1f1; /* Optional: adds space around the thumb */
}

/* Scrollbar thumb when hovered */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color when hovered */
}

a {
  color: #111111;
  outline: 0 none;
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: 0 none;
}

a:active,
a:hover,
a:focus {
  color: inherit;
}

p a {
  color: #111111;
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

hr {
  clear: both;
}

section,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
summary {
  display: block;
}

address {
  font-style: italic;
}

table {
  background-color: transparent;
  width: 100%;
}

table thead th {
  padding: 12px 8px !important;
}

table td {
  padding: 15px 10px !important;
}

@media only screen and (max-width: 360px) {
  table td {
    word-break: break-all;
  }
}

p,
address,
pre,
hr,
ul,
ol,
dl,
dd,
table {
  margin-bottom: 24px;
}

.overflow-hide {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.clear {
  clear: both;
}

.pull-none {
  float: none;
}

::selection {
  background: #484848;
  color: #fff;
}

::-moz-selection {
  background: #484848;
  color: #fff;
}

::-webkit-selection {
  background: #484848;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .col-xs-100pc {
    width: 100%;
  }

  .wt-list-single-somefacts .col-xs-100pc {
    width: inherit !important;
  }

  .wt-list-single-somefacts .col-xs-100pc h1,
  .wt-list-single-somefacts .col-xs-100pc h2,
  .wt-list-single-somefacts .col-xs-100pc h3 {
    font-size: 22px;

    line-height: normal;
  }
  .wt-list-single-somefacts h1,
  .wt-list-single-somefacts h2,
  .wt-list-single-somefacts .col-xs-100pc h3 {
    font-size: 22px;
    line-height: normal;
  }

  body {
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {
  .wt-list-single-somefacts .col-xs-100pc {
    width: 100% !important;
  }
  .wt-list-single-somefacts-three .col-xs-4 {
    width: 100% !important;
  }
}

/*======================================
2. HEADING & TITLE 
======================================*/

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 15px;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 400;
  line-height: 1.1;
}
.title-style-2 {
  font-family: 'Rajdhani', sans-serif;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #000;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: #1a1a1a;
}

h1 {
  font-size: 66px;
  color: #1a1a1a;
  font-weight: 700;
}

h2 {
  font-size: 48px;
  color: #1a1a1a;
  font-weight: 700;
}

h3 {
  font-size: 24px;
  color: #1a1a1a;
  font-weight: 700;
}

h4 {
  font-size: 20px;
  color: #1a1a1a;
  font-weight: 700;
}

h5 {
  font-weight: normal;
  font-size: 18px;
  color: #1a1a1a;
  font-weight: 700;
}

h6 {
  font-weight: normal;
  font-size: 14px;
  color: #1a1a1a;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.display-block {
  display: block;
}

/*____BOLD TRANSPERENT TITLE____*/

.section-head {
  position: relative;
  z-index: 1;
}

.section-head h1:after,
.section-head h2:after,
.section-head h3:after {
  position: absolute;
  content: attr(data-title);
  color: #1a1a1a;
  opacity: 0.1;
  font-family: 'Rajdhani', sans-serif;
  font-size: 200px;
  line-height: 100px;
  text-transform: uppercase;
  bottom: -30px;
  left: 50%;
  width: 100%;
  z-index: -1;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.section-head.text-white h1:after,
.section-head.text-white h2:after,
.section-head.text-white h3:after {
  color: #c1c1c1;
}

@media only screen and (max-width: 991px) {
  .section-head h1:after,
  .section-head h2:after,
  .section-head h3:after {
    font-size: 74px;
    bottom: -10px;
  }
}

@media only screen and (max-width: 720px) {
  .section-head h1:after,
  .section-head h2:after,
  .section-head h3:after {
    display: none;
  }
}

/*======================================
3. TEXT & PARAGRAPH 
======================================*/

p {
  margin-bottom: 20px;
}

strong {
  font-weight: 600;
}

/*-------------------------------------
	1. text align
------------------------------------ */

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

/*-------------------------------------
	2. text vertical align
------------------------------------ */

.v-align-t {
  vertical-align: top;
}

.v-align-m {
  vertical-align: middle;
}

.v-align-b {
  vertical-align: bottom;
}

/*-------------------------------------
	3. text no line brack
------------------------------------ */

.text-nowrap {
  white-space: nowrap;
}

/*-------------------------------------
	4. text transform
------------------------------------ */

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

/*-------------------------------------
	5. text size
------------------------------------ */

.font-12 {
  font-size: 12px;
  line-height: 18px;
}

.font-14 {
  font-size: 14px;
  line-height: 20px;
}

.font-16 {
  font-size: 16px;
  line-height: 22px;
}

.font-18 {
  font-size: 18px;
  line-height: 24px;
}

.font-20 {
  font-size: 20px;
  line-height: 26px;
}

.font-22 {
  font-size: 22px;
  line-height: 28px;
}

.font-24 {
  font-size: 24px;
  line-height: 30px;
}

.font-26 {
  font-size: 26px;
  line-height: 32px;
}

.font-28 {
  font-size: 28px;
  line-height: 34px;
}

.font-30 {
  font-size: 30px;
  line-height: 36px;
}

.font-32 {
  font-size: 32px;
  line-height: 38px;
}

.font-34 {
  font-size: 34px;
  line-height: 40px;
}

.font-36 {
  font-size: 36px;
  line-height: 42px;
}

.font-38 {
  font-size: 38px;
  line-height: 44px;
}

.font-40 {
  font-size: 40px;
  line-height: 46px;
}

.font-45 {
  font-size: 46px;
  line-height: 52px;
}

.font-50 {
  font-size: 50px;
  line-height: 56px;
}

.font-60 {
  font-size: 60px;
  line-height: 66px;
}

.font-70 {
  font-size: 70px;
  line-height: 76px;
}

.font-80 {
  font-size: 80px;
  line-height: 86px;
}

.font-90 {
  font-size: 90px;
  line-height: 96px;
}

.font-100 {
  font-size: 100px;
  line-height: 106px;
}

.font-120 {
  font-size: 120px;
  line-height: 126px;
}

.font-140 {
  font-size: 140px;
  line-height: 146px;
}

.font-160 {
  font-size: 160px;
  line-height: 166px;
}

.font-180 {
  font-size: 180px;
  line-height: 186px;
}

/*-------------------------------------
	6. text bold
------------------------------------ */

.font-weight-100 {
  font-weight: 100;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

/*-------------------------------------
	7. Letter Spacing
------------------------------------ */

.letter-spacing-2 {
  letter-spacing: 2px;
}

.letter-spacing-3 {
  letter-spacing: 3px;
}

.letter-spacing-4 {
  letter-spacing: 4px;
}

.letter-spacing-5 {
  letter-spacing: 5px;
}

/*======================================
4. LISTING STYLES 
======================================*/

dl,
ul,
ol {
  list-style-position: outside;
  padding: 0;
}

ul,
ol {
  margin-bottom: 24px;
}

ul li,
ol li {
  padding: 0;
}

dl {
  margin-left: 0;
  margin-bottom: 30px;
}

dl dd {
  margin-bottom: 10px;
}

.list-simple li {
  margin-bottom: 10px;
}

.list-simple li ul {
  margin-left: 15px;
  margin-top: 10px;
}

.list-simple.list-unstyled li ul {
  margin-left: 30px;
  margin-top: 10px;
}

.list-circle,
.list-angle-right,
.list-arrow,
.list-check,
.list-checked,
.list-check-circle,
.list-chevron-circle,
.list-arrow-circle,
.list-times-circle {
  margin: 0 0 20px 0;
  padding: 0;
  list-style: none;
}

.list-circle li,
.list-angle-right li,
.list-arrow li,
.list-check li,
.list-checked li,
.list-check-circle li,
.list-chevron-circle li,
.list-arrow-circle li,
.list-times-circle li {
  padding: 5px 5px 5px 20px;
  position: relative;
}

.list-circle li:before,
.list-angle-right li:before,
.list-arrow li:before,
.list-check li:before,
.list-checked li:before,
.list-check-circle li:before,
.list-chevron-circle li:before,
.list-arrow-circle li:before,
.list-times-circle li:before {
  font-family: 'FontAwesome';
  position: absolute;
  left: 0;
  top: 3px;
  display: block;
  font-size: 15px;
  color: #000;
}

.list-circle li:before {
  top: 6px;
  font-size: 10px;
}

.list-circle li:before {
  content: '\f111';
}

.list-angle-right li:before {
  content: '\f105';
}

.list-arrow li:before {
  content: '\f0da';
}

.list-check li:before {
  content: '\f00c';
}

.list-checked li:before {
  content: '\f046';
}

.list-check-circle li:before {
  content: '\f058';
}

.list-chevron-circle li:before {
  content: '\f138';
}

.list-arrow-circle li:before {
  content: '\f0a9';
}

.list-times-circle li:before {
  content: '\f057';
}

/*-------------------------------------
	1. listing before icon color css
-------------------------------------*/

ul.primary li:before {
  color: #0f1221;
}

ul.secondry li:before {
  color: #253641;
}

ul.black li:before {
  color: #000;
}

ul.white li:before {
  color: #fff;
}

ul.orange li:before {
  color: #ff6600;
}

ul.green li:before {
  color: #00cc00;
}

ul.red li:before {
  color: #ff3737;
}

dl.no-margin,
ul.no-margin,
ol.no-margin {
  margin: 0px !important;
  list-style: none;
}

/*-------------------------------------
	2. listing before numbers
-------------------------------------*/

ol.list-num-count {
  counter-reset: li;
  padding-left: 0;
}

ol.list-num-count > li {
  position: relative;
  margin: 0 0 20px 40px;
  padding: 0px 8px;
  list-style: none;
}

ol.list-num-count > li:last-child {
  margin-bottom: 0px;
}

ol.list-num-count > li:before {
  content: counter(li, decimal);
  counter-increment: li;
  position: absolute;
  top: 0;
  left: -40px;
  width: 26px;
  height: 24px;
  padding: 5px;
  color: #fff;
  background: #0f1221;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

ol.list-num-count.lower-alpha > li:before {
  content: counter(li, lower-alpha);
}

ol.list-num-count.upper-alpha > li:before {
  content: counter(li, upper-alpha);
}

ol.list-num-count.list-outline > li:before {
  border: 1px solid #000;
  background-color: transparent;
  color: #000;
}

ol.list-num-count.list-outline-none > li:before {
  background-color: transparent;
  color: #000;
}

/*======================================
5. BOX SPACING (Padding & Margin)
======================================*/

/*-------------------------------------
	1.1 padding (around)
-------------------------------------*/

.p-a0 {
  padding: 0;
}
.p-a5 {
  padding: 5px;
}
.p-a10 {
  padding: 10px;
}
.p-a15 {
  padding: 15px;
}
.p-a20 {
  padding: 20px;
}
.p-a25 {
  padding: 25px;
}
.p-a30 {
  padding: 30px;
}
.p-a40 {
  padding: 40px;
}
.p-a50 {
  padding: 50px;
}
.p-a60 {
  padding: 60px;
}
.p-a70 {
  padding: 70px;
}
.p-a80 {
  padding: 80px;
}
.p-a90 {
  padding: 90px;
}
.p-a100 {
  padding: 100px;
}
.p-a110 {
  padding: 110px;
}
.p-a120 {
  padding: 120px;
}
.p-a130 {
  padding: 130px;
}
.p-a140 {
  padding: 140px;
}
.p-a150 {
  padding: 150px;
}

/*-------------------------------------
	1.2 Padding (top)
-------------------------------------*/

.p-t0 {
  padding-top: 0;
}
.p-t5 {
  padding-top: 5px;
}
.p-t10 {
  padding-top: 10px;
}
.p-t15 {
  padding-top: 15px;
}
.p-t20 {
  padding-top: 20px;
}
.p-t30 {
  padding-top: 30px;
}
.p-t40 {
  padding-top: 40px;
}
.p-t50 {
  padding-top: 50px;
}
.p-t60 {
  padding-top: 60px;
}
.p-t70 {
  padding-top: 70px;
}
.p-t80 {
  padding-top: 80px;
}
.p-t90 {
  padding-top: 90px;
}
.p-t100 {
  padding-top: 100px;
}
.p-t110 {
  padding-top: 110px;
}
.p-t120 {
  padding-top: 120px;
}
.p-t130 {
  padding-top: 130px;
}
.p-t140 {
  padding-top: 140px;
}
.p-t150 {
  padding-top: 150px;
}

/*-------------------------------------
    1.3 Padding (bottom)
-------------------------------------*/

.p-b0 {
  padding-bottom: 0;
}
.p-b5 {
  padding-bottom: 5px;
}
.p-b10 {
  padding-bottom: 10px;
}
.p-b15 {
  padding-bottom: 15px;
}
.p-b20 {
  padding-bottom: 20px;
}
.p-b30 {
  padding-bottom: 30px;
}
.p-b40 {
  padding-bottom: 40px;
}
.p-b50 {
  padding-bottom: 50px;
}
.p-b60 {
  padding-bottom: 60px;
}
.p-b70 {
  padding-bottom: 70px;
}
.p-b80 {
  padding-bottom: 80px;
}
.p-b90 {
  padding-bottom: 90px;
}
.p-b100 {
  padding-bottom: 100px;
}
.p-b110 {
  padding-bottom: 110px;
}
.p-b120 {
  padding-bottom: 120px;
}
.p-b130 {
  padding-bottom: 130px;
}
.p-b140 {
  padding-bottom: 140px;
}
.p-b150 {
  padding-bottom: 150px;
}
/*-------------------------------------
	1.4 Padding (left)
-------------------------------------*/

.p-l0 {
  padding-left: 0;
}
.p-l5 {
  padding-left: 5px;
}
.p-l10 {
  padding-left: 10px;
}
.p-l15 {
  padding-left: 15px;
}
.p-l20 {
  padding-left: 20px;
}
.p-l30 {
  padding-left: 30px;
}
.p-l40 {
  padding-left: 40px;
}
.p-l50 {
  padding-left: 50px;
}
.p-l60 {
  padding-left: 60px;
}
.p-l70 {
  padding-left: 70px;
}
.p-l80 {
  padding-left: 80px;
}
.p-l90 {
  padding-left: 90px;
}
.p-l100 {
  padding-left: 100px;
}
.p-l110 {
  padding-left: 110px;
}
.p-l120 {
  padding-left: 120px;
}
.p-l130 {
  padding-left: 130px;
}
.p-l140 {
  padding-left: 140px;
}
.p-l150 {
  padding-left: 150px;
}

/*-------------------------------------
	1.5 Padding (right)
-------------------------------------*/

.p-r0 {
  padding-right: 0;
}
.p-r5 {
  padding-right: 5px;
}
.p-r10 {
  padding-right: 10px;
}
.p-r15 {
  padding-right: 15px;
}
.p-r20 {
  padding-right: 20px;
}
.p-r30 {
  padding-right: 30px;
}
.p-r40 {
  padding-right: 40px;
}
.p-r50 {
  padding-right: 50px;
}
.p-r60 {
  padding-right: 60px;
}
.p-r70 {
  padding-right: 70px;
}
.p-r80 {
  padding-right: 80px;
}
.p-r90 {
  padding-right: 90px;
}
.p-r100 {
  padding-right: 100px;
}
.p-r110 {
  padding-right: 110px;
}
.p-r120 {
  padding-right: 120px;
}
.p-r130 {
  padding-right: 130px;
}
.p-r140 {
  padding-right: 140px;
}
.p-r150 {
  padding-right: 150px;
}

/*-------------------------------------
	1.6 Padding (left right)
-------------------------------------*/

.p-lr0 {
  padding-left: 0;
  padding-right: 0;
}
.p-lr5 {
  padding-left: 5px;
  padding-right: 5px;
}
.p-lr10 {
  padding-left: 10px;
  padding-right: 10px;
}
.p-lr15 {
  padding-left: 15px;
  padding-right: 15px;
}
.p-lr20 {
  padding-left: 20px;
  padding-right: 20px;
}
.p-lr30 {
  padding-left: 30px;
  padding-right: 30px;
}
.p-lr40 {
  padding-left: 40px;
  padding-right: 40px;
}
.p-lr50 {
  padding-left: 50px;
  padding-right: 50px;
}
.p-lr60 {
  padding-left: 60px;
  padding-right: 60px;
}
.p-lr70 {
  padding-left: 70px;
  padding-right: 70px;
}
.p-lr80 {
  padding-left: 80px;
  padding-right: 80px;
}
.p-lr90 {
  padding-left: 90px;
  padding-right: 90px;
}
.p-lr100 {
  padding-left: 100px;
  padding-right: 100px;
}
.p-lr120 {
  padding-left: 120px;
  padding-right: 120px;
}
.p-lr150 {
  padding-left: 150px;
  padding-right: 150px;
}

/*-------------------------------------
	1.7 Padding (top bottom)
-------------------------------------*/

.p-tb0 {
  padding-bottom: 0;
  padding-top: 0;
}
.p-tb5 {
  padding-bottom: 5px;
  padding-top: 5px;
}
.p-tb10 {
  padding-bottom: 10px;
  padding-top: 10px;
}
.p-tb15 {
  padding-bottom: 15px;
  padding-top: 15px;
}
.p-tb20 {
  padding-bottom: 20px;
  padding-top: 20px;
}
.p-tb30 {
  padding-bottom: 30px;
  padding-top: 30px;
}
.p-tb40 {
  padding-bottom: 40px;
  padding-top: 40px;
}
.p-tb50 {
  padding-bottom: 50px;
  padding-top: 50px;
}
.p-tb60 {
  padding-bottom: 60px;
  padding-top: 60px;
}
.p-tb70 {
  padding-bottom: 70px;
  padding-top: 70px;
}
.p-tb80 {
  padding-bottom: 80px;
  padding-top: 80px;
}
.p-tb90 {
  padding-bottom: 90px;
  padding-top: 90px;
}
.p-tb100 {
  padding-bottom: 100px;
  padding-top: 100px;
}
.p-tb120 {
  padding-bottom: 120px;
  padding-top: 120px;
}
.p-tb150 {
  padding-bottom: 150px;
  padding-top: 150px;
}

/*-------------------------------------
	2.1 margin (around)
-------------------------------------*/

.m-a-1 {
  margin: -1px;
}
.m-a0 {
  margin: 0;
}
.m-a5 {
  margin: 5px;
}
.m-a10 {
  margin: 10px;
}
.m-a15 {
  margin: 15px;
}
.m-a20 {
  margin: 20px;
}
.m-a30 {
  margin: 30px;
}
.m-a40 {
  margin: 40px;
}
.m-a50 {
  margin: 50px;
}
.m-a60 {
  margin: 60px;
}
.m-a70 {
  margin: 70px;
}
.m-a80 {
  margin: 80px;
}
.m-a90 {
  margin: 90px;
}
.m-a100 {
  margin: 100px;
}
.m-a110 {
  margin: 110px;
}
.m-a120 {
  margin: 120px;
}
.m-a130 {
  margin: 130px;
}
.m-a140 {
  margin: 140px;
}
.m-a150 {
  margin: 150px;
}

/*-------------------------------------
	2.2 marging (top)
-------------------------------------*/

.m-t0 {
  margin-top: 0;
}
.m-t5 {
  margin-top: 5px;
}
.m-t10 {
  margin-top: 10px;
}
.m-t15 {
  margin-top: 15px;
}
.m-t20 {
  margin-top: 20px;
}
.m-t30 {
  margin-top: 30px;
}
.m-t40 {
  margin-top: 40px;
}
.m-t50 {
  margin-top: 50px;
}
.m-t60 {
  margin-top: 60px;
}
.m-t70 {
  margin-top: 70px;
}
.m-t80 {
  margin-top: 80px;
}
.m-t90 {
  margin-top: 90px;
}
.m-t100 {
  margin-top: 100px;
}
.m-t110 {
  margin-top: 110px;
}
.m-t120 {
  margin-top: 120px;
}
.m-t130 {
  margin-top: 130px;
}
.m-t140 {
  margin-top: 140px;
}
.m-t150 {
  margin-top: 150px;
}

/*-------------------------------------
	2.3 marging (bottom)
------------------------------------*/
.m-b0 {
  margin-bottom: 0;
}
.m-b5 {
  margin-bottom: 5px;
}
.m-b10 {
  margin-bottom: 10px;
}
.m-b15 {
  margin-bottom: 15px;
}
.m-b20 {
  margin-bottom: 20px;
}
.m-b30 {
  margin-bottom: 30px;
}
.m-b40 {
  margin-bottom: 40px;
}
.m-b50 {
  margin-bottom: 50px;
}
.m-b60 {
  margin-bottom: 60px;
}
.m-b70 {
  margin-bottom: 70px;
}
.m-b80 {
  margin-bottom: 80px;
}
.m-b90 {
  margin-bottom: 90px;
}
.m-b100 {
  margin-bottom: 100px;
}
.m-b110 {
  margin-bottom: 110px;
}
.m-b120 {
  margin-bottom: 120px;
}
.m-b130 {
  margin-bottom: 130px;
}
.m-b140 {
  margin-bottom: 140px;
}
.m-b150 {
  margin-bottom: 150px;
}

/*-------------------------------------
	2.4 marging (left)
-------------------------------------*/
.m-l0 {
  margin-left: 0;
}
.m-l5 {
  margin-left: 5px;
}
.m-l10 {
  margin-left: 10px;
}
.m-l15 {
  margin-left: 15px;
}
.m-l20 {
  margin-left: 20px;
}
.m-l30 {
  margin-left: 30px;
}
.m-l40 {
  margin-left: 40px;
}
.m-l50 {
  margin-left: 50px;
}
.m-l60 {
  margin-left: 60px;
}
.m-l70 {
  margin-left: 70px;
}
.m-l80 {
  margin-left: 80px;
}
.m-l90 {
  margin-left: 90px;
}
.m-l100 {
  margin-left: 100px;
}
.m-l110 {
  margin-left: 110px;
}
.m-l120 {
  margin-left: 120px;
}
.m-l130 {
  margin-left: 130px;
}
.m-l140 {
  margin-left: 140px;
}
.m-l150 {
  margin-left: 150px;
}

/*-------------------------------------
	2.5 marging (right)
------------------------------------*/
.m-r0 {
  margin-right: 0;
}
.m-r5 {
  margin-right: 5px;
}
.m-r10 {
  margin-right: 10px;
}
.m-r15 {
  margin-right: 15px;
}
.m-r20 {
  margin-right: 20px;
}
.m-r30 {
  margin-right: 30px;
}
.m-r40 {
  margin-right: 40px;
}
.m-r50 {
  margin-right: 50px;
}
.m-r60 {
  margin-right: 60px;
}
.m-r70 {
  margin-right: 70px;
}
.m-r80 {
  margin-right: 80px;
}
.m-r90 {
  margin-right: 90px;
}
.m-r100 {
  margin-right: 100px;
}
.m-r110 {
  margin-right: 110px;
}
.m-r120 {
  margin-right: 120px;
}
.m-r130 {
  margin-right: 130px;
}
.m-r140 {
  margin-right: 140px;
}
.m-r150 {
  margin-right: 150px;
}

/*-------------------------------------
	2.6 marging (left right)
-------------------------------------*/
.m-lr0 {
  margin-left: 0;
  margin-right: 0;
}
.m-lr5 {
  margin-left: 5px;
  margin-right: 5px;
}
.m-lr10 {
  margin-left: 10px;
  margin-right: 10px;
}
.m-lr15 {
  margin-left: 15px;
  margin-right: 15px;
}
.m-lr20 {
  margin-left: 20px;
  margin-right: 20px;
}
.m-lr30 {
  margin-left: 30px;
  margin-right: 30px;
}
.m-lr40 {
  margin-left: 40px;
  margin-right: 40px;
}
.m-lr50 {
  margin-left: 50px;
  margin-right: 50px;
}
.m-lr60 {
  margin-left: 60px;
  margin-right: 60px;
}
.m-lr70 {
  margin-left: 70px;
  margin-right: 70px;
}
.m-lr80 {
  margin-left: 80px;
  margin-right: 80px;
}
.m-lr90 {
  margin-left: 90px;
  margin-right: 90px;
}
.m-lr100 {
  margin-left: 100px;
  margin-right: 100px;
}
.m-lr120 {
  margin-left: 120px;
  margin-right: 120px;
}
.m-lr150 {
  margin-left: 150px;
  margin-right: 150px;
}
/*-------------------------------------
	2.7 marging (top bottom)
-------------------------------------*/

.m-tb0 {
  margin-bottom: 0;
  margin-top: 0;
}
.m-tb5 {
  margin-bottom: 5px;
  margin-top: 5px;
}
.m-tb10 {
  margin-bottom: 10px;
  margin-top: 10px;
}
.m-tb15 {
  margin-bottom: 15px;
  margin-top: 15px;
}
.m-tb20 {
  margin-bottom: 20px;
  margin-top: 20px;
}
.m-tb30 {
  margin-bottom: 30px;
  margin-top: 30px;
}
.m-tb40 {
  margin-bottom: 40px;
  margin-top: 40px;
}
.m-tb50 {
  margin-bottom: 50px;
  margin-top: 50px;
}
.m-tb60 {
  margin-bottom: 60px;
  margin-top: 60px;
}
.m-tb70 {
  margin-bottom: 70px;
  margin-top: 70px;
}
.m-tb80 {
  margin-bottom: 80px;
  margin-top: 80px;
}
.m-tb90 {
  margin-bottom: 90px;
  margin-top: 90px;
}
.m-tb100 {
  margin-bottom: 100px;
  margin-top: 100px;
}
.m-tb120 {
  margin-bottom: 120px;
  margin-top: 120px;
}
.m-tb150 {
  margin-bottom: 150px;
  margin-top: 150px;
}
/*-------------------------------------
	2.8 colum gap less
-------------------------------------*/

.no-col-gap [class*='col-xs-'],
.no-col-gap [class*='col-sm-'],
.no-col-gap [class*='col-md-'],
.no-col-gap [class*='col-lg-'] {
  padding-left: 0;
  padding-right: 0;
}

/*======================================
6. POSITIONS ( absolute , relative , statice & z-index)
======================================*/
/*-------------------------------------
	1. position
-------------------------------------*/
/*1.1 relative*/
.relative {
  position: relative;
}
/*1.2 absolute*/
.absolute {
  position: absolute;
}
/*1.3 static*/
.static {
  position: static;
}

/*-------------------------------------
	2. z-index
-------------------------------------*/
.z-index1 {
  z-index: 1;
}
.z-index2 {
  z-index: 2;
}
.z-index3 {
  z-index: 3;
}
.z-index4 {
  z-index: 4;
}
.z-index5 {
  z-index: 5;
}
.z-index6 {
  z-index: 6;
}
.z-index7 {
  z-index: 7;
}
.z-index8 {
  z-index: 8;
}
.z-index9 {
  z-index: 9;
}
.z-index10 {
  z-index: 10;
}
.z-index100 {
  z-index: 100;
}
.z-index999 {
  z-index: 999;
}
/*======================================
7. COLOR
======================================*/

/*-------------------------------------
	1. text
-------------------------------------*/
.botus {
  color: orange !important;
}
.site-text-white {
  color: #fff;
}
.site-text-primary {
  color: #ff3737;
}
.site-text-secondry {
  color: #1a1a1a;
}
.text-black {
  color: #000;
}
.text-gray {
  color: #f5f6f6;
}
.text-gray-dark {
  color: #d3d3d3;
}
.text-red {
  color: #99090a;
}
.text-green {
  color: #557131;
}
.text-yellow {
  color: #ffc100;
}
.text-light-blue {
  color: #004f83;
}
/*-------------------------------------
	2. background color
-------------------------------------*/

.bg-orange-light {
  background-color: #fff6ef;
}
.bg-orange-light2 {
  background-color: #feede7;
}
.bg-transparent {
  background-color: transparent;
}
.site-bg-primary {
  background-color: #d72323;
}
.site-bg-secondry {
  background-color: #1a1a1a;
}
.bg-dark {
  background-color: #1e1e20;
}
.bg-white {
  background-color: #fff;
}
.bg-black {
  background-color: #000;
}
.bg-black-light {
  background-color: #212121;
}
.bg-gray {
  background-color: #f7f7f7;
}
.bg-gray-light {
  background-color: #f1f1f1;
}
.bg-gray-dark {
  background-color: #d3d3d3;
}
.bg-red {
  background-color: #fb5455;
}
.bg-orange {
  background-color: #ff4b00;
}
.bg-dark-blue {
  background-color: #161b23;
}
.bg-light-blue {
  background-color: #3097db;
}
.bg-green {
  background-color: #54ba1d;
}
.bg-yellow {
  background-color: #fff000;
}
.bg-purple {
  background-color: #5e6db3;
}
.bg-cyan {
  background-color: #00ca95;
}
.bg-blue {
  background-color: #31cff9;
}
.bg-light-orange {
  background-color: #fd7b6c;
}

/*-------------------------------------
	3. background image
-------------------------------------*/

.bg-parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 768px) {
  .bg-parallax {
    background-attachment: fixed !important;
    background-position: center !important;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

/*======================================
8. OVERLAY ( transparient box style  )
======================================*/

.overlay-wraper {
  position: relative;
}
.overlay-main {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.opacity-01 {
  opacity: 0.1;
}
.opacity-02 {
  opacity: 0.2;
}
.opacity-03 {
  opacity: 0.3;
}
.opacity-04 {
  opacity: 0.4;
}
.opacity-05 {
  opacity: 0.5;
}
.opacity-06 {
  opacity: 0.6;
}
.opacity-07 {
  opacity: 0.7;
}
.opacity-08 {
  opacity: 0.8;
}
.opacity-09 {
  opacity: 0.9;
}
.overlay-light .overlay-main {
  opacity: 0.3;
}
.overlay-dark .overlay-main {
  opacity: 0.9;
}

.overlay-wraper > .container,
.overlay-wraper > .container-fluid,
.overlay-wraper > .wt-icon-box-wraper,
.overlay-wraper > .wt-left-part,
.overlay-wraper > .wt-right-part {
  position: relative;
  z-index: 1;
}

/*use for box*/

.overlay-bx {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

.overlay-icon {
  list-style: none;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  z-index: 1 !important;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
}

.overlay-icon a {
  display: inline-block;
  padding: 0;
  margin: 0 2px;
  background-color: #d72323;
  color: #fff;
}

.overlay-icon .wt-icon-box-xs {
  line-height: 40px;
}

.overlay-icon a:hover {
  color: #fff;
}

.mfp-gallery .overlay-icon a {
  color: #fff;
}

.mfp-gallery .overlay-icon a:hover {
  color: #0f1221;
}

.overlay-bx:hover a > i,
.wt-thum-bx:hover .overlay-bx a > i,
.wt-box:hover .overlay-bx a > i {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.overlay-bx:hover,
.wt-thum-bx:hover .overlay-bx,
.wt-box:hover .overlay-bx,
.ow-img:hover .overlay-bx,
.ow-portfolio-img:hover .overlay-bx {
  opacity: 1;
  visibility: visible;
}
/*======================================
9. BOXES CSS 
======================================*/

.rounded-bx,
.wt-box,
.wt-icon-box,
.wt-icon-box-small,
.wt-thum-bx,
.wt-post-thum {
  position: relative;
}
/*-------------------------------------
	1. box content
-------------------------------------*/

.wt-box {
  position: relative;
}
.wt-box.no-margin {
  margin-bottom: 0;
}
/*-------------------------------------
	2. For icons with box
-------------------------------------*/

.wt-icon-box-xld,
.wt-icon-box-xl,
.wt-icon-box-lg,
.wt-icon-box-md,
.wt-icon-box-sm,
.wt-icon-box-xs {
  display: inline-block;
  text-align: center;
}

.wt-icon-box-xld {
  width: 180px;
  height: 180px;
}
.wt-icon-box-xl {
  width: 150px;
  height: 150px;
}
.wt-icon-box-lg {
  width: 120px;
  height: 120px;
}
.wt-icon-box-md {
  width: 100px;
  height: 100px;
}
.wt-icon-box-sm {
  width: 80px;
  height: 80px;
}
.wt-icon-box-xs {
  width: 40px;
  height: 40px;
}

.wt-icon-box-xld.radius,
.wt-icon-box-xl.radius,
.wt-icon-box-lg.radius,
.wt-icon-box-md.radius,
.wt-icon-box-sm.radius,
.wt-icon-box-xs.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.wt-icon-box-xld i,
.wt-icon-box-xl i,
.wt-icon-box-lg i,
.wt-icon-box-md i,
.wt-icon-box-sm i,
.wt-icon-box-xs i {
  vertical-align: middle;
}

.wt-icon-box-xld i {
  font-size: 100px;
}
.wt-icon-box-xl i {
  font-size: 80px;
}
.wt-icon-box-lg i {
  font-size: 60px;
}
.wt-icon-box-md i {
  font-size: 45px;
}
.wt-icon-box-sm i {
  font-size: 30px;
}
.wt-icon-box-xs i {
  font-size: 20px;
}

.wt-icon-box-xld img,
.wt-icon-box-xl img,
.wt-icon-box-lg img,
.wt-icon-box-md img,
.wt-icon-box-sm img,
.wt-icon-box-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
}

.wt-icon-box-xld img {
  height: 90px;
}
.wt-icon-box-xl img {
  height: 80px;
}
.wt-icon-box-lg img {
  height: 55px;
}
.wt-icon-box-md img {
  height: 40px;
}
.wt-icon-box-sm img {
  height: 30px;
}
.wt-icon-box-xs img {
  height: 20px;
}

/*-------------------------------------
	4. For only icons
-------------------------------------*/

.icon-xld,
.icon-xl,
.icon-lg,
.icon-md,
.icon-sm,
.icon-xs {
  display: inline-block;
  text-align: center;
}

.icon-xld i,
.icon-xl i,
.icon-lg i,
.icon-md i,
.icon-sm i,
.icon-xs i {
  vertical-align: middle;
}

.icon-xld {
  width: 120px;
}

.icon-xld i {
  font-size: 100px;
  line-height: 100px;
}
.icon-xld.inline-icon {
  width: auto;
  text-align: left !important;
}

.icon-xl {
  width: 100px;
}
.icon-xl i {
  font-size: 80px;
  line-height: 80px;
}

.icon-xl.inline-icon {
  width: auto;
  text-align: left !important;
}

.icon-lg {
  width: 80px;
}

.icon-lg i {
  font-size: 60px;
  line-height: 60px;
}
.icon-lg.inline-icon {
  width: auto;
  text-align: left !important;
}

.icon-md {
  width: 60px;
}
.icon-md i {
  font-size: 45px;
  line-height: 45px;
}

.icon-md.inline-icon {
  width: auto;
  text-align: left !important;
}
.icon-sm {
  width: 40px;
}

.icon-sm i {
  font-size: 30px;
  line-height: 30px;
}

.icon-sm.inline-icon {
  width: auto;
  text-align: left !important;
}
.icon-xs {
  width: 30px;
}

.icon-xs i {
  font-size: 20px;
  line-height: 20px;
}
.icon-xs.inline-icon {
  width: auto;
  text-align: left !important;
}

.icon-xld img,
.icon-xl img,
.icon-lg img,
.icon-md img,
.icon-sm img,
.icon-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
}

.icon-xld img {
  height: 90px;
}
.icon-xl img {
  height: 80px;
}

.icon-lg img {
  height: 70px;
}
.icon-md img {
  height: 50px;
}

.icon-sm img {
  height: 30px;
}

.icon-xs img {
  height: 20px;
}

/*-------------------------------------
	5. place icon with box
-------------------------------------*/

.wt-icon-box-wraper {
  position: relative;
}

.wt-icon-box-wraper .wt-tilte {
  margin-top: 0;
}

.wt-icon-box-wraper .after-titile-line {
  margin-bottom: 10px;
}

.wt-icon-box-wraper p:last-child {
  margin: 0px;
}

.icon-content {
  overflow: hidden;
}

/*.icon-content p{word-break:break-all;}*/

.wt-icon-box-xld,
.wt-icon-box-xl,
.wt-icon-box-lg,
.wt-icon-box-md,
.wt-icon-box-sm,
.wt-icon-box-xs {
  display: table;
}

.wt-icon-box-xld .icon-cell,
.wt-icon-box-xl .icon-cell,
.wt-icon-box-lg .icon-cell,
.wt-icon-box-md .icon-cell,
.wt-icon-box-sm .icon-cell,
.wt-icon-box-xs .icon-cell {
  display: table-cell;
  vertical-align: middle;
}

/*-------------------------------------
	6. icon box left align
-------------------------------------*/

.wt-icon-box-wraper.left .wt-icon-box-xld,
.wt-icon-box-wraper.left .wt-icon-box-xl,
.wt-icon-box-wraper.left .wt-icon-box-lg,
.wt-icon-box-wraper.left .wt-icon-box-md,
.wt-icon-box-wraper.left .wt-icon-box-sm,
.wt-icon-box-wraper.left .wt-icon-box-xs {
  float: left;
  margin-right: 20px;
}

.wt-icon-box-wraper.left .icon-xld,
.wt-icon-box-wraper.left .icon-xl,
.wt-icon-box-wraper.left .icon-lg,
.wt-icon-box-wraper.left .icon-md,
.wt-icon-box-wraper.left .icon-sm,
.wt-icon-box-wraper.left .icon-xs {
  float: left;
  margin-right: 20px;
}

/*-------------------------------------
	7. icon box right align
-------------------------------------*/

.wt-icon-box-wraper.right {
  text-align: right;
}

.wt-icon-box-wraper.right .wt-icon-box-xld,
.wt-icon-box-wraper.right .wt-icon-box-xl,
.wt-icon-box-wraper.right .wt-icon-box-lg,
.wt-icon-box-wraper.right .wt-icon-box-md,
.wt-icon-box-wraper.right .wt-icon-box-sm,
.wt-icon-box-wraper.right .wt-icon-box-xs {
  float: right;
  display: table;
  margin-left: 20px;
}

.wt-icon-box-wraper.right .icon-xld,
.wt-icon-box-wraper.right .icon-xl,
.wt-icon-box-wraper.right .icon-lg,
.wt-icon-box-wraper.right .icon-md,
.wt-icon-box-wraper.right .icon-sm,
.wt-icon-box-wraper.right .icon-xs {
  float: right;
  margin-left: 20px;
}

/*-------------------------------------
	8. icon box center align
-------------------------------------*/

.wt-icon-box-wraper.center {
  text-align: center;
}

.wt-icon-box-wraper.center .wt-icon-box-xld,
.wt-icon-box-wraper.center .wt-icon-box-xl,
.wt-icon-box-wraper.center .wt-icon-box-lg,
.wt-icon-box-wraper.center .wt-icon-box-md,
.wt-icon-box-wraper.center .wt-icon-box-sm,
.wt-icon-box-wraper.center .wt-icon-box-xs {
  margin-left: auto;
  margin-right: auto;
}

.wt-icon-box-wraper.bx-style-1,
.wt-icon-box-wraper.bx-style-2 {
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
}

.cart-icon {
  position: relative;
  display: inline-block;
}

.cart-badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 3px 6px;
  font-size: 12px;
}

.wt-icon-box-wraper.bx-style-2.center [class*='wt-icon-box-'],
.wt-icon-box-wraper.bx-style-2.left [class*='wt-icon-box-'],
.wt-icon-box-wraper.bx-style-2.right [class*='wt-icon-box-'] {
  position: absolute;
}

.wt-icon-box-wraper.bx-style-2.center [class*='wt-icon-box-'] {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-xl {
  margin-left: -75px;
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-lg {
  margin-left: -60px;
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-md {
  margin-left: -50px;
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-sm {
  margin-left: -40px;
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-xs {
  margin-left: -20px;
}

.wt-icon-box-wraper.bx-style-2.left [class*='wt-icon-box-'] {
  position: absolute;
  top: auto;
  left: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.wt-icon-box-wraper.bx-style-2.right [class*='wt-icon-box-'] {
  position: absolute;
  top: auto;
  right: 0;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

/*-------------------------------------
	9. media with content box css
-------------------------------------*/

.wt-box,
.wt-info,
.wt-tilte,
.wt-tilte-inner {
  position: relative;
}

.wt-tilte-inner {
  display: inline-block;
}

.wt-tilte-inner.skew-title:after {
  content: '';
  position: absolute;
  right: -15px;
  top: 0;
  width: 50px;
  height: 100%;
  z-index: -1;
  -moz-transform: skewX(20deg);
  -webkit-transform: skewX(20deg);
  -o-transform: skewX(20deg);
  -ms-transform: skewX(20deg);
  transform: skewX(20deg);
}

.wt-box[class*='border-'],
.wt-info[class*='border-'],
.wt-icon-box-wraper[class*='border-'] {
  border-color: #eee;
}

.wt-info.border-1,
.wt-info.border-2,
.wt-info.border-3,
.wt-info.border-4,
.wt-info.border-5 {
  border-top: none;
}

/*-------------------------------------
	10. for images
-------------------------------------*/

.wt-thum-bx,
.wt-post-thum {
  background-color: #000;
}

.wt-thum-bx img,
.wt-post-thum img {
  width: 100%;
  height: auto;
}

/*======================================
10. IMAGES OVERLAY EFFECTS (overly animation on images)  
======================================*/

.wt-img-overlay1,
.wt-img-overlay2 {
  position: relative;
}

.wt-img-overlay1:before,
.wt-img-overlay1:after {
  content: '';
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0.4;
  z-index: 0;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

/*-------------------------------------
	1. effect 1
-------------------------------------*/

.wt-img-overlay1:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.wt-img-overlay1:hover:before,
.wt-box:hover .wt-img-overlay1:before {
  opacity: 0.5;
}

/*-------------------------------------
	2. effect 2
-------------------------------------*/

.wt-img-overlay2:before {
  width: 100%;
  height: 100%;
  opacity: 0;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );*/
  /* IE6-9 */
}

.wt-img-overlay2:hover:before,
.wt-box:hover .wt-img-overlay2:before {
  opacity: 0.9;
}

/*======================================
11. shortcodeS 
======================================*/

/*-------------------------------------
	1. Buttons 
--------------------------------------- */

/* 1.1 theme default button */

.btn {
  padding-top: 9px;
  padding-bottom: 9px;
}

.site-button:active,
.site-button:focus,
.site-button:visited,
.active > .site-button {
  background-color: #d72323;
  color: #fff;
}

.site-button:hover {
  background-color: #1a1a1a;
  color: #fff;
}

.site-button-secondry {
  color: #fff;
  outline: none;
  background-color: #1a1a1a;
}

.site-button-secondry:active,
.site-button-secondry:hover,
.active > .site-button-secondry {
  background-color: #ededed;
  color: #111;
}

.site-button-secondry:hover.site-btn-effect {
  text-shadow: -400px 0 0 #111111, 0 0 0 #111111;
}

.site-button {
  outline: none;
  color: #fff;
  padding: 15px 40px;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
  background-color: #d72323;
  border: none;
  font-size: 14px;
  font-weight: 500;
}

.site-button-secondry {
  color: #fff;
  padding: 15px 40px;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
  background-color: #1a1a1a;
  border: none;
  font-size: 14px;
}
.mfp-video.video-play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  margin: -25px;
  color: #fff;
  display: block;
  z-index: 10;
  font-size: 14px;
  border: 2px solid #fff;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.mfp-video.video-play-btn:hover {
  background-color: #0f1221;
  color: #fff;
  border: 2px solid transparent;
}

.mfp-video.video-play-btn i {
  margin-right: -5px;
}

/* 1.2 button text uppercase */

.site-button.text-uppercase {
  text-transform: uppercase;
}

/* 1.3 button size */

.button-sm {
  padding: 5px 10px;
  font-size: 12px;
}

.button-lg {
  padding: 15px 30px;
  font-size: 16px;
}

.button-xl {
  padding: 30px 50px;
  font-size: 24px;
}

/* 1.4 button rounded */

.radius-no {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

.radius-sm {
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

.radius-md {
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
}

.radius-xl {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}

.radius-bx {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
}

/* 1.6 white */

.site-button.white {
  background-color: #fff;
  color: #484848;
}

.site-button.white:hover,
.site-button.white:active,
.site-button.white:focus {
  background-color: #f4f4f4;
  color: #555;
}

/* 1.7 black */
.site-button.black {
  background-color: #000;
  color: #fff;
}

.site-button.black:hover,
.site-button.black:active,
.site-button.black:focus {
  background-color: #171717 !important;
  color: #fff !important;
}
/* 1.8 Gray */
.site-button.gray {
  background-color: #666666;
  color: #fff;
}

.site-button.gray:hover,
.site-button.gray:active,
.site-button.gray:focus {
  background-color: #555555;
  color: #fff;
}
/* 1.9 pink */
.site-button.pink {
  background-color: #e63f75;
  color: #fff;
}

.site-button.pink:hover,
.site-button.pink:active,
.site-button.pink:focus {
  background-color: #d22b61;
  color: #fff;
}
/* 1.10 Blue */
.site-button.blue {
  background-color: #42b8d4;
  color: #fff;
}

.site-button.blue:hover,
.site-button.blue:active,
.site-button.blue:focus {
  background-color: #2ca2be;
  color: #fff;
}
/* 1.11 Green */
.site-button.green {
  background-color: #35b494;
  color: #fff;
}

.site-button.green:hover,
.site-button.green:active,
.site-button.green:focus {
  background-color: #26a585;
  color: #fff;
}
/* 1.12 Orange */
.site-button.orange {
  background-color: #e56713;
  color: #fff;
}

.site-button.orange:hover,
.site-button.orange:active,
.site-button.orange:focus {
  background-color: #d55703;
  color: #fff;
}
/* 1.13 Red */

.site-button.red {
  background-color: #d93223;
  color: #fff;
}

.site-button.red:hover,
.site-button.red:active,
.site-button.red:focus {
  background-color: #c51e0f;
  color: #fff;
}
/*1.14 Brown */

.site-button.brown {
  background-color: #69441f;
  color: #fff;
}

.site-button.brown:hover,
.site-button.brown:active,
.site-button.brown:focus {
  background-color: #5f3a15;
  color: #fff;
}
/* 1.15 Yellow */

.site-button.yellow {
  background-color: #ecc731;
  color: #fff;
}

.site-button.yellow:hover,
.site-button.yellow:active,
.site-button.yellow:focus {
  background-color: #d4af19;
  color: #fff;
}
/* 1.16 purple */

.site-button.purple {
  background-color: #ae1af7;
  color: #fff;
}

.site-button.purple:hover,
.site-button.purple:active,
.site-button.purple:focus {
  background-color: #9804e1;
  color: #fff;
}
/* 1.17 Graphical */

.site-button.graphical {
  color: #fff;
  text-shadow: 0 -1px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.3),
    0 1px 3px -1px rgba(45, 60, 72, 0.5);
  -webkit-box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.3),
    0 1px 3px -1px rgba(45, 60, 72, 0.5);
  -moz-box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.3),
    0 1px 3px -1px rgba(45, 60, 72, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.site-button.graphical:active {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25) inset;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25) inset;
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25) inset;
}
/* 1.19 Outline */

.site-button.outline {
  color: #888;
  background: none;
  border-width: 2px;
  border-style: solid;
  border-color: #eee;
}
/* 1.20 Outline white  */
.site-button.outline.white {
  color: #e7e7e7;
  border-color: #e7e7e7;
}
.site-button.outline.white:hover {
  color: #666666;
}
/* 1.21 Outline black */
.site-button.outline.black {
  color: #171717;
  border-color: #171717;
}
/* 1.22 Outline gray */
.site-button.outline.gray {
  color: #666666;
  border-color: #666666;
}
/* 1.23 Outline pink */
.site-button.outline.pink {
  color: #e63f75;
  border-color: #e63f75;
}
/* 1.24 Outline blue */
.site-button.outline.blue {
  color: #42b8d4;
  border-color: #42b8d4;
}
/* 1.25 Outline green */
.site-button.outline.green {
  color: #35b494;
  border-color: #35b494;
}
/* 1.26 Outline orange */
.site-button.outline.orange {
  color: #e56713;
  border-color: #e56713;
}
/* 1.27 Outline red */
.site-button.outline.red {
  color: #d93223;
  border-color: #d93223;
}
/* 1.28 Outline brown */
.site-button.outline.brown {
  color: #69441f;
  border-color: #69441f;
}

/* 1.29 Outline yellow */
.site-button.outline.yellow {
  color: #0f1221;
  border-color: #0f1221;
}
/* 1.30 Outline purple */
.site-button.outline.purple {
  color: #ae1af7;
  border-color: #ae1af7;
}
/* 1.31 Outline hover */
.site-button.outline.black:hover {
  border-color: rgba(0, 0, 0, 0);
  color: #fff;
}
/* 1.32 button text link */
.site-button-link {
  display: inline-block;
  position: relative;
  font-weight: 700;
}
/* 1.33 text link white */

.site-button-link.white {
  color: #e7e7e7;
}
.site-button-link.white:hover,
.site-button-link.white:active,
.site-button-link.white:focus {
  color: #ccc;
}
/* 1.34 text link black */
.site-button-link.black {
  color: #171717;
}

.site-button-link.black:hover,
.site-button-link.black:active,
.site-button-link.black:focus {
  color: #000;
}
/* 1.35 text link Gray */
.site-button-link.gray {
  color: #666666;
}

.site-button-link.gray:hover,
.site-button-link.gray:active,
.site-button-link.gray:focus {
  color: #555555;
}
/* 1.36 text link pink */
.site-button-link.pink {
  color: #e63f75;
}

.site-button-link.pink:hover,
.site-button-link.pink:active,
.site-button-link.pink:focus {
  color: #2ca2be;
}
/* 1.37 text link Blue */
.site-button-link.blue {
  color: #42b8d4;
}

.site-button-link.blue:hover,
.site-button-link.blue:active,
.site-button-link.blue:focus {
  color: #2ca2be;
}
/* 1.38 text link Green */
.site-button-link.green {
  color: #35b494;
}

.site-button-link.green:hover,
.site-button-link.green:active,
.site-button-link.green:focus {
  color: #26a585;
}
/* 1.39 text link Orange */

.site-button-link.orange {
  color: #e56713;
}
.site-button-link.orange:hover,
.site-button-link.orange:active,
.site-button-link.orange:focus {
  color: #d55703;
}
/* 1.40 text link Red */
.site-button-link.red {
  color: #d93223;
}

.site-button-link.red:hover,
.site-button-link.red:active,
.site-button-link.red:focus {
  color: #c51e0f;
}
/* 1.41 text link Brown */
.site-button-link.brown {
  color: #69441f;
}

.site-button-link.brown:hover,
.site-button-link.brown:active,
.site-button-link.brown:focus {
  color: #5f3a15;
}
/* 1.42 text link Yellow */

.site-button-link.yellow {
  color: #ecc731;
}

.site-button-link.yellow:hover,
.site-button-link.yellow:active,
.site-button-link.yellow:focus {
  color: #d4af19;
}
/* 1.43 text link purple */

.site-button-link.purple {
  color: #ae1af7;
}
.site-button-link.purple:hover,
.site-button-link.purple:active,
.site-button-link.purple:focus {
  color: #9804e1;
}

/* 1.44 app strore */

.site-button.button-app {
  text-align: left;
  padding: 15px 25px;
}

.site-button.button-app i {
  display: inline-block;
  font-size: 45px;
  float: left;
  margin-right: 15px;
}

.site-button.button-app strong {
  display: block;
  font-size: 16px;
}
/* 1.45 filter buttons css */

.masonry-filter {
  display: inline-block;
  margin-bottom: 0;
}

.masonry-filter > li {
  display: inline-block;
  margin-right: 0px;
}

.masonry-filter li a {
  color: #111111;
  font-size: 14px;
  position: relative;
  z-index: 1;
  display: block;
  overflow: hidden;
  position: relative;
  margin: 1px 0px;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: 'Rajdhani', sans-serif;
  padding: 10px 15px;
  background-color: #eee;
}

.masonry-filter > li.active a,
.masonry-filter > li a:hover,
.masonry-filter > li a:active,
.masonry-filter > li a:focus {
  color: #fff;
  background-color: #d72323;
}

/* Example basic styles for react-modal */
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Apply blur to the background */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
}

.modal-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-close-button {
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.modal-header h2 {
  font-size: 40px !important;
}

.cons-contact-form {
  margin-top: 10px;
  margin-left: -5px !important;
}

.full-button {
  width: 100% !important;
}

.youtube-link span {
  color: #d72323 !important;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.masonry-filter.filter-gradi > li.active a,
.masonry-filter.filter-gradi > li a:hover,
.masonry-filter.filter-gradi > li a:active,
.masonry-filter.filter-gradi > li a:focus {
  color: #fff;
}

.masonry-filter.filter-light-bg > li a {
  color: #0f1221;
}

.masonry-filter.filter-gradi > li a {
  display: inline-block;
  margin-right: 0px;
  padding: 10px 25px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  margin-right: 10px;
}

.masonry-filter.filter-gradi > li.active a,
.masonry-filter.filter-gradi > li a:hover,
.masonry-filter.filter-gradi > li a:active,
.masonry-filter.filter-gradi > li a:focus {
  background: rgb(249, 107, 57); /* Old browsers */
}

@media only screen and (max-width: 991px) {
  .masonry-filter.filter-gradi > li a {
    padding: 10px 10px;
  }

  .logo-header-inner img {
    margin-top: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .masonry-filter.filter-gradi > li a {
    padding: 4px 10px;
  }
  .filter-wrap {
    text-align: left !important;
  }
}

/* 1.45(1) Masonry filter alignment [Left-Right-Center] */
.filter-wrap.right > .masonry-filter {
  float: right;
}

@media only screen and (max-width: 991px) {
  .filter-wrap.right > .masonry-filter {
    float: none;
  }
}

.filter-wrap.center > .masonry-filter {
  margin-left: auto;
  margin-right: auto;
}

/* 1.45(2) Masonry filter link style */

.masonry-filter.link-style > li a {
  font-size: 22px;
  font-family: 'Rajdhani', sans-serif;
}

.masonry-filter.link-style.white > li:after {
  color: #fff;
}

.masonry-filter.link-style > li:last-child:after {
  display: none;
}

.masonry-filter.link-style.white > li.active a,
.masonry-filter.link-style.white > li a:hover,
.masonry-filter.link-style.white > li a:active,
.masonry-filter.link-style.white > li a:focus {
  color: #f7c20a;
}

.masonry-filter.has-bg > li a {
  color: #fff;
}

.masonry-filter.link-style.has-bg > li.active a,
.masonry-filter.link-style.has-bg > li:hover a,
.masonry-filter.link-style.has-bg > li:active a,
.masonry-filter.link-style.has-bg > li:focus a {
  color: #0f1221;
}

/* 1.45(3) Masonry filter button style */

.masonry-filter.button-style > li {
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .masonry-filter.button-style > li {
    margin-bottom: 10px;
  }

  .button-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 20px;
    flex-direction: column-reverse;
  }

  .slider-block-black {
    display: flex;
    flex-direction: column;
    height: 100px;
    gap: 30px;
  }

  .tp-caption.tp-resizeme.tp-paragraph {
    margin-top: 100px !important;
  }

  .slider-block-red {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .tp-caption.tp-resizeme.tp-2-paragraph {
    margin-top: 80px !important;
  }

  .site-button.rent-equipment {
    margin-top: 60px !important;
  }

  .slider-block-red > .home-hero,
  .slider-block-black > .home-hero {
    font-size: 46px !important;
  }

  .site-button.site-btn-effect.send-message-btn {
    width: 250px !important;
  }
}

.masonry-filter.button-style > li a {
  background-color: #666666;
  color: #fff;
  padding: 10px 20px;
  font-weight: 600;
  display: inline-block;
}

.masonry-filter.button-style > li.active a,
.masonry-filter.button-style > li a:hover,
.masonry-filter.button-style > li a:active,
.masonry-filter.button-style > li a:focus {
  background-color: #000;
  color: #fff;
}

.masonry-filter.button-style.has-bg > li a {
  background-color: #fff;
  color: #666;
}

.masonry-filter.button-style.has-bg > li.active a,
.masonry-filter.button-style.has-bg > li a:hover,
.masonry-filter.button-style.has-bg > li a:active,
.masonry-filter.button-style.has-bg > li a:focus {
  background-color: #000;
  color: #fff;
}

/* 1.45(4) Masonry filter button outline style */

.masonry-filter.outline-style > li {
  margin-right: 10px;
}

.masonry-filter.outline-style > li a {
  border-width: 2px;
  border-style: solid;
  border-color: #666666;
  color: #666666;
  padding: 10px 20px;
  font-weight: 600;
  display: inline-block;
}

.masonry-filter.outline-style.rounded-corner > li a {
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}

.masonry-filter.outline-style > li.active a,
.masonry-filter.outline-style > li a:hover,
.masonry-filter.outline-style > li a:active,
.masonry-filter.outline-style > li a:focus {
  border-color: #000;
  color: #000;
}

.masonry-filter.outline-style.has-bg > li a {
  border-color: #fff;
  color: #fff;
}

.masonry-filter.outline-style.has-bg > li.active a,
.masonry-filter.outline-style.has-bg > li a:hover,
.masonry-filter.outline-style.has-bg > li a:active,
.masonry-filter.outline-style.has-bg > li a:focus {
  border-color: #000;
  color: #000;
}

@media only screen and (max-width: 768px) {
  .masonry-item {
    width: 100%;
  }
}

/*-------------------------------------
	2. Title separators 
-------------------------------------*/
.wt-separator-two-part {
  padding-bottom: 15px;
}
.wt-separator-two-part-row {
  display: flex;
  align-items: center;
}
.wt-separator-two-part-left .section-head {
  margin-bottom: 0px;
}

.wt-separator-outer {
  overflow: hidden;
}

.wt-small-separator {
  font-family: 'Rajdhani', sans-serif;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 15px;
  position: relative;
  display: inline-block;
  font-weight: 600;
}
.wt-small-separator div {
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .wt-separator-two-part-right.text-right {
    text-align: left !important;
  }
  .wt-separator-two-part {
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 540px) {
  .wt-small-separator {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 5px;
    display: block;
  }
  .wt-small-separator div {
    display: block;
  }
}

/*center-title*/
.center.wt-small-separator-outer {
  text-align: center;
}
.center.wt-small-separator-outer h2 {
  max-width: 475px;
  margin: 0px auto;
}
.center .wt-small-separator div.sep-leaf-right {
  position: relative;
  margin-left: 50px;
}
.center .wt-small-separator div.sep-leaf-left {
  position: relative;
  margin-right: 50px;
}

.center .wt-small-separator div.sep-leaf-right:before,
.center .wt-small-separator div.sep-leaf-left:before {
  position: absolute;
  content: '';
  background-color: #d72323;
  width: 30px;
  height: 3px;
  bottom: 4px;
}

.center .wt-small-separator div.sep-leaf-right:before {
  right: 0px;
}
.center .wt-small-separator div.sep-leaf-left:before {
  left: 0px;
}
/*left-title*/

.left.wt-small-separator-outer {
  text-align: left;
}
.left .wt-small-separator div.sep-leaf-right {
  position: relative;
  margin-left: 40px;
  display: none;
}
.left .wt-small-separator div.sep-leaf-left {
  position: relative;
  margin-right: 40px;
}
.left .wt-small-separator div.sep-leaf-right:before,
.left .wt-small-separator div.sep-leaf-left:before {
  position: absolute;
  content: '';
  background-color: #d72323;
  width: 30px;
  height: 4px;
  bottom: 3px;
}
.left .wt-small-separator div.sep-leaf-right:before {
  right: 0px;
}
.left .wt-small-separator div.sep-leaf-left:before {
  left: 0px;
}

.wt-small-separator-outer
  .wt-small-separator.text-white
  div.sep-leaf-right:before,
.wt-small-separator-outer
  .wt-small-separator.text-white
  div.sep-leaf-left:before {
  background-color: #fff;
}

@media only screen and (max-width: 540px) {
  div.sep-leaf-right:before {
    display: none;
  }
  div.sep-leaf-left:before {
    display: none;
  }

  .center .wt-small-separator div.sep-leaf-left {
    margin: 0px;
  }
  .center .wt-small-separator div.sep-leaf-right {
    margin: 0px;
  }

  .left .wt-small-separator div.sep-leaf-left,
  .left .wt-small-separator div.sep-leaf-right {
    margin: 0px;
  }
}

/*Separator defualt*/

/*-------------------------------------
	4. TABS 
-------------------------------------*/

.wt-tabs:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

@media only screen and (max-width: 767px) {
  .wt-tabs .nav-tabs > li {
    float: none;
    display: block;
    width: 100%;
  }
}

.wt-tabs p:last-child {
  margin-bottom: 0;
}

/*4.1 tabs style 1 [ default ] */

.wt-tabs .tab-pane {
  padding: 20px 0;
}

.wt-tabs .nav-tabs > li > a {
  color: #000;
  font-size: 16px;
  padding: 15px;
  border-bottom: 1px solid transparent;
  border-radius: 0px;
  font-weight: 800;
  border-top: 2px solid transparent;
  display: block;
}

.wt-tabs .nav-tabs > li > a.active,
.wt-tabs .nav-tabs > li > a.active:focus {
  background-color: #fff;
  border-bottom: 1px solid #fff;
  color: #000;
  border-top: 2px solid #0f1221;
}

.wt-tabs .nav-tabs > li.active > a:hover {
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom: 1px solid #fff;
  color: #000;
  border-top: 2px solid #0f1221;
}

.wt-tabs .nav > li > a:focus,
.wt-tabs .nav > li > a:hover {
  background-color: transparent;
}

.wt-tabs .nav li a:hover {
  border-color: transparent;
}

.wt-tabs .nav-tabs.nav-justified > .active > a,
.wt-tabs .nav-tabs.nav-justified > .active > a:focus,
.wt-tabs .nav-tabs.nav-justified > .active > a:hover {
  border: 0px;
}

.wt-tabs .nav-tabs > li > a i {
  margin-right: 5px;
}

.wt-tabs.tabs-default.has-bg .nav-tabs > li > a {
  color: #fff;
}

.wt-tabs.tabs-default.has-bg .nav-tabs > li.active > a,
.wt-tabs.tabs-default.has-bg .nav-tabs > li.active > a:focus,
.wt-tabs.tabs-default.has-bg .nav-tabs > li.active > a:hover {
  color: #000;
  border-color: #ddd #ddd #fff;
}

.wt-tabs.tabs-default.has-bg .tab-pane {
  color: #fff;
}

/* tabs nav center */
.wt-tabs.nav-center > .nav-tabs {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

/*4.2 tabs bg  [ tabs background ] */
.wt-tabs.bg-tabs .nav-tabs > li > a {
  border: 0px;
  margin-right: -1px;
  border: 1px solid #ddd;
}

.wt-tabs.bg-tabs .nav-tabs > li > a.active {
  color: #d72323;
  border: 1px solid #ddd;
}

.wt-tabs.bg-tabs.has-bg .tab-pane {
  color: #fff;
}

/*4.3 tabs bg  [ tabs background ] */
.wt-tabs.vertical.bg-tabs .nav-tabs > li > a {
  border-bottom: 4px solid transparent;
}

.wt-tabs.vertical.bg-tabs .nav-tabs > li.active > a {
  border-bottom: 1px solid transparent;
  background-color: #000;
  color: #0f1221;
  border-bottom: 4px solid #0f1221;
}

.wt-tabs.vertical.right.bg-tabs .nav-tabs > li > a {
  border: 1px solid #ddd;
}

.wt-tabs.vertical.right.bg-tabs .nav-tabs > li.active > a {
  border-left: 1px solid transparent;
}

/*4.4 tabs bg & top border  [ tabs background & top border in active ] */

.wt-tabs.border-top .nav-tabs > li.active > a {
  color: #1a1a1a;
}

.wt-tabs.border-top .nav-tabs > li.active > a:after {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #000;
}

.wt-tabs.border-top.vertical .nav-tabs > li.active > a:after {
  top: 0px;
  left: -1px;
  width: 3px;
  height: 100%;
}

/*4.4 tabs style 2 [ content with border outer ] */
.wt-tabs.border .tab-pane {
  padding: 10px;
  border: 1px solid #ddd;
  margin-top: -1px;
}
.wt-tabs.border {
  border: none !important;
}
.wt-tabs.border .table td,
.wt-tabs.border .table th {
  border: 0px;
}
.wt-tabs.border .nav.nav-tabs {
  border: 0px;
}

/*4.5 tabs style 3  [ left-nav ] */

.wt-tabs.vertical .nav-tabs {
  float: left;
  width: 170px;
  border-bottom: none;
  border-right: 0px;
}

@media only screen and (max-width: 720px) {
  .wt-tabs.vertical .nav-tabs {
    width: 100%;
    float: none;
    border-right: 0px;
  }
  .wt-tabs.vertical .tab-content {
    width: 100%;
    margin-left: 0px !important;
  }
}

.wt-tabs.vertical .tab-pane {
  padding: 10px 0 10px 20px;
}

.wt-tabs.vertical .nav-tabs li {
  float: none;
  margin-right: 0px;
}

.wt-tabs.vertical .nav-tabs li a {
  margin-right: 0;
  border-right: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0;
  border-radius: 0px;
  color: #000;
  border-bottom: 1px solid transparent;
}

.wt-tabs.vertical .nav-tabs li.active a {
  border-bottom: 1px solid #0f1221;
  background-color: #fff;
}

.wt-tabs.vertical .tab-content {
  border-left: 0px;
  margin-left: 169px;
}

/*4.6 tabs style 4  [ left-nav & content with border ] */
.wt-tabs.vertical.border .tab-pane {
  padding: 20px;
  margin-left: -1px;
}

/*4.7 tabs style 5  [ right-nav ] */

.wt-tabs.vertical.right .nav-tabs {
  border-left: 1px solid #ddd;
  border-right: none;
  float: right;
}

.wt-tabs.vertical.right .nav-tabs li {
  margin-right: 0;
  margin-left: -1px;
}

.wt-tabs.vertical.right .nav-tabs li a {
  border-right: 1px solid transparent;
  border-left: none;
}

.wt-tabs.vertical.right .nav-tabs li.active a {
  border-right: 1px solid #ddd;
  border-left: none;
}

.wt-tabs.vertical.right .tab-content {
  border-left: none;
  border-right: 1px solid #ddd;
  margin-right: 169px;
  margin-left: 0;
}

.wt-tabs.vertical.right .tab-pane {
  padding: 10px 20px 10px 0;
}

/*4.8 tabs style 6  [ right-nav & content with border ]*/

.wt-tabs.vertical.right.border .tab-pane {
  padding: 20px;
  margin-right: -1px;
}

/*-------------------------------------
	5. Accordians 
-------------------------------------*/

.wt-accordion .wt-panel {
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
  border-radius: 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.acod-head {
  position: relative;
}

.acod-head a.collapsed {
  color: #1a1a1a;
}

.acod-title {
  margin-top: 0;
  margin-bottom: 0;
}

.acod-title a {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.acod-head .fa {
  margin-right: 5px;
}

.acod-head a {
  display: block;
  padding: 25px 60px 25px 15px;
}

.acod-head a,
.acod-head a:hover,
.acod-head a:active,
.acod-head a:focus {
  color: #0f1221;
}

.acod-body {
  color: #000;
}

.acod-body .acod-content p:last-child {
  margin-bottom: 0px;
}

.wt-accordion.acc-bdr .panel {
  border-bottom: 1px solid #ddd;
  margin-bottom: 0px;
}
.wt-accordion.acc-bdr .acod-content {
  padding: 0px 15px 30px 15px;
}

@media only screen and (max-width: 540px) {
  .acod-head a .indicator {
    font-size: 12px;
  }
}

/*Accodian open close indicator css*/

.acod-head .indicator {
  padding: 15px;
  color: #000;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  font-size: 12px;
}

.acod-head .indicator .fa {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.acod-head.acc-actives .indicator .fa:before,
.acod-head [aria-expanded='false'] .indicator .fa:before {
  content: '\f067';
  font-family: 'FontAwesome';
}

.acod-head.acc-actives .indicator .fa:before,
.acod-head [aria-expanded='true'] .indicator .fa:before {
  font-family: 'FontAwesome';
  content: '\f068';
}

.acod-head .collapsed .indicator .fa:before,
.acod-head .collapsed [aria-expanded='false'] .indicator .fa:before {
  content: '\f067';
  font-family: 'FontAwesome';
}

.acod-head .indicator .fa:before,
.acod-head [aria-expanded='true'] .indicator .fa:before {
  font-family: 'FontAwesome';
  content: '\f068';
}

/* 1. Accordion defult */

.acc-default .acod-title a {
  padding: 5px 0;
  border-bottom: 1px solid #ececec;
}

.acc-default .acod-head .indicator {
  padding: 0;
}

.acc-default.acc-has-bg .acod-head a,
.acc-default.acc-has-bg .acod-head a:hover,
.acc-default.acc-has-bg .acod-head a:active,
.acc-default.acc-has-bg .acod-head a:focus,
.acc-default.acc-has-bg .acod-head .indicator,
.acc-default.acc-has-bg .acod-body {
  color: #fff;
}

/* 2. Accordion outline */

.acc-outline .acod-title a {
  border: 1px solid #ddd;
}

.acc-outline.acc-has-bg .acod-head a,
.acc-outline.acc-has-bg .acod-head a:hover,
.acc-outline.acc-has-bg .acod-head a:active,
.acc-outline.acc-has-bg .acod-head a:focus,
.acc-outline.acc-has-bg .acod-head .indicator,
.acc-outline.acc-has-bg .acod-body {
  color: #fff;
}

/* 3. Accordion bg gray */

.acc-bg-gray a {
  background-color: #eff3f6;
}

.acc-bg-gray .acod-head .indicator {
  color: #fff;
  background-color: #1a1a1a;
}

.acc-bg-gray.acc-has-bg .acod-body {
  color: #fff;
}

/* 3. Accordion bg gray */

.acc-bg-white a {
  background-color: #fff;
}

.acc-bg-white .acod-head .indicator {
  color: #fff;
  background-color: #1a1a1a;
}

.acc-bg-white.acc-has-bg .acod-body {
  color: #fff;
}

/* 4. Accordion bg primary */

.acc-bg-primary a {
  background-color: #000;
}

.acc-bg-primary .acod-head a,
.acc-bg-primary .acod-head a:hover,
.acc-bg-primary .acod-head a:active,
.acc-bg-primary .acod-head a:focus {
  color: #fff;
}

.acc-bg-primary .acod-head .indicator {
  color: #fff;
}

.acc-bg-primary.acc-has-bg .acod-body {
  color: #fff;
}

/* 5. Accordion bg dark */

.acc-bg-dark a {
  background-color: #000;
}

.acc-bg-dark .acod-head a,
.acc-bg-dark .acod-head a:hover,
.acc-bg-dark .acod-head a:active,
.acc-bg-dark .acod-head a:focus {
  color: #fff;
}

.acc-bg-dark .acod-head .indicator {
  background-color: #1a1a1a;
}

.acc-bg-dark .acod-head .fa {
  color: #fff;
}

.acc-bg-dark.acc-has-bg .acod-body {
  color: #fff;
}

/*-------------------------------------
	6. Carousels
-------------------------------------*/

/* 6.1 Client carousel 1*/

.ow-client-logo {
  display: table;
  width: 100%;
}

.remove-line.ow-client-logo:after {
  visibility: hidden;
}

.client-logo {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.owl-carousel .ow-client-logo img {
  max-height: 100%;
  width: auto;
  display: inline-block;
  height: 75px;
}

.client-logo-media img {
  max-width: 100% !important;
  margin: 0px auto !important;
  width: auto !important;
}

@media only screen and (max-width: 720px) {
  .ow-client-logo {
    margin: 0px 1px;
  }
}

/*-------------------------------------
	7. Testimonials 
-------------------------------------*/

.testimonial-pic {
  background: #fff;
  width: 65px;
  height: 65px;
  position: relative;
  display: inline-block;
}

.testimonial-pic.radius {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
}

.testimonial-pic.radius img {
  width: 100%;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  padding: 5px;
}

.quote-left,
.quote-right {
  position: relative;
}

.quote-left:before,
.quote-right:after {
  font-family: 'FontAwesome';
  position: absolute;
  z-index: 10;
  top: 15px;
}

.quote-left:before {
  content: '\f10d';
  left: 0;
}

.quote-right:after {
  content: '\f10e';
  left: auto;
  right: 0;
}

.secondary-button {
  background-color: black !important;
  color: white !important;
}

.right-button{
  background-color: #d72323 !important;
  color: white !important;
}

/*testimonia home 1*/
.testimonial-block-position-outer {
  position: relative;
}
.testimonial-block-position {
  margin-bottom: 30px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 420px;
}

.button-container-pg2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 460px;
}
.arrow {
  margin-left: 10px; /* Adds space between text and arrow */
  transition: margin-left 0.5s ease;
}

@media only screen and (max-width: 767px) {
  .testimonial-block-position {
    margin-bottom: 0px;
  }
}

.testimonial-1-outer {
  position: relative;
  z-index: 1;
}
.testimonial-1-content-outer {
  position: relative;
}

.testimonial-1 {
  margin: 0px 0px 0px 0px;
  position: relative;
}

.testimonial-1 .testimonial-pic {
  width: 105px;
  height: 105px;
  border: 3px solid #fff;
  border-radius: 50%;
  margin: -50px 0px 20px 0px;
}
.testimonial-1 .testimonial-pic img {
  border-radius: 50%;
  width: 100%;
}
.testimonial-1 .testimonial-info {
  padding: 0px;
}

.testimonial-1 .testimonial-text {
  padding: 80px 30px 100px 30px;
  position: relative;
  z-index: 1;
}
.testimonial-1 .testimonial-text p {
  margin-bottom: 0px;
  font-family: 'Muli', sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
}
.testimonial-1 .testimonial-text .fa-quote-left {
  position: absolute;
  font-size: 40px;
  left: 20px;
  top: 20px;
  z-index: -1;
  color: #d72323;
}

.testimonial-1 .testimonial-detail {
  padding: 0px 30px 80px 30px;
}
.testimonial-1 .testimonial-name {
  padding-right: 0px;
  display: block;
  font-size: 24px;
  font-weight: 700;
  font-family: 'Rajdhani', sans-serif;
}
.testimonial-1 .testimonial-name::after {
  display: none;
}

.testimonial-1 .testimonial-position {
  display: block;
  font-size: 16px;
  position: relative;
  line-height: 16px;
  font-weight: 600;
}

.testimonial-1-content.owl-carousel.owl-btn-top-right.long-arrow-next-prev
  .owl-stage-outer {
  padding-top: 0px;
}
.testimonial-1-content.owl-carousel.owl-btn-top-right.long-arrow-next-prev
  .owl-nav {
  position: absolute;
  right: 30px;
  top: 170px;
  width: auto;
}

.testimonial-1-content {
  text-align: center;
}

.testimonial-1-outer .hilite-large-title {
  position: absolute;
  right: -1px;
  bottom: -20px;
  z-index: 1;
}
.testimonial-1-outer .hilite-large-title span {
  font-size: 120px;
  line-height: 100px;
  color: #fff;
  opacity: 1;
  font-family: 'Rajdhani', sans-serif;
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: -2px;
}

.testimonial-1-content.owl-carousel .owl-dots {
  margin-top: -60px;
}

@media only screen and (max-width: 767px) {
  .testimonial-1-content.owl-carousel.owl-carousel .owl-nav {
    margin: 0px;
  }
}

.arrow-position-bottom .owl-nav {
  margin: 0px;
}
.arrow-position-bottom .owl-nav .owl-prev,
.arrow-position-bottom .owl-nav .owl-next {
  position: absolute;
  bottom: 90px;
  background-color: transparent;
  font-size: 28px;
  padding: 0px;
  margin: 0px;
}
.arrow-position-bottom .owl-nav .owl-prev {
  left: 5px;
}
.arrow-position-bottom .owl-nav .owl-next {
  right: 5px;
}

/*testimonia home 2*/
.testimonial2-block-position-outer {
  position: relative;
}
.testimonial2-block-position {
  margin: 40px 0px 40px 40px;
}

.testimonial-2-outer {
  position: relative;
  z-index: 1;
}
.testimonial-2-content-outer {
  position: relative;
}

.testimonial-2 {
  margin: 0px 0px 0px 0px;
  position: relative;
}

.testimonial-2-content {
}
.testimonial-2-content:before {
  content: '';
  position: absolute;
  left: -40px;
  top: -40px;
  bottom: -40px;
  border: 10px solid #fff;
  border-right: 0px;
  width: 342px;
}

.testimonial-2 .testimonial-pic {
  width: 105px;
  height: 105px;
  border: 3px solid #fff;
  border-radius: 50%;
  margin: 0px 0px 20px 0px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.testimonial-2 .testimonial-pic img {
  border-radius: 50%;
  width: 100%;
}
.testimonial-2 .testimonial-info {
  padding: 0px;
}

.testimonial-2 .testimonial-text {
  padding: 80px 30px 40px 30px;
  position: relative;
  z-index: 1;
}
.testimonial-2 .testimonial-text p {
  margin-bottom: 0px;
  font-family: 'Muli', sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
}
.testimonial-2 .testimonial-text .fa-quote-left {
  position: absolute;
  font-size: 40px;
  left: 20px;
  top: 20px;
  z-index: -1;
  color: #d72323;
}

.testimonial-2 .testimonial-detail {
  padding: 0px 30px 80px 30px;
}
.testimonial-2 .testimonial-name {
  padding-right: 0px;
  display: block;
  font-size: 24px;
  font-weight: 700;
  font-family: 'Rajdhani', sans-serif;
}
.testimonial-2 .testimonial-name::after {
  display: none;
}

.testimonial-2 .testimonial-position {
  display: block;
  font-size: 16px;
  position: relative;
  line-height: 16px;
  font-weight: 600;
}

.testimonial-2-content.owl-carousel.owl-btn-top-right.long-arrow-next-prev
  .owl-stage-outer {
  padding-top: 0px;
}
.testimonial-2-content.owl-carousel.owl-btn-top-right.long-arrow-next-prev
  .owl-nav {
  position: absolute;
  right: 30px;
  top: 170px;
  width: auto;
}

.testimonial-2-content {
  text-align: center;
}

.testimonial-2-outer .hilite-large-title {
  position: absolute;
  right: -1px;
  bottom: -20px;
  z-index: 1;
}
.testimonial-2-outer .hilite-large-title span {
  font-size: 120px;
  line-height: 100px;
  color: #fff;
  opacity: 1;
  font-family: 'Rajdhani', sans-serif;
  display: block;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: -2px;
}

.testimonial-2-content.owl-carousel .owl-dots {
  margin-top: -60px;
}

@media only screen and (max-width: 1199px) {
  .testimonial-2-content:before {
    width: 286px;
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-2-content:before {
    display: none;
  }
  .testimonial2-block-position {
    margin: 40px 0px 80px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial2-block-position {
    margin: 0px;
  }
  .testimonial2-block-position .owl-carousel .owl-nav {
    margin-top: 10px;
  }
}

.arrow-dark-position-bottom .owl-nav {
  margin: 0px;
}
.arrow-dark-position-bottom .owl-nav .owl-prev,
.arrow-dark-position-bottom .owl-nav .owl-next {
  position: absolute;
  bottom: 90px;
  background-color: transparent;
  font-size: 28px;
  padding: 0px;
  margin: 0px;
  color: #1a1a1a;
}
.arrow-dark-position-bottom .owl-nav .owl-prev {
  left: 5px;
}
.arrow-dark-position-bottom .owl-nav .owl-next {
  right: 5px;
}

.bg-right-half-block-outer {
  position: relative;
}

.bg-right-half-block {
  position: absolute;
  width: 3000px;
  left: calc(100% + -27%);
  top: -80px;
  bottom: -80px;
  z-index: -1;
  background-color: #d72323;
}
@media only screen and (max-width: 991px) {
  .bg-right-half-block {
    display: none;
  }
}
.testimonial-outline-text {
  position: absolute;
  left: 40px;
  bottom: 40px;
}
.testimonial-outline-text span {
  font-size: 90px;
  line-height: 80px;
  display: block;
  font-weight: 700;
  opacity: 1;
  color: #d72323 !important;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  -ms-text-fill-color: rgba(0, 0, 0, 0);
  -moz-text-fill-color: rgba(0, 0, 0, 0);
  -o-text-fill-color: rgba(0, 0, 0, 0);
  -webkit-text-stroke-width: 1px;
  -ms-text-stroke-width: 1px;
  -moz-text-stroke-width: 1px;
  -o-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  -ms-text-stroke-color: #fff;
  -moz-text-stroke-color: #fff;
  -o-text-stroke-color: #fff;
}
@media only screen and (max-width: 767px) {
  .testimonial-outline-text {
    display: none;
  }
}

/*---testimonial drone 1---*/
.animated-left-drone {
  position: relative;
}
.animated-left-drone-media {
  padding-top: 120px;
  margin-left: -150px;
}

@media only screen and (max-width: 1199px) {
  .animated-left-drone-media {
    margin-left: 0px;
  }
}

/*---testimonial drone 2---*/
.animated-right-drone {
  position: relative;
}
.animated-right-drone-media {
  position: absolute;
  right: 0px;
  top: 0px;
  padding-top: 130px;
}
@media only screen and (max-width: 991px) {
  .animated-right-drone-media {
    display: none;
  }
}
/*-------------------------------------
	8. Pricing table
 -------------------------------------*/

.pricingtable-inner {
  text-align: center;
  border: 2px solid #d72323;
  padding: 50px 20px;
}

.pricingtable-inner.pricingtable-highlight {
  border: 2px solid #1a1a1a;
}

.pricing-table-top-section {
  position: relative;
  overflow: hidden;
}

.pricingtable-price {
  position: relative;
}

.pricingtable-sign {
  font-size: 25px;
  color: #d72323;
  font-weight: 700;
  position: relative;
  padding-right: 14px;
}

.pricingtable-bx {
  font-size: 50px;
  line-height: 58px;
  color: #0f1221;
  font-weight: 700;
}

.pricingtable-type {
  font-size: 20px;
  color: #0f1221;
  display: block;
  letter-spacing: 0px;
}

.pricingtable-title {
  padding-bottom: 30px;
  position: relative;
  z-index: 1;
}

.pricingtable-title .title-style-2 {
  text-transform: uppercase;
  font-size: 24px;
}

.table-featured-title {
  font-style: italic;
  padding: 4px 50px;
  position: absolute;
  left: -30px;
  top: 30px;
  -ms-transform: rotate(42deg);
  -webkit-transform: rotate(42deg);
  transform: rotate(-42deg);
}

.pricingtable-title * {
  margin: 0px;
}

.pricingtable-features {
  margin: 0;
  padding-bottom: 30px;
  list-style: none;
  color: #000;
}

.pricingtable-features li {
  padding: 10px 0px;
  font-size: 18px;
}

.pricingtable-features li i {
  margin: 0 3px;
}
.pricingtable-highlight-outer {
  position: relative;
  z-index: 10;
}
.pricingtable-highlight {
  position: relative;
  z-index: 99;
}

@media only screen and (max-width: 540px) {
  .pricingtable-bx {
    font-size: 40px;
    line-height: 40px;
  }
  .pricingtable-sign {
    font-size: 20px;
    padding-right: 5px;
  }
  .pricingtable-features li {
    padding: 10px 0px;
    font-size: 14px;
  }
  .pricingtable-inner {
    text-align: center;
    border: 14px solid #ebebeb;
    padding: 20px 20px;
  }
}

.pt-with-icon {
  margin-top: 70px;
}
.pt-pic-outer {
  position: relative;
  top: -70px;
  margin-bottom: -45px;
}
.pt-pic {
  width: 140px;
  margin: 0px auto;
  background-color: #dff6fc;
  border-radius: 50%;
  height: 140px;
  line-height: 140px;
  text-align: center;
}

.pt-pic img {
  width: 75px;
}

@media only screen and (max-width: 767px) {
  .pricingtable-row.no-col-gap [class*='col-xs-'],
  .pricingtable-row.no-col-gap [class*='col-sm-'],
  .pricingtable-row.no-col-gap [class*='col-md-'],
  .pricingtable-row.no-col-gap [class*='col-lg-'] {
    padding-left: 0px;
    padding-right: 0px;
  }

  .pricingtable-row.m-b30 {
    margin-bottom: 0px;
  }
}

/*-------------------------------------
	9. Alert box 
-------------------------------------*/

.alert.alert-sm {
  padding: 5px 15px;
  font-size: 12px;
}

.alert.alert-lg {
  padding: 25px 15px;
  font-size: 16px;
}

.alert.alert-xl {
  padding: 35px 15px;
  font-size: 18px;
}

.alert[class*='alert-'] i {
  margin-right: 8px;
}

.alert.no-radius {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
}

.alert.no-bg {
  background-color: transparent;
  border-width: 2px;
}

.alert[class*='alert-'] ul {
  padding-left: 25px;
  margin-top: 10px;
}

.alert[class*='alert-'] ul li:before {
  color: #a94442;
}

/*-------------------------------------
	10. Image effects 
-------------------------------------*/

.wt-img-effect {
  position: relative;
  overflow: hidden;
  display: block;
}

.wt-img-effect img {
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: all 0.25s;
  -moz-transition: all 0.25s;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
}

/* 10.1 image-opacity */

.wt-img-effect.opacity img:hover {
  opacity: 0.8;
  -moz-opacity: 0.8;
  -webkit-opacity: 0.8;
}

/*10.2 image-zoom */

.wt-img-effect.zoom-slow img {
  transition: all 10s;
  -moz-transition: all 10s;
  -webkit-transition: all 10s;
  -o-transition: all 10s;
}

.wt-img-effect.zoom-slow:hover img {
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -o-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
}

.bg-blur-img:hover {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

/*10.3 image-zoom-slow */

.wt-img-effect.zoom:hover img {
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

/*10.4 image-shrink */

.wt-img-effect.shrink:hover img {
  transform: scale(0.8);
  -ms-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -o-transform: scale(0.8);
  -moz-transform: scale(0.8);
}

/*10.5 image-side-pan */

.wt-img-effect.side-pan:hover img {
  margin-left: -9%;
  transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -moz-transform: scale(1.2);
}

/*10.6 image-vertical-pan */

.wt-img-effect.vertical-pan:hover img {
  margin-top: -10%;
  transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -moz-transform: scale(1.2);
}

/*10.7 image-fade-in */

.wt-img-effect.fade-in {
  background: #000;
}

.wt-img-effect.fade-in img {
  opacity: 0.65;
  -moz-opacity: 0.65;
  -webkit-opacity: 0.65;
}

.wt-img-effect.fade-in:hover img {
  opacity: 1;
  -moz-opacity: 1;
  -webkit-opacity: 1;
}

/*10.8 image-fade-out */

.wt-img-effect.fade-out {
  background: #000;
}

.wt-img-effect.fade-out:hover img {
  opacity: 0.7;
  -moz-opacity: 0.7;
  -webkit-opacity: 0.7;
}

/*10.9 image-rotate */

.wt-img-effect.rotate:hover img {
  -moz-transform: scale(1.5) rotate(-20deg);
  -webkit-transform: scale(1.5) rotate(-20deg);
  -o-transform: scale(1.5) rotate(-20deg);
  -ms-transform: scale(1.5) rotate(-20deg);
  transform: scale(1.5) rotate(-20deg);
}

/*10.10 image-sepia */

.wt-img-effect.sepia img {
  -webkit-filter: sepia(100%);
  filter: sepia(100%);
}

.algo-image-hover.sepia:hover img {
  -webkit-filter: sepia(0);
  filter: sepia(0);
}

/*10.11 image-blurr */

.wt-img-effect.blurr img {
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.wt-img-effect.blurr:hover img {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}

/*10.12 image-blurr-invert */

.wt-img-effect.blurr-invert img {
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.wt-img-effect.blurr-invert img {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}

.wt-img-effect.blurr-invert:hover img {
  filter: blur(0px);
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
}

/*10.13 image off color */

.wt-img-effect.off-color img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}

.wt-img-effect.off-color:hover img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}

/*10.14 image on color */

.wt-img-effect.on-color img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}

.wt-img-effect.on-color:hover img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}

/*-------------------------------------
	11. Modal pop 
-------------------------------------*/

.modal-xlg {
  width: 1000px;
}

.provider_registration .bootstrap-select div.dropdown-menu ul li a {
  font-weight: 600;
}

.provider_registration
  .bootstrap-select
  div.dropdown-menu
  ul
  li
  a
  span.childcat {
  font-weight: 400;
  color: #7c7c7c;
}

@media only screen and (max-width: 1024px) {
  .modal-xlg {
    width: auto;
    margin-left: 15px;
    margin-right: 15px;
  }
}

/*-------------------------------------
	12. Social icons 
-------------------------------------*/

.social-icons {
  list-style: none;
  margin-left: -3px;
  margin-right: -3px;
  font-size: 12px;
  margin: 0px;
}

.social-icons li {
  display: inline-block;
  text-align: center;
}

.social-icons li a {
  display: block;
  padding: 3px;
}

.social-icons.social-md {
  font-size: 18px;
}
.social-icons.social-lg li a {
  font-size: 24px;
}
.social-icons.has-bg li a:hover {
  color: #fff;
}
.social-icons.social-light a {
  color: #fff;
}
.social-icons.social-light.has-bg li a:hover {
  color: #777;
}
.social-icons.social-dark a {
  color: #000000;
}
.social-icons.social-dark a:hover {
  color: #fff;
}
.social-icons.social-dark.has-bg li a:hover {
  color: #777;
}

.social-icons.social-square a,
.social-icons.social-radius a {
  width: 24px;
  height: 24px;
  line-height: 24px;
  padding: 0;
}

.social-icons.social-square.social-md a,
.social-icons.social-radius.social-md a {
  width: 36px;
  height: 36px;
  line-height: 36px;
  padding: 0;
}

.social-icons.social-square.social-lg a,
.social-icons.social-radius.social-lg a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  padding: 0;
}

.social-icons.social-square.social-primary a,
.social-icons.social-radius.social-primary a {
  background-color: #000;
  color: #fff;
}

.social-icons.social-square.social-primary a:hover,
.social-icons.social-radius.social-primary a:hover {
  background-color: #1a1a1a;
}

.social-icons.social-square.social-dark a,
.social-icons.social-radius.social-dark a {
  background-color: #fff;
}

.social-icons.social-square.social-dark a:hover,
.social-icons.social-radius.social-dark a:hover {
  background-color: #1a1a1a;
  color: #fff;
}

.social-icons.social-square.social-dark.has-bg a:hover,
.social-icons.social-radius.social-dark.has-bg a:hover {
  border: 1px solid #777;
}

.social-icons.social-darkest a {
  color: #000;
}

.social-icons.social-square.social-darkest a:hover,
.social-icons.social-radius.social-darkest a:hover {
  background-color: #000;
  color: #fff;
}

.social-icons.social-radius a {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
}

.social-icons.social-square.social-dark.white-border a,
.social-icons.social-radius.social-dark.white-border a {
  background: none;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 1);
}

/*12.1 social icon default */

.wt-social-icon {
  display: inline-block;
  margin: 0 -3px;
  padding: 0;
}

.wt-social-icon li {
  display: inline-block;
  padding: 0;
  font-size: 12px;
}

.wt-social-icon li a {
  display: inline-block;
  width: 24px;
  height: 24px;
  padding: 4px;
}

.wt-social-icon li .fa {
  vertical-align: middle;
}

/*12.2 social icon with border */

.wt-social-icon.border li {
  padding: 0 3px;
}

.wt-social-icon.border li a {
  border: 1px solid #efeded;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

/*12.3 social icon dark */

.wt-social-links.dark li {
  padding: 0 3px;
}

.wt-social-icon.dark li a {
  border: 1px solid #777777;
  color: #777777;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

/*-------------------------------------
	13. Breadcrumb 
-------------------------------------*/

/*13.1 breadcrumb-row-1*/

.wt-breadcrumb {
  margin: 0;
  list-style: none;
}
.wt-breadcrumb li {
  padding: 0px;
  margin-right: 3px;
  color: #fff;
  position: relative;
  display: inline-block;
  font-size: 22px;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 700;
}

.wt-breadcrumb li a {
  color: #fff;
}
.wt-breadcrumb li a:hover {
  color: #1a1a1a;
}
.wt-breadcrumb li:last-child {
  color: #1a1a1a;
}
.wt-breadcrumb li:first-child a i {
  font-size: 18px;
  vertical-align: text-top;
}
.wt-breadcrumb li:last-child:after {
  display: none;
}
/*13.2 Breadcrumb-1*/

.breadcrumb-style-2 li:after {
  content: '\f101';
  margin-left: 7px;
  font-family: 'FontAwesome';
  color: #fff;
  font-size: 16px;
  font-weight: normal;
}

/*-------------------------------------
	14. Google map 
-------------------------------------*/
.google-map {
  width: 100%;
  height: 460px;
}
/*-------------------------------------
	17. Form elements 
-------------------------------------*/

label {
  font-weight: 700;
  margin-bottom: 10px;
}

.panel-default,
.panel-default > .panel-heading {
  border-color: #e7ecf1;
  color: inherit;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #e7ecf1;
}

.form-group {
  margin-bottom: 25px;
}

.form-control {
  border-color: #e1e6eb;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  height: 40px;
  font-size: 13px;
  line-height: 20px;
  padding: 9px 12px 9px 10px;
}

.form-control:focus {
  border-color: #e1e1e1;
  outline: 0;
}

.form-control:focus,
.has-error .form-control:focus {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control.kv-fileinput-caption {
  height: 39px;
}

.form-group.form-inline .radio,
.form-group.form-inline .checkbox {
  margin-right: 15px;
}

/* 17.1 input group */

.input-group {
  width: 100%;
}

.input-group .form-control,
.input-group-addon,
.input-group-btn {
  display: table-cell;
}

.input-group-addon {
  background: #fff;
  border-color: #e1e1e1;
  padding: 6px 14px;
  font-size: 16px;
}

.input-group-addon.font-size-20 {
  font-size: 20px;
}

.input-group-addon.fixed-w {
  text-align: center;
  padding: 6px 0;
  width: 40px;
}

.input-group-addon.v-align-t {
  line-height: 30px;
}

/* 17.3 radio & checkbox */

input[type='checkbox'],
input[type='radio'] {
  opacity: 0;
  margin-left: 1px !important;
}

input[type='checkbox'] + label,
input[type='radio'] + label {
  display: block;
  padding-left: 20px !important;
  position: relative;
}

input[type='checkbox'] + label:hover:before,
input[type='radio'] + label:hover:before {
  border-color: #3396d1;
}

input[type='checkbox'] + label:before,
input[type='radio'] + label:before {
  background-color: #fff;
  border: 2px solid #b6b7b8;
  border-radius: 0px;
  content: '';
  display: inline-block;
  height: 16px;
  line-height: 1;
  margin-left: -20px;
  position: absolute;
  top: 2px;
  transition: all linear 0.1s;
  width: 16px;
}

input[type='checkbox'] + label:after {
  color: #fff;
  content: '\f00c';
  font-family: FontAwesome;
  font-size: 0px;
  -webkit-font-smoothing: antialiased;
  left: 6px;
  position: absolute;
  top: 7px;
  transition: all linear 0.1s;
}

input[type='checkbox']:checked + label:before {
  border-width: 7px;
  border-color: #3396d1;
}

input[type='checkbox']:checked + label:after {
  font-size: 10px;
  left: 2px;
  top: 2px;
}

input[type='radio'] + label:before {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  content: '';
}

input[type='radio']:checked + label:before {
  border-color: #3396d1;
  border-width: 5px;
}

.form-inline .checkbox input[type='checkbox'],
.form-inline .radio input[type='radio'] {
  position: absolute;
  left: 0;
  bottom: 0;
}

/*17.4 Browse Button css */

.btn-file {
  overflow: hidden;
  position: relative;
}

.btn-file input[type='file'] {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  cursor: inherit;
  display: block;
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

/*17.8 validation symbol */

.has-feedback label ~ .form-control-feedback {
  top: 31px;
}

/*17.9 error for select box */

.has-error .bootstrap-select .dropdown-toggle,
.has-error .bootstrap-select .dropdown-toggle:hover,
.has-error .bootstrap-select .dropdown-toggle:focus {
  border-color: #a94442 !important;
}

/*17.10 succes for select box */

.has-success .bootstrap-select .dropdown-toggle,
.has-success .bootstrap-select .dropdown-toggle:hover,
.has-success .bootstrap-select .dropdown-toggle:focus {
  border-color: #3c763d !important;
}
/*17.11(1) resize-vertical*/
.resize-vertical {
  resize: vertical;
}

/*17.11(2) resize-horizontal*/
.resize-horizontal {
  resize: horizontal;
}

/*17.11(3) resize-none*/
.resize-none {
  resize: none;
}

/*-------------------------------------
	18. Loading 
-------------------------------------*/

/*18.1 loading - 1*/

.loading-area {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  overflow: hidden;
}

.loading-box {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  z-index: 9999;
}

.loading-pic {
  width: 100%;
  position: absolute;
  top: 50%;
  z-index: 99999;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*18.2 loading - 2*/

.loading-cover,
.loading-srh-bar,
.loading-map {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.loading-cover .fa-spinner,
.loading-srh-bar .fa-spinner,
.loading-map .fa-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -10px;
  font-size: 25px;
  color: #333;
}

/*-------------------------------------
	19. Widgets 
-------------------------------------*/

.widget {
  margin-bottom: 40px;
}
.widget-title {
  margin: 0 0 25px;
}
.widget-title {
  margin-bottom: 20px;
}

.recent-posts-entry ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

/* 19.1 widget listing*/

.site-footer .widget_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.site-footer .widget_services ul li {
  margin-bottom: 13px;
  position: relative;
  padding: 10px 10px 10px 0px;
  margin-bottom: 0;
  display: inline-block;
}

.site-footer .widget_services ul li {
  font-size: 14px;
  font-weight: normal;
  text-transform: none;
}

.site-footer .widget_services ul li {
  display: table;
  width: 100%;
  padding: 2px 0px;
}
.site-footer .widget_services ul li a {
  display: table-cell;
  width: 50%;
  font-weight: 400;
}

.site-footer .widget_services ul li a {
  color: #000;
}

.site-footer .widget_services ul li a:hover {
  color: #d72323;
}
.site-footer.footer-light .widget_services ul li a:hover {
  color: #1a1a1a;
}

.site-footer .widget_services li {
  border-bottom: none;
  line-height: 28px;
}

.site-footer .widget_services li:before {
  top: 0;
  left: 1px;
}

.site-footer .widget_services.inline-links li {
  display: block;
  border-bottom: none;
  position: relative;
  border-bottom: 1px dashed rgba(102, 102, 102, 0.3);
}

.site-footer .widget_services.inline-links li:last-child {
  border-bottom: 0px;
}

@media only screen and (max-width: 375px) {
  .site-footer .widget_services ul li {
    display: block;
  }
  .site-footer .widget_services ul li a {
    display: block;
    width: 100%;
  }
}

/* 19.1 widget listing*/

.widget_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.widget_services ul li {
  border-bottom: 1px solid #e1e1e1;
  position: relative;
  padding: 10px 0px 10px 0px;
  line-height: 20px;
}

.widget_services ul li {
  font-size: 14px;
}
.widget_services ul li:last-child {
  font-size: 14px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: 0px;
}

.widget_services ul li a {
  color: #000;
}
.widget_services ul li a:hover {
  color: #d72323;
}

.widget_services ul li .badge {
  float: right;
  background: none;
  color: #111;
  font-weight: normal;
  font-size: 14px;
  font-weight: 600;
}

.widget_services li li {
  border-bottom: none;
  padding-left: 25px;
  padding-right: 5px;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 28px;
}

.widget_services li li:before {
  top: 0;
  left: 1px;
}
/*----Widget----*/
.widget_archives ul {
  list-style: none;
  margin-bottom: 0px;
}
.widget_archives ul li {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e1e1e1;
  position: relative;
  padding: 10px 0px 10px 0px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
}
.widget_archives ul li:first-child {
  padding-top: 0px;
}
.widget_archives ul li:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: 0px;
}

.widget_archives ul li a {
  color: #000;
}

.widget_archives ul li a:hover {
  color: #d72323;
}

.widget_archives ul li .badge {
  float: right;
  background: none;
  color: #111;
  font-weight: normal;
  font-size: 14px;
  font-weight: 600;
}

/*-----All Services------*/
.all_services {
  background-color: #1a1a1a;
}

.all_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.all_services ul li {
  border-bottom: 1px solid #010c1d;
  position: relative;
}

.all_services ul li {
  font-size: 16px;
}

.all_services ul li:last-child {
  border-bottom: 0px;
}

.all_services ul li a {
  position: relative;
  padding: 20px 10px 20px 30px;
  margin-bottom: 0;
  line-height: 20px;
  display: block;
  color: #fff;
  font-family: 'Rajdhani', sans-serif;
  font-size: 24px;
  letter-spacing: 1px;
}

.all_services ul li a:hover {
  background-color: #d72323;
}

.all_services ul li a.active {
  background-color: #d72323;
}

.service-side-btn a.btn-block {
  background-color: #fff;
}
.service-side-btn a.btn-block:hover {
  background-color: #d72323;
}
@media only screen and (max-width: 767px) {
  .need-help {
    margin-bottom: 0px;
  }
}

/*19.2 widget search custom*/

.search-bx button {
  border-left-color: #fff;
  padding: 9px 20px;
  color: #fff !important;
  background-color: #d72323;

  border: 0px;
  height: 56px;
  border-radius: 0px;
}

.search-bx .input-group .form-control {
  border-right: 1px solid transparent;
  height: 56px;
  background-color: #fff;
}

/*19.3 widget search wp-default*/

.widget_search .screen-reader-text {
  display: block;
}

.searchform {
  position: relative;
}

.searchform input[type='text'] {
  width: 100%;
  height: 40px;
  padding: 10px 90px 10px 15px;
  border: 1px solid #cccccc;
}

.searchform input[type='submit'] {
  height: 40px;
  padding: 10px 15px;
  background-color: #0f1221;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #ffffff;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.searchform input[type='submit']:hover,
.searchform input[type='submit']:focus,
.searchform input[type='submit']:active {
  background-color: #6ab33e;
  color: #ffffff !important;
  border-bottom-color: #5a9e2f;
}

/*19.4 widget recent-posts*/

.recent-posts-entry .post-date,
.tweets-feed-entry .tweet-date {
  color: #3396d1;
  font-style: normal;
}

.widget .post-title {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
  color: #999;
  font-weight: 500;
}

.wt-post-header {
  margin-bottom: 15px;
}

.widget .wt-post-meta {
  margin-bottom: 3px;
}
.widget .wt-post-meta ul {
  display: table;
}
.widget .wt-post-meta li {
  font-size: 14px;
  color: #d72323;
  display: table-cell;
}

.recent-posts-entry .widget-post:last-child {
  border: none;
  margin-bottom: 0px;
}

.recent-posts-entry .wt-post-date {
  background-color: #0e0e0e;
  width: 50px;
  height: 60px;
  float: left;
}

.recent-posts-entry .wt-post-media {
  width: 70px;
  float: left;
}

.recent-posts-entry .wt-post-date strong {
  display: block;
  font-size: 24px;
}

.recent-posts-entry .wt-post-date span {
  display: block;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
}

.recent-posts-entry .widget-post {
  margin-bottom: 20px;
}

.recent-posts-entry .wt-post-info {
  margin-left: 85px;
}

.recent-posts-entry .wt-post-info .wt-post-meta ul li {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  font-family: 'Rajdhani', sans-serif;
}

.recent-posts-entry .wt-post-info .post-title {
  margin-top: 0px;
}

/*19.8 widget tags-list*/

.widget_tag_cloud a {
  padding: 4px 14px;
  font-size: 14px;
  display: inline-block;
  margin: 0 0 4px 4px;
  color: #1a1a1a;
  position: relative;
  z-index: 1;
  font-weight: 600;
  border: 1px solid #1a1a1a;
}

.widget_tag_cloud a:hover {
  background-color: #d72323;
  color: #fff;
}
.home-hero {
  font-size: 56px !important;
}

/*Widget Product*/
.product-widget-box {
  position: relative;
  padding-bottom: 25px;
}
.product-widget-box:last-child {
  padding-bottom: 0px;
}
.product-widget-media {
  float: left;
  width: 75px;
  border: 1px solid #e1e1e1;
  padding: 2px;
}
.product-widget-info {
  padding-left: 85px;
}

.product-widget-info .product-widget-title {
  margin-bottom: 5px;
}
.product-widget-info .price ins {
  font-size: 18px !important;
  color: #d72323;
  font-weight: 600;
  font-family: 'Rajdhani', sans-serif;
  text-decoration: none;
}

.price-renting {
  font-size: 14px !important;
  font-weight: 800;
  font-family: 'Rajdhani', sans-serif;
  text-decoration: none;
}

/*Product Single*/
.wt-product-gallery {
  border: 1px solid #ddd;
}
.p-single-old-price {
  color: #141414;
  font-size: 17px;
  line-height: 1.1;
  display: block;
  display: block;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 500;
}

.p-single-new-price {
  font-size: 26px;
  line-height: 1.2;
  color: #d72323;
  margin-top: 5px;
  display: block;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 700;
}

@media only screen and (max-width: 540px) {
  .p-single-new-price {
    font-size: 20px;
  }
}
.product-single-availability {
  margin-bottom: 15px;
  margin-top: 15px;
}
.product-single-availability span {
  color: #1a1a1a;
  font-weight: 700;
}
.product-single-rating {
  margin-bottom: 15px;
}

.product_meta .sku,
.product_meta a {
  font-weight: normal;
  color: inherit;
}
.product_meta .sku_wrapper,
.product_meta .posted_in {
  font-weight: 700;
  color: #1a1a1a;
}

.checkout-billing-form {
  border: 10px solid #1a1a1a;
  padding: 30px;
}

.checkout-billing-form input.form-control,
.checkout-billing-form textarea.form-control {
  border: 1px solid #ddd;
}

.your-order-list {
  padding: 30px;
  background-color: #f7f7f7;
  margin-left: 30px;
}

.your-order-list ul {
  list-style: none;
  margin-bottom: 30px;
}

.your-order-list ul li {
  padding: 15px 10px;
  clear: both;
  border-bottom: 1px solid #eee;
  color: #1a1a1a;
}

.your-order-list ul li:last-child {
  border-bottom: none;
}

.your-order-list ul li {
  text-align: right;
  display: flex;
  justify-content: space-between;
}

.your-order-list ul li b {
  color: #d72323;
  font-family: 'Rajdhani', sans-serif;
  font-size: 20px;
  text-transform: uppercase;
}

@media only screen and (max-width: 991px) {
  .product-detail-info {
    padding: 0px 0px 30px 0px;
  }
  .your-order-list {
    margin-left: 0px;
  }
}

/* 19.14 widget gallery */

.widget_gallery ul {
  padding-left: 0;
}

.widget_gallery .wt-post-thum {
  margin-right: 2px;
  margin-bottom: 2px;
}

.widget_gallery li a {
  position: relative;
}

.widget_gallery li a:hover {
  cursor: crosshair;
}

.widget_gallery li:hover img {
  margin-bottom: 0;
  opacity: 0.3;
}

.widget_gallery li:hover a:after {
  opacity: 1;
}

.widget_gallery a {
  display: block;
}

@media only screen and (max-width: 991px) {
  .widget_gallery li {
    width: 24.333%;
  }
}

@media only screen and (max-width: 480px) {
  .widget_gallery li {
    width: 32.1%;
  }
}

.widget_newsletter-2 .newsletter-bx {
  background-color: #f6f7f8;
  text-align: center;
}

.widget_newsletter-2 .newsletter-bx .newsletter-icon {
  background-color: #000;
  display: block;
  width: 70px;
  height: 70px;
  margin: 0px auto;
  margin-bottom: 15px;
  border-radius: 50%;
  text-align: center;
}

.widget_newsletter-2 .newsletter-bx .newsletter-icon i {
  font-size: 32px;
  color: #fff;
  line-height: 70px;
}

/* 19.18 widget client slider */

.widget-client {
  background-color: #f6f7f8;
}

/*-------widget service-------*/

.widget_address li {
  margin-bottom: 20px;
  color: #999999;
  position: relative;
  padding-left: 30px;
}

.widget_address li i {
  position: absolute;
  left: 0px;
  top: 5px;
  font-size: 16px;
}

.widget_address_outer .social-icons li {
  padding-right: 10px;
}

/*-------widget newsletter-------*/

.widget_newsletter .newsletter-bx .form-control {
  background-color: #fff;
  border: 0px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
}

/*-------------------------------------
	20. Table 
-------------------------------------*/

/* 20.1 Full-responsive */

.wt-responsive-table td,
.wt-responsive-table th {
  text-align: center;
}
.wish-list-table {
  margin-bottom: 0px;
}
@media only screen and (max-width: 800px) {
  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td,
  #no-more-tables tr {
    display: block;
  }
  #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    display: none;
  }
  #no-more-tables tr {
    border: 1px solid #ccc;
  }
  #no-more-tables td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    white-space: normal;
    text-align: left;
  }
  #no-more-tables td:before {
    position: absolute;
    top: 18px;
    left: 18px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }
  #no-more-tables td:before {
    content: attr(data-title);
  }
}

.cart-total-table table {
  margin-bottom: 0px;
}
/*-------------------------------------
	21. Video 
-------------------------------------*/

.yt-thum-box img {
  margin: -9.5% 0%;
}

/*======================================
12. WP DEFAULT  
======================================*/

.alignnone {
  margin: 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 15px;
}

.alignright {
  float: right;
  margin: 5px 0 25px 25px;
}

.alignleft {
  float: left;
  margin: 5px 25px 25px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 25px 25px;
}

a img.alignnone {
  margin: 5px 0 25px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 25px 25px 0;
}

a img.aligncenter {
  display: block;
  margin: 5px auto 15px;
}

.wp-caption {
  max-width: 100%;
  text-align: center;
}

.wp-caption img[class*='wp-image-'] {
  display: block;
  margin: 0;
}

.wp-caption.alignnone {
  margin: 5px 0 25px 0;
}

.wp-caption.alignleft {
  margin: 5px 25px 25px 0;
}

.wp-caption.alignright {
  margin: 5px 0 25px 25px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  color: #9d9d9d;
  font-size: 13px;
  line-height: 18px;
  margin: 0;
  padding: 10px 0;
  text-align: left;
}

blockquote {
  color: #000;
  clear: both;
  border: none;
  position: relative;
  text-align: left;
}

blockquote .fa-quote-left {
  color: #d72323;
}

blockquote p {
  font-size: 20px;
  line-height: 26px;
  padding-left: 0px;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 700;
  margin-top: 15px;
}

blockquote strong {
  font-size: 18px;
  padding-right: 10px;
}

blockquote span {
  font-size: 14px;
  color: #000;
  line-height: 30px;
  margin-bottom: 0px;
  font-style: normal;

  display: inline-block;
}

@media only screen and (max-width: 540px) {
  blockquote strong {
    font-size: 14px;
    padding-right: 10px;
  }
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
  max-width: 100%;
  height: auto;
}

/*======================================
13. BLOG  
======================================*/

.blog-post {
  position: relative;
  margin-bottom: 30px;
}

.wt-post-title {
  margin-bottom: 15px;
}

.wt-post-title .post-title {
  margin: 0;
}

.wt-post-meta {
  margin-bottom: 20px;
}

.wt-post-meta ul {
  margin: 0px;
  list-style: none;
}

.wt-post-meta li {
  padding: 0;
  display: inline-block;
  font-size: 14px;
}
.wt-post-meta li.post-user span {
  font-weight: 600;
}
.wt-post-meta li.post-comment span {
  font-weight: 600;
}

.wt-post-meta i {
  margin-right: 5px;
}

.wt-post-meta li:after {
  content: '|';
  display: inline-block;
  font-weight: normal;
  margin-left: 5px;
  font-size: 14px;
  margin-right: 5px;
  color: #000;
}

.wt-post-meta li:last-child:after {
  display: none;
}

.wt-post-text p a {
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
}

.wt-post-readmore {
  margin-bottom: 0px;
}

.wt-post-readmore a {
  font-size: 16px;
  position: relative;
}

.wt-post-readmore a:hover {
  color: #000;
}

.blog-post .wt-post-info {
  z-index: 1;
}

.blog-post .wt-post-info .post-author {
  padding-top: 15px;
  font-size: 13px;
  position: relative;
}

.blog-post .wt-post-info .post-author .post-author-pic {
  left: 30px;
  top: 0px;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-right: 20px;
  border: 2px solid #0f1221;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.blog-post:hover .wt-post-info .post-author .post-author-pic {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  border: 4px solid #0f1221;
}

.blog-post-outer-3 .container {
  max-width: 1430px;
}

@media only screen and (max-width: 767px) {
  .blog-post-outer-3.p-b40 {
    padding-bottom: 0px;
  }

  .blog-post-1-outer [class*='col-']:last-child .blog-post {
    margin-bottom: 0px;
  }
}

.blog-post .blog-category {
  position: absolute;
  left: -20px;
  top: 30px;
  z-index: 1;
}

.blog-post .blog-category:after {
  position: absolute;
  content: '';
  right: -8px;
  width: 20px;
  height: 46px;
  border: 2px solid #fff;
  z-index: -1;
  top: -10px;
}

.site-button.site-btn-effect.mobile {
  width: 250px;
}

.blog-post .blog-category span {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  padding: 2px 10px 1px 10px;
  position: relative;
}

.blog-post blockquote {
  clear: both;
  position: relative;
  text-align: left;
  padding: 30px 30px 30px 30px;
  border: 6px solid #d72323;
}

.blog-post blockquote .fa-quote-left {
  font-size: 50px;
  color: #d72323;
  line-height: 50px;
  padding-bottom: 10px;
}

.blog-post blockquote p {
  font-size: 30px;
  line-height: 36px;
  padding-left: 0px;
  font-family: 'Rajdhani', sans-serif;
}

@media only screen and (max-width: 540px) {
  .blog-post blockquote p {
    font-size: 20px;
    line-height: 26px;
  }

  .site-button.site-btn-effect.desktop {
    display: none;
  }
}

.blog-post blockquote strong {
  font-size: 18px;
}

.blog-post blockquote span {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 0px;
  font-style: normal;
  padding-left: 0px;
  display: inline-block;
  font-weight: 700;
}
/*==============================*/

.blog-post-outer .blog-post:last-child {
  margin-bottom: 0px;
}

.block-shadow {
  -webkit-box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
  box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
}

.blog-social-icon li a .social-tooltip {
  padding: 4px 15px;
  position: absolute;
  right: 130%;
  top: 0px;
  background-color: #0f1221;
  color: #fff;
  display: block;
  width: auto;
  text-align: right;
  opacity: 0;

  font-size: 12px;
  border-radius: 3px;
}

.blog-social-icon li a:hover .social-tooltip {
  opacity: 1;
}

.social-tooltip:after,
.social-tooltip:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
}

.social-tooltip:after {
  border-left-color: #0f1221;
  border-width: 6px;
  margin-top: -6px;
}

.social-tooltip:before {
  border-left-color: #0f1221;
  border-width: 6px;
  margin-top: -6px;
}

/*-------------------------------------
	10. side bar
-------------------------------------*/
.side-bar .widget {
  margin-bottom: 20px;
  background-color: #f8f8f8;
}

.widget .widget-post img {
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .side-bar .widget:last-child {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .side-bar {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 420px) {
  .side-bar {
    margin-bottom: 0px;
    padding: 0px;
    box-shadow: none !important;
  }
}
/*-------------------------------------
	12. comment list
-------------------------------------*/

.comments-area {
  padding: 0;
}

.comments-area .comments-title {
  font-size: 24px;
}

ol.comment-list {
  list-style: none;
}

ol.comment-list li.comment {
  position: relative;
  padding: 0;
}

ol.comment-list li.comment .comment-body {
  position: relative;
  padding: 30px 0px 30px 110px;
  position: relative;
  margin-bottom: 30px;
  border-bottom: 1px solid #d9dcde;
}

ol.comment-list li.comment .comment-author {
  display: block;
  margin-bottom: 10px;
}

ol.comment-list li.comment .comment-author .avatar {
  position: absolute;
  top: 30px;
  left: 0px;
  width: 90px;
  height: 90px;
  border: 4px solid transparent;

  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

ol.comment-list li.comment .comment-author .fn {
  display: inline-block;
  color: #000;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
}

ol.comment-list li.comment .comment-author .says {
  display: none;
  color: #999999;
  font-weight: 600;
}

ol.comment-list li.comment .comment-meta {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

ol.comment-list li.comment .comment-meta a {
  color: #d72323;
}

ol.comment-list li.comment .comment-meta:before,
ol.comment-list li.comment .reply a:before {
  font-family: 'FontAwesome';
  font-size: 16px;
  vertical-align: top;
}

ol.comment-list li.comment p {
  margin: 0 0 5px;
  font-weight: 400;
}

ol.comment-list li.comment .reply {
  text-align: left;
}

ol.comment-list li.comment .reply a {
  font-size: 14px;
  position: relative;
}

ol.comment-list li .children {
  list-style: none;
  margin-left: 40px;
}

ol.comment-list li .children li {
  padding: 0;
}

ol.comment-list li.comment .comment-body:hover .comment-author .avatar {
  border: 4px solid #fff;
}

@media only screen and (max-width: 991px) {
  #comment-list {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 767px) {
  #comment-list.p-a30 {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    margin-bottom: 0px;
  }
  .comments-area .padding-30 {
    padding: 15px;
  }
  ol.comment-list li.comment .comment-body {
    margin-bottom: 30px;
    margin-left: 30px;
    padding-left: 60px;
  }
  ol.comment-list li.comment .comment-author .avatar {
    left: -35px;
    height: 70px;
    width: 70px;
  }
  ol.comment-list li .children {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 480px) {
  ol.comment-list li.comment .comment-body {
    margin-left: 10px;
  }
  ol.comment-list li.comment .comment-author .avatar {
    left: -15px;
    top: 12px;
    width: 60px;
    height: 60px;
  }
  ol.comment-list li.comment .comment-body {
    padding-left: 55px;
    padding-top: 10px;
  }
}

@media only screen and (max-width: 380px) {
  ol.comment-list li.comment .comment-body {
    padding: 20px;
    margin-bottom: 20px;
    margin-left: 0px;
  }
  ol.comment-list li.comment .comment-author .avatar {
    position: inherit;
    display: block;
    margin-bottom: 20px;
  }

  .comments-title {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.comment-reply-link {
  position: relative;
  color: #1a1a1a;
  font-weight: 700;
}

.comment-reply-link:before {
  position: absolute;
  content: '\f178';
  font-family: 'FontAwesome';
  left: 50%;
  top: 0px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

.comment-reply-link:hover:before {
  left: 110%;
  opacity: 1;
}

.comment-reply-link:hover {
  color: #000;
}

/*-------------------------------------
	13. comment form
-------------------------------------*/

.comment-respond .comment-reply-title {
  font-size: 24px;
}

.comments-area .comment-form {
  margin: 0 -15px;
}

.comments-area .comment-form .comment-notes {
  display: none;
}

.comments-area .comment-form p {
  width: 50%;
  float: left;
  padding: 0 15px;
  margin-bottom: 30px;
  position: relative;
  font-weight: 600;
}

.comments-area .comment-form p.comment-form-url {
  width: 100%;
  display: block;
  clear: both;
}

.comments-area .comment-form p.form-allowed-tags {
  width: 100%;
}

ol.comment-list li.comment .comment-respond .comment-form p {
  padding: 0 15px !important;
}

.comments-area .comment-form p label {
  display: none;
  line-height: 18px;
  margin-bottom: 10px;
}

.comments-area .comment-form p input[type='text'],
.comments-area .comment-form p textarea {
  width: 100%;
  height: 50px;
  padding: 10px 15px;
  border: none;
  background-color: #f8f8f8;
}

.comments-area .comment-form p.comment-form-comment {
  width: 100%;
  display: block;
  clear: both;
}

.comments-area .comment-form p textarea {
  height: 120px;
  resize: none;
  padding: 15px;
}

.comments-area .comment-form p.form-submit {
  clear: both;
  float: none;
  width: 100%;
  margin: 0;
}

.comments-area .comment-form p input[type='submit'] {
  background-color: #77c04b;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-width: 0;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  padding: 10px 20px;
  text-transform: uppercase;
}

.comments-area .comment-form p input[type='submit']:hover,
.comments-area .comment-form p input[type='submit']:focus,
.comments-area .comment-form p input[type='submit']:active {
  background-color: #6ab33e;
  border-color: #6ab33e;
  color: #fff;
}

.comment-respond .form-submit .site-button {
  position: relative;
}

.comment-form .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #777;
  font-size: 14px;
}

.comment-form .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #777;
  font-size: 14px;
}

.comment-form .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #777;
  font-size: 14px;
}

.comment-form .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #777;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .comments-area .comment-form p {
    width: 100%;
    float: none;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1280px) {
  .wt-post-info-inner {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .wt-post-info-inner {
    height: auto;
  }
}

@media only screen and (max-width: 540px) {
  .comment-respond .comment-reply-title {
    font-size: 20px;
  }

  .comments-area .comments-title {
    font-size: 20px;
  }
}

/*-------------------------------------
	16. if no image with blog post Latest-blog-3
-------------------------------------*/
.date-style-2 {
  border: 8px solid #d6d6d6;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.date-style-2 .wt-post-info {
  position: relative;
  padding: 50px 30px;
}

.date-style-2 .wt-post-meta ul li {
  font-family: 'Rajdhani', sans-serif;
  margin-right: 30px;
}

.date-style-2 .wt-post-meta ul li:last-child {
  margin-right: 0px;
}

.date-style-2 .wt-post-meta ul li.post-category {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  margin-right: 0px;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);

  transform-origin: left 0px;

  -webkit-transform-origin: left 0px;
  -moz-transform-origin: left 0px;
  -o-transform-origin: left 0px;
  -ms-transform-origin: left 0px;

  border-right: 20px solid #fff;
  border-bottom: 20px solid #fff;
  background-color: #fff;
}

.date-style-2 .wt-post-meta ul li.post-category span {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-color: #d72323;
  font-size: 24px;
  display: block;
  padding: 2px 15px;
  letter-spacing: 2px;
}

.date-style-2 .wt-post-meta ul li.post-date,
.date-style-2 .wt-post-meta ul li.post-comment {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #d72323;
  position: relative;
}

.date-style-2 .wt-post-readmore {
  padding-top: 5px;
}
.date-style-2 .wt-post-readmore a {
  color: #d72323;
}

.date-style-2 .wt-post-meta {
  margin-bottom: 10px;
}

.date-style-2 .wt-post-meta ul li:after {
  content: '';
  position: absolute;
  width: 16px;
  height: 2px;
  background-color: #1a1a1a;
  top: 15px;
  right: -30px;
}
.date-style-2 .wt-post-meta ul li.post-category:after {
  display: none;
}

.date-style-2:hover {
  border: 8px solid #d72323;
}

/*-------------------------------------
	16. Blog-style-1
-------------------------------------*/
.blog-style-1 {
  position: relative;
  margin-bottom: 0px;
}
.blog-style-1 .wt-post-info {
  position: relative;
  padding: 30px 0px 0px 0px;
}

.blog-style-1 .wt-post-meta ul li {
  font-family: 'Rajdhani', sans-serif;
  margin-right: 20px;
}

.blog-style-1 .wt-post-meta ul li:last-child {
  margin-right: 0px;
}

.blog-style-1 .wt-post-meta ul li.post-date,
.blog-style-1 .wt-post-meta ul li.post-category,
.blog-style-1 .wt-post-meta ul li.post-comment {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #d72323;
  position: relative;
}

.blog-style-1 .wt-post-readmore {
  padding-top: 25px;
}
.blog-style-1 .wt-post-readmore a {
  color: #d72323;
}

.blog-style-1 .wt-post-meta {
  margin-bottom: 10px;
}

.blog-style-1 .wt-post-meta ul li:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 1px;
  background-color: #1a1a1a;
  top: 12px;
  right: -22px;
}

/*blog list style*/
.blog-list-style .wt-post-info {
  margin-bottom: 30px;
}
.blog-list-style.blog-style-1 .wt-post-readmore {
  padding-top: 0px;
}

/*-------------------------------------
	16. Blog-style-3
-------------------------------------*/
.blog-style-3 {
  position: relative;
  border: 1px solid #ddd;
  background-color: #fff;
}
.blog-style-3 .wt-post-info {
  position: relative;
}

.blog-style-3 .wt-post-meta ul li {
  font-family: 'Rajdhani', sans-serif;
  margin-right: 30px;
}

.blog-style-3 .wt-post-meta ul li:last-child {
  margin-right: 0px;
}

.blog-style-3 .wt-post-meta ul li.post-category {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  margin-right: 0px;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);

  transform-origin: left 0px;

  -webkit-transform-origin: left 0px;
  -moz-transform-origin: left 0px;
  -o-transform-origin: left 0px;
  -ms-transform-origin: left 0px;

  border-right: 20px solid #fff;
  border-bottom: 20px solid #fff;
  background-color: #fff;
}

.blog-style-3 .wt-post-meta ul li.post-category span {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-color: #d72323;
  font-size: 24px;
  display: block;
  padding: 2px 15px;
  letter-spacing: 2px;
}

.blog-style-3 .wt-post-meta ul li.post-date,
.blog-style-3 .wt-post-meta ul li.post-comment {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #d72323;
  position: relative;
}

.blog-style-3 .wt-post-readmore {
  padding-top: 5px;
}
.blog-style-3 .wt-post-readmore a {
  color: #d72323;
}

.blog-style-3 .wt-post-meta {
  margin-bottom: 10px;
}

.blog-style-3 .wt-post-meta ul li:after {
  content: '';
  position: absolute;
  width: 16px;
  height: 2px;
  background-color: #1a1a1a;
  top: 15px;
  right: -30px;
}
.blog-style-3 .wt-post-meta ul li.post-category:after {
  display: none;
}

.blog-style-3:hover {
  background-color: #d72323;
}

.blog-style-3:hover .post-title {
  color: #fff;
}
.blog-style-3:hover .wt-post-text p {
  color: #fff;
}
.blog-style-3:hover .wt-post-meta ul li.post-date,
.blog-style-3:hover .wt-post-meta ul li.post-comment {
  color: #fff;
}
.blog-style-3:hover .site-button-link {
  color: #fff;
}
.blog-style-3:hover .site-button-link::before {
  background-color: #fff;
}

.wt-post-title .post-title a:hover {
  color: #d72323;
}

/*---- Blog large----*/
.blog-style-large {
  position: relative;
}
.blog-style-large .wt-post-media {
  position: relative;
  height: 515px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.blog-style-large .wt-post-info {
  position: absolute;
  bottom: 30px;
  right: 30px;
  left: 30px;
}

.blog-style-large .wt-post-title {
  margin-bottom: 0px;
}
.blog-style-large .wt-post-meta {
  margin-bottom: 10px;
}
.blog-style-large .wt-post-meta ul li.post-date,
.blog-style-large .wt-post-meta ul li.post-comment {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #d72323;
  position: relative;
  font-family: 'Rajdhani', sans-serif;
}

.big-text {
  color: #d72323 !important;
}

@media only screen and (max-width: 991px) {
  .blog-style-large {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .blog-block-style [class*='col-']:last-child .blog-post {
    margin-bottom: 0px;
  }
}

.recent-posts-entry-date .widget-post:last-child {
  border: none;
}

.recent-posts-entry-date .wt-post-date {
  background-color: #0e0e0e;
  width: 50px;
  height: 60px;
  float: left;
}

.recent-posts-entry-date .wt-post-date strong {
  display: block;
  font-size: 24px;
}

.recent-posts-entry-date .wt-post-date span {
  display: block;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
}

.recent-posts-entry-date .wt-post-info {
  margin-left: 70px;
}

.recent-posts-entry-date .wt-post-info .wt-post-meta ul li {
  font-weight: normal;
  font-size: 12px;
}

.recent-posts-entry-date .wt-post-info .post-title {
  font-weight: normal;
  margin-top: 0px;
  font-size: 14px;
}

/*======================================
14. HEADER (top part of page)
======================================*/

.site-header {
  position: relative;
  z-index: 999;
}

.site-header ul,
.site-header ol {
  margin-bottom: 0;
}

/*-------------------------------------
	1. header full screen
-------------------------------------*/

.nav-wide .container {
  width: 100%;
}

.nav-wide .container {
  padding: 0px 50px;
}

.nav-full-banner .container {
  max-width: 1440px;
  width: 100%;
  padding: 0px !important;
}

@media only screen and (max-width: 991px) {
  .nav-wide .container {
    padding: 0px 70px;
  }
  .nav-full-banner .container {
    padding: 0px 15px !important;
  }
}

@media only screen and (max-width: 768px) {
  .nav-wide .container {
    padding: 0px 15px;
  }
}

/*-------------------------------------
	2. without top bar
-------------------------------------*/

.extra-nav {
  display: table;
  float: right;
  height: 70px;
  position: relative;
  z-index: 13;
}

.extra-nav .extra-cell {
  display: table-cell;
  padding-left: 25px;
  vertical-align: middle;
}
.extra-nav .extra-cell:first-child {
  padding-left: 0px;
}

@media only screen and (max-width: 540px;) {
  .extra-nav .extra-cell {
    padding-left: 10px;
  }
}
@media only screen and (max-width: 480px) {
  .extra-nav.header-2-nav {
    padding-left: 0px;
  }
  .extra-nav.header-2-nav::after {
    display: none;
  }
  .extra-nav.header-2-nav .extra-cell {
    padding-left: 0px;
  }
  .extra-nav.header-2-nav .site-menu-share {
    font-size: 0px;
  }
  .extra-nav.header-2-nav .site-search-btn {
    font-size: 0px;
    padding: 0px;
  }
}

/*-------------------------------------
	3. map page header
-------------------------------------*/

#header-part.fix-map-header {
  height: 90px;
}

#header-part.fix-map-header .main-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

@media only screen and (max-width: 480px) {
  #header-part.fix-map-header {
    height: 160px;
  }
}

/*======================================
16. LOGO ( company identify  symbol )
======================================*/

/*-------------------------------------
	1. logo for header 
-------------------------------------*/

.logo-header {
  display: table;
  float: left;
  width: 130px;
  height: 70px;
  vertical-align: middle;
  padding: 0;
  font-size: 36px;
  color: #1bbce8;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  z-index: 11;
}

.services-list-horizontal {
  display: flex;
  justify-content: space-between;
  padding: 0;
  list-style: none;
  flex-wrap: wrap;
}

.services-list-horizontal li {
  background-color: #d72323;
  color: white;
  padding: 15px;
  margin: 10px;
  flex: 1;
  text-align: center;
}

.top-five {
  flex-wrap: nowrap; /* Ensure the top 5 items stay in a single row */
}

.remaining-items {
  display: flex;
  flex-wrap: wrap; /* Allow the remaining items to wrap if needed */
  justify-content: space-between; /* Optional for spacing */
}

.remaining-items li {
  width: calc(
    33.33% - 20px
  ); /* Make the remaining items take 3 columns in one row */
  margin-top: 15px;
}
.first-item {
  margin-left: 0px !important;
}

.logo-header-inner {
  vertical-align: middle;
  height: 40px !important;
  padding-left: 10px;
  margin-top: -10px !important;
}

.logo-header-inner img {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 640px) {
  .header-style-2 .logo-header {
    width: 120px;
  }
}

@media only screen and (max-width: 350px) {
  .logo-header {
    width: 120px;
  }
}

.logo-footer {
  margin-left: 0;
  margin-right: 0;
  position: relative;
  z-index: 9;
  width: 90px !important;
}

.logo-footer-inner {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.logo-footer-inner img {
  max-width: 100%;
  max-height: 100%;
}

.nav-wide .container {
  padding: 0px 15px;
  max-width: 100%;
}

.site-menu-share {
  background: none;
  border: none;
  outline: none;
  color: #000;
  font-size: 16px;
  display: inline-block;
  font-weight: 600;
  text-align: center;
}

/*-------------------------------------
	2. when header is sticky 
-------------------------------------*/

/*.is-fixed .logo-header {	height:70px;	}*/

/*======================================
17. MAIN BAR ( place logo & main-navigation )
======================================*/

.top-bar {
  padding: 10px 0px;
  font-size: 14px;
}

.social-bx li a {
  color: #ffffff;
  font-size: 12px;
}

.wt-topbar-left {
  float: left;
}

.wt-topbar-right {
  float: right;
}

.wt-topbar-center {
  text-align: center;
}

.wt-topbar-info li {
  display: inline-block;
  margin-right: 10px;
  padding: 12px 0px;
}
.wt-topbar-info li span {
  display: inline-block;
  padding-right: 10px;
}
.wt-topbar-info li i {
  margin-right: 10px;
}

.login-controls li {
  display: inline-block;
  margin-right: 10px;
}
.login-controls li:last-child {
  margin-right: 0px;
}
.login-controls li a {
  color: #1a1a1a;
}

.social-bx li {
  margin-right: 15px;
}
.social-bx li:last-child {
  margin-right: 0px;
}

.login-controls {
  margin-left: 30px;
}

@media only screen and (max-width: 991px) {
  .top-bar .d-flex.justify-content-between {
    display: block !important;
  }

  .login-controls {
    margin-left: 10px;
    text-align: left;
    display: inline-block;
  }
  .login-controls li:last-child {
    display: inline-block;
    margin-right: 0px;
  }
  .social-bx {
    text-align: left;
    display: inline-block !important;
  }
  .social-bx li {
    margin-right: 15px;
    display: inline-block;
  }
}
@media only screen and (max-width: 480px) {
  .header-style-2 .wt-topbar-left {
    display: none !important;
  }
}

/*-------------------------------------
	1. eamil-phone (left) 
-------------------------------------*/

/*------------- top share icon block ----------------------*/

@media only screen and (max-width: 991px) {
  .header-top-info .wt-topbar-right {
    padding-left: 0px;
    padding-right: 0px;
  }
  .header-top-info .social-bx {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 480px) {
  .header-top-info {
    display: none;
  }
}

/*-----------------------------------*/

.main-bar {
  position: relative;
  width: 100%;
  padding: 10px 0px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.main-bar::after,
.main-bar::after {
  clear: both;
  content: '';
  display: table;
}

/*main bar with box view*/

@media only screen and (max-width: 480px) {
  .main-bar {
    padding-left: 0px;
    padding-right: 0px;
  }
}

/*header info css*/

.header-info {
  float: right;
}

.header-info > ul {
  list-style: none;
  display: table;
}

.header-info > ul li {
  display: table-cell;
  padding: 0px 10px;
  vertical-align: middle;
}

.header-info > ul li .icon-md {
  float: left;
  margin-right: 15px;
}

.header-info > ul li strong {
  display: block;
  font-size: 32px;
  line-height: 32px;
  color: #111;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 300;
}
.header-info .icon-content {
  text-align: right;
  padding-top: 10px;
}

.header-botton {
  background-color: #1a1a1a;
}

.header-botton .header-nav .nav {
  float: none;
}

.header-botton .header-nav .nav > li > a {
  padding: 20px 12px;
  color: #fff;
}

.header-botton .extra-nav {
  padding: 13px 0;
}

.header-botton .extra-nav .site-search-btn {
  color: #fff;
  padding: 7px 15px;
}

.header-botton .extra-nav .wt-cart .woo-cart-count {
  border: 2px solid #fff;
  color: #fff;
  min-width: 36px;
  padding: 5px 5px 5px;
}

.header-botton .extra-nav .wt-cart .woo-cart-count::before {
  border-color: #fff;
}

@media only screen and (max-width: 991px) {
  .header-botton .navbar-toggler {
    float: left;
    margin: 9px 0;
  }
  .header-botton .navbar-toggler span {
    background-color: #fff;
  }
  .header-botton .header-nav .nav > li > a {
    padding: 12px 12px;
    color: #777;
  }
}

@media only screen and (max-width: 640px) {
  .header-middle .logo-header {
    float: none;
    margin: 9px auto;
  }
}

/*======================================
18. NAVIGATIONS 
======================================*/

/*-------------------------------------
	1. mega-menu
-------------------------------------*/

.navbar-toggler {
  border: none;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 15px;
  margin-right: -10px;
  padding: 12px 10px;
}
.navbar-toggler .icon-bar {
  background: #111111;
  height: 3px;
  border-radius: 0;
  -webkit-border-radius: 0;
  display: block;
  width: 22px;
}
.navbar-toggler .icon-bar + .icon-bar {
  margin-top: 4px;
}

.header-style-2 .navbar-toggler .icon-bar {
  background: #000;
  height: 3px;
  border-radius: 0;
  -webkit-border-radius: 0;
  display: block;
  width: 22px;
}
.header-style-2 .navbar-toggler .icon-bar + .icon-bar {
  margin-top: 4px;
}

@media only screen and (max-width: 480px) {
  .navbar-toggler {
    margin-left: 10px;
  }
}

.is-fixed .navbar-toggler {
  margin-top: 14px;
  margin-bottom: 14px;
}

.header-nav {
  position: relative;
  padding: 0px;
  z-index: 10;
}

.header-nav .nav {
  display: flex;
  justify-content: flex-end;
  flex-direction: inherit;
}

.header-nav .nav i {
  font-size: 9px;
  margin-left: 3px;
  margin-top: -3px;
  vertical-align: middle;
  opacity: 0.7;
}

.header-nav .nav > li {
  position: relative;
  margin: -10px 0px;
  padding: 10px 0px;
  font-family: 'Rajdhani', sans-serif;
  text-transform: uppercase;
}

.header-nav .nav > li > a {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  color: #fff;
  font-size: 18px;
  padding: 35px 14px;
  cursor: pointer;
  margin: -10px 0px;
  font-weight: 600;
}

.send-us {
  width: 100% !important;
}

.header-nav .nav > li > a:hover {
  background-color: transparent;
  color: #212427;
}

.header-nav .nav > li > a:active,
.header-nav .nav > li > a:focus {
  background-color: transparent;
}

.header-nav .nav > li.active > a,
.header-nav .nav > li.current-menu-item > a {
  background-color: transparent;
  color: #0f1221;
}

/* =====hover continue show effect Start=====*/

.header-nav .nav > li:hover > a {
  color: #0f1221;
}

/* =====hover continue show effect End=====*/

.header-nav .nav > li:hover > .sub-menu,
.header-nav .nav > li:hover > .mega-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.header-nav .nav > li .sub-menu {
  background-color: #ffffff;
  display: block;
  left: 0;
  top: 100%;
  list-style: none;
  margin: 20px 0 0;
  opacity: 0;
  padding: 0px;
  position: absolute;
  visibility: hidden;
  width: 220px;
  z-index: 10;
}

.header-nav .nav > li .sub-menu li {
  border-bottom: 1px solid #f4f4f4;
  position: relative;
  text-transform: none;
}

.header-nav .nav > li .sub-menu li a {
  color: #1d1d1d;
  display: block;
  font-size: 15px;
  padding: 10px 20px;
  font-weight: 600;
}

.header-nav .nav > li .sub-menu li a:hover {
  color: #000;
  text-decoration: none;
}

.header-nav .nav > li .sub-menu li:hover > a {
  color: #d72323;
}

.header-nav .nav > li .sub-menu li:last-child {
  border-bottom: 0px;
}

.header-nav .nav > li .sub-menu li .fa {
  color: #363636;
  display: block;
  float: right;
  position: absolute;
  right: 10px;
  top: 2px;
}

.header-nav .nav > li .sub-menu li > .sub-menu {
  left: 220px;
  margin: 0 0 0 20px;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -ms-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.header-nav .nav > li .sub-menu li:hover > .sub-menu {
  left: 220px;
  margin: 0px;
  opacity: 1;
  top: -1px;
  visibility: visible;
}

.header-nav .nav > li .sub-menu li:hover > .sub-menu:before {
  background-color: transparent;
  bottom: 0px;
  content: '';
  display: block;
  height: 100%;
  left: -6px;
  position: absolute;
  top: 0px;
  width: 6px;
}

.header-nav .nav > li.has-mega-menu {
  position: inherit;
}

.header-nav .nav > li .mega-menu li a > i {
  font-size: 13px;
  margin-right: 5px;
  min-width: 20px;
}

.header-nav .nav > li .mega-menu {
  background-color: #ffffff;
  border: 1px solid #e9e9e9;
  display: table;
  left: 0px;
  list-style: none;
  opacity: 0;
  position: absolute;
  right: 0px;
  visibility: hidden;
  width: 100%;
  margin-top: 20px;
  z-index: 10;
}

.header-nav .nav > li .mega-menu > li {
  display: table-cell;
  padding: 10px 0;
  position: relative;
  vertical-align: top;
  width: 25%;
  text-transform: none;
}

.header-nav .nav > li .mega-menu > li:after {
  content: '';
  background-color: rgba(204, 204, 204, 0.4);
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 1px;
  height: 100%;
}

.header-nav .nav > li .mega-menu > li:last-child:after {
  display: none;
}

.header-nav .nav > li .mega-menu > li > a {
  color: #555555;
  display: block;
  font-size: 14px;
  padding: 0 20px;
}

.header-nav .nav > li .mega-menu > li ul {
  list-style: none;
  margin: 10px 0px 0px 0px;
  padding: 0px;
}

.header-nav .nav > li .mega-menu > li ul a {
  color: #474747;
  display: block;
  font-size: 13px;
  line-height: 34px;
  padding: 2px 20px;
}

.header-nav .nav > li .mega-menu > li ul a:hover {
  color: #1bbce8;
  background-color: #f2f2f2;
}

.header-nav .nav > li.menu-item-has-children:before {
  content: '\f078';
  display: block;
  font-family: 'FontAwesome';
  right: 4px;
  position: absolute;
  top: 50%;
  color: #999;
  margin-top: -8px;
  font-size: 8px;
}

/*-- Submenu direction---*/

.header-nav .nav > li.submenu-direction .sub-menu {
  left: auto;
  right: 0;
}

.header-nav .nav > li.submenu-direction .sub-menu li > .sub-menu {
  left: auto;
  right: 220px;
  margin: 0 20px 0 0;
}

.header-nav .nav > li.submenu-direction .sub-menu li:hover > .sub-menu {
  left: auto;
  right: 220px;
  margin: 0 0 0 0;
}

.has-child .submenu-toogle {
  position: absolute;
  right: 4px;
  top: 4px;
  color: #000;
  background-color: transparent;
  z-index: 900;
  cursor: pointer;
  padding: 10px;
  font-size: 14px;
  display: none;
}

.header-nav .has-child li .submenu-toogle {
  opacity: 0.9;
}

.header-nav
  .has-child.nav-active
  > a
  + .submenu-toogle.glyphicon-chevron-right:before {
  content: '\e114';
}

.header-nav .has-child.nav-active > a + .submenu-toogle.glyphicon-plus::before {
  content: '\2212';
}

@media only screen and (max-width: 991px) {
  header .logo {
    display: block;
    padding: 4px 0;
  }
  .header-nav .nav i {
    margin-top: 6px;
  }
  .header-nav .nav li.has-child.nav-active > a {
    color: #000;
  }
}

@media only screen and (max-width: 991px) {
  /* responsive strat when media screen [991px] css START*/
  .navbar-header {
    float: none;
  }

  .navbar-toggler {
    display: block;
    margin-top: 14px;
    margin-bottom: 14px;
    float: right;
    outline: none !important;
  }

  /* Responsive strat when medai screen [991px] css END*/
  .header-nav {
    clear: both;
    margin: 0 -15px;
    border-bottom: 1px solid #e9e9e9;
  }
  .header-nav .nav {
    float: none;
    margin: 0;
    background: #fff;
    display: block;
    width: 100%;
    position: absolute;
    top: 0px;
  }

  .header-nav .nav li {
    float: none;
  }
  .header-nav .nav > li .sub-menu > li,
  .header-nav .nav > li .mega-menu > li {
    float: none;
    display: block;
    width: auto;
  }
  .header-nav .nav li .sub-menu > li a {
    padding-left: 30px;
  }
  .header-nav .nav > li > a {
    padding: 12px 15px;
    border-top: 1px solid #e9e9e9;
    color: #777;
  }
  .header-nav .nav > li.active > a,
  .header-nav .nav > li.current-menu-item > a {
    color: #000;
  }
  .header-nav .nav i {
    float: right;
  }
  .header-nav .nav > li > a:hover,
  .header-nav .nav > li > a:active,
  .header-nav .nav > li > a:focus {
    background-color: #f0f0f0;
    text-decoration: none;
  }
  .header-nav .nav > li .mega-menu > li:after {
    display: none;
  }
  .header-nav .nav > li ul,
  .header-nav .nav > li .sub-menu,
  .header-nav .nav > li .mega-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: #f9f9f9;
  }
  .header-nav .nav > li ul.mega-menu ul {
    display: block;
  }
  .header-nav .nav > li .mega-menu > li {
    padding: 0px;
  }
  .header-nav .nav > li ul.mega-menu li:hover ul {
    display: block;
  }
  .header-nav .nav li.has-child {
    position: relative;
  }
  .header-nav .nav li .sub-menu,
  .header-nav .nav li .mega-menu {
    opacity: 1;
    margin: 0 !important;
  }
  .has-child .submenu-toogle {
    display: block;
  }

  .header-nav
    .has-child.nav-active
    > a
    + .submenu-toogle.fa.fa-angle-right::before {
    content: '\f107';
  }

  .mobile-full-width-menu .header-nav .nav {
    position: inherit;
  }
}

.header-nav.nav-animation .nav > li > a {
  position: relative;
}

.header-nav.nav-animation .nav > li > ul.sub-menu li > a {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  position: relative;
  z-index: 1;
}

.header-nav.nav-animation .nav > li > ul.sub-menu li > a:before {
  position: absolute;
  content: '\f178';
  font-family: 'FontAwesome';
  font-size: 14px;
  color: #b72323;
  left: 5px;
  opacity: 0;

  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.header-nav.nav-animation .nav > li > ul.sub-menu li > a:hover:before {
  opacity: 1;
  left: 20px;
}
.header-nav.nav-animation .nav > li:hover > ul.sub-menu li:hover > a {
  padding-left: 40px;
}

@media only screen and (max-width: 991px) {
  .is-fixed .header-nav .nav,
  .header-fixed .header-nav .nav {
    overflow: auto;
    max-height: 300px;
  }
  .is-fixed .header-nav .nav > li > a,
  .header-fixed .header-nav .nav > li > a {
    padding: 12px 15px;
  }
  .is-fixed#header-part .navbar-toggler,
  .header-fixed .navbar-toggler {
    margin: 15px 0;
  }

  .header-nav.nav-animation .nav > li > a:before {
    left: 30px;
  }

  .mobile-sider-drawer-menu .is-fixed .header-nav .nav,
  .mobile-sider-drawer-menu .header-fixed .header-nav .nav {
    overflow: auto;
    max-height: 100%;
  }
}

/*Header Style 4*/

.site-header.header-style-4 {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
}
.site-header.header-style-4 .main-bar {
  position: fixed;
}

.header-style-4 .header-nav .nav > li:hover > a:before {
  text-shadow: 0px 0 #1a1a1a, -10px 0 #1a1a1a, 10px 0 #1a1a1a;
}
.header-style-4 .top-bar {
  padding: 0px 0px;
  font-size: 14px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 0;
}

.header-style-4 .top-bar .wt-topbar-info li {
  display: inline-block;
  margin-right: 0px;
  padding: 12px 15px 12px 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}
.header-style-4 .top-bar .wt-topbar-info li:first-child {
  position: relative;
  z-index: 1;
}

.header-style-4 .top-bar .wt-topbar-info li span {
  display: inline-block;
  padding-right: 10px;
}
.header-style-4 .top-bar .wt-topbar-info li i {
  margin-right: 10px;
  font-size: 20px;
}

.header-style-4 .top-bar .wt-topbar-right .wt-topbar-info-2 li {
  display: inline-block;
  margin-right: 0px;
  padding: 10px 15px 10px 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}
.header-style-4 .top-bar .wt-topbar-right .wt-topbar-info-2 li span {
  display: inline-block;
  padding-right: 10px;
}
.header-style-4 .top-bar .wt-topbar-right .wt-topbar-info-2 li i {
  margin-right: 10px;
  font-size: 20px;
}

.header-style-4 .social-icons {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}
.header-style-4 .social-icons li {
  padding-left: 10px;
}
.header-style-4 .social-icons li a {
  color: #fff;
  font-size: 12px;
}

.header-style-4 .header-search {
  display: block;
  position: relative;
  width: 48px;
  text-align: center;
}
.header-style-4 .header-search-icon {
  display: block;
  color: #fff;
  line-height: 40px;
}

.header-style-4 .header-search-icon.close {
  position: absolute;
  top: 0px;
  right: 0px;
  opacity: 1 !important;
}
.header-style-4 .header-search-icon.close i {
  display: none;
}

.header-style-4 .header-search-icon.close:after,
.header-style-4 .header-search-icon.close:before {
  content: '';
  position: absolute;
  width: 3px;
  height: 18px;
  background-color: #fff;
  right: 22px;
  top: -8px;
}

.header-style-4 .header-search-icon.close:after {
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Safari */
  transform: rotate(45deg);
}
.header-style-4 .header-search-icon.close:before {
  -ms-transform: rotate(-45deg); /* IE 9 */
  -webkit-transform: rotate(-45deg); /* Safari */
  transform: rotate(-45deg);
}
.header-style-4 .header-search-icon.close:hover:after,
#search .close:hover:before {
  cursor: pointer;
}

.header-style-4 .header-search-icon i {
  margin: 0px auto;
}

.header-style-4 .main-bar {
  position: relative;
  width: 100%;
  padding: 0px 0px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.header-style-4 .main-bar .container {
  position: relative;
  max-width: 100%;
}

.header-style-4 .header-nav .nav > li > a {
  color: #fff;
  display: block;
}

.header-style-4 .header-nav .nav > li > a {
  color: #fff;
  display: block;
  position: relative;
}

/*nav hover brackets*/
.header-style-4 .header-nav .nav > li > a:after,
.header-style-4 .header-nav .nav > li > a:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 30px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  opacity: 0;
}

.header-style-4 .header-nav .nav > li > a:before {
  left: 5%;
  border: 1px solid #fff;
  border-right: 0px;
}
.header-style-4 .header-nav .nav > li > a:after {
  right: 5%;
  border: 1px solid #fff;
  border-left: 0px;
}

.header-style-4 .header-nav .nav > li > a:hover:after,
.header-style-4 .header-nav .nav > li > a:hover:before {
  opacity: 1;
}

.header-style-4 .header-nav .nav > li > a:hover:before {
  left: 0%;
}
.header-style-4 .header-nav .nav > li > a:hover:after {
  right: 0%;
}

@media only screen and (max-width: 991px) {
  .header-style-4 .header-nav .nav > li > a:after,
  .header-style-4 .header-nav .nav > li > a:before {
    display: none;
  }
}

/*nav active brackets*/
.header-style-4 .header-nav .nav > li.active > a:after,
.header-style-4 .header-nav .nav > li.active > a:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 30px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  opacity: 1;
}

.header-style-4 .header-nav .nav > li.active > a:before {
  left: 0%;
  border: 1px solid #fff;
  border-right: 0px;
}
.header-style-4 .header-nav .nav > li.active > a:after {
  right: 0%;
  border: 1px solid #fff;
  border-left: 0px;
}

@media only screen and (max-width: 991px) {
  .header-style-4 .header-nav .nav > li > a:after,
  .header-style-4 .header-nav .nav > li > a:before {
    display: none;
  }
}

.header-style-4 .header-nav-request {
  float: right;
  color: #fff;
  font-family: 'Rajdhani', sans-serif;
  font-size: 22px;
  font-weight: 700;
}
.header-style-4 .header-nav-request a {
  background-color: #d72323;
  display: block;
  color: #fff;
  text-transform: uppercase;
  padding: 8px 10px;
}

@media only screen and (max-width: 540px) {
  .header-style-4 .header-nav-request {
    font-size: 16px;
    font-weight: 600;
  }
}
@media only screen and (max-width: 400px) {
  .header-style-4 .header-nav-request {
    display: none;
  }
}

.header-style-4 .header-nav-request a i {
  margin-left: 10px;
}
.header-style-4 .is-fixed.color-fill .main-bar {
  background: rgba(0, 0, 0, 0.8);
}

.header-style-4 .header-nav .nav > li {
  font-weight: 500;
  position: relative;
  margin: 0px 0px;
  padding: 10px 0px;
}
.header-style-4 .logo-header {
  padding-top: 20px;
}
.header-style-4 .header-2-nav {
  padding-top: 20px;
}

@media only screen and (max-width: 991px) {
  .header-style-4 .header-2-nav {
    padding-top: 0px;
  }
  .header-style-4 .logo-header {
    padding-top: 0px;
  }

  .header-style-4 .social-icons {
    display: none;
  }
  .header-style-4 .wt-topbar-right {
    float: right;
  }

  .header-style-4 .top-bar .wt-topbar-right .wt-topbar-info-2 li {
    font-size: 12px;
    padding: 9px 10px 9px 15px;
  }
  .header-style-4 .top-bar .wt-topbar-right .wt-topbar-info-2 li:last-child {
    border-right: 0px;
    padding-right: 0px;
  }

  .header-style-4 .top-bar .wt-topbar-info li:first-child {
    font-size: 12px;
  }
  .header-style-4 .header-nav .nav > li {
    padding: 0px;
  }

  .header-style-4 .header-nav .nav > li > a {
    color: #000;
  }

  .header-style-4 .navbar-toggler .icon-bar {
    background: #fff;
  }
  .header-style-4.dark-menu .navbar-toggler .icon-bar {
    background: #000;
  }
}

@media only screen and (max-width: 767px) {
  .header-style-4 .wt-topbar-left {
    display: none !important;
  }
  .header-style-4 .wt-topbar-right {
    float: left;
  }
}

@media only screen and (max-width: 640px) {
  .header-style-4 .header-nav-request a {
    font-size: 0px;
  }
  .header-style-4 .header-nav-request a i {
    font-size: 24px;
    margin: 0px;
    width: 20px;
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
  .header-style-4 .top-bar .wt-topbar-right .wt-topbar-info-2 li {
    display: block;
    padding-right: 0px;
    padding-bottom: 0px;
    border: none;
    padding-left: 0px;
  }
  .header-style-4 .top-bar .wt-topbar-right .wt-topbar-info-2 li:last-child {
    padding-bottom: 10px;
  }
}

.header-style-4 #search-toggle-block {
  display: none;
  position: absolute;
  right: 15px;
  top: 65px;
  border: 3px solid #d72323;
}
.header-style-4 #search {
  position: relative;
  background-color: rgba(255, 255, 255);
}
.header-style-4 #search form {
  width: 280px;
  color: #262423;
  text-align: center;
  outline: none;
  padding: 12px;
  margin: 0px auto;
}
.header-style-4 #search input[type='search'] {
  background: none;
  border: none;
  padding: 0px;
  font-size: 14px;
  height: auto;
}
.header-style-4 #search .search-btn {
  border: none;
  background: none;
  padding: 0px 0px 0px 15px;
}
.header-style-4 #search .search-btn i {
  font-size: 18px;
}

/*Header Style 4 dark menu*/
.header-style-4.dark-menu .header-nav .nav > li > a,
.header-style-4.dark-menu .header-nav-request,
.header-style-4.dark-menu .header-search-icon {
  color: #1a1a1a;
}

.header-style-4.dark-menu .is-fixed.color-fill .header-nav .nav > li > a,
.header-style-4.dark-menu .is-fixed.color-fill .header-nav-request,
.header-style-4.dark-menu .is-fixed.color-fill .header-search-icon {
  color: #000;
}

.header-style-4.dark-menu .is-fixed.color-fill .main-bar {
  background: rgba(255, 255, 255);
}

.header-style-4.dark-menu .header-nav .nav > li > a:after,
.header-style-4.dark-menu .header-nav .nav > li > a:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 30px;

  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;

  opacity: 0;
}

.header-style-4.dark-menu .header-nav .nav > li > a:before {
  left: 5%;
  border: 1px solid #1a1a1a;
  border-right: 0px;
}
.header-style-4.dark-menu .header-nav .nav > li > a:after {
  right: 5%;
  border: 1px solid #1a1a1a;
  border-left: 0px;
}

.header-style-4.dark-menu .header-nav .nav > li > a:hover:after,
.header-style-4.dark-menu .header-nav .nav > li > a:hover:before {
  opacity: 1;
}

.header-style-4.dark-menu .header-nav .nav > li > a:hover:before {
  left: 0%;
}
.header-style-4.dark-menu .header-nav .nav > li > a:hover:after {
  right: 0%;
}

.header-style-4.dark-menu .header-search-icon.close:after,
.header-style-4.dark-menu .header-search-icon.close:before {
  background-color: #000;
}

/*nav active brackets*/
.header-style-4.dark-menu .header-nav .nav > li.active > a:after,
.header-style-4.dark-menu .header-nav .nav > li.active > a:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 30px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  opacity: 1;
}

.header-style-4.dark-menu .header-nav .nav > li.active > a:before {
  left: 0%;
  border: 1px solid #1a1a1a;
  border-right: 0px;
}
.header-style-4.dark-menu .header-nav .nav > li.active > a:after {
  right: 0%;
  border: 1px solid #1a1a1a;
  border-left: 0px;
}

@media only screen and (max-width: 991px) {
  .header-style-4.dark-menu .header-nav .nav > li > a:after,
  .header-style-4.dark-menu .header-nav .nav > li > a:before {
    display: none;
  }
}

/*-------------------------------------
	2. nav dark version
-------------------------------------*/

.nav-dark.header-nav .nav > li .sub-menu,
.nav-dark.header-nav .nav > li .mega-menu {
  background-color: #000;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.nav-dark.header-nav .nav > li .sub-menu li {
  border-bottom: 1px solid #23282f;
}

.nav-dark.header-nav .nav > li .sub-menu li:last-child {
  border-bottom: 0px;
}

.nav-dark.header-nav .nav > li .sub-menu li a,
.nav-dark.header-nav .nav > li .mega-menu > li ul a,
.nav-dark.header-nav .nav > li .mega-menu > li > a {
  color: #cccccc;
}

.nav-dark.header-nav .nav > li .sub-menu li a:hover {
  background-color: #222;
}

.nav-dark.header-nav .nav > li .mega-menu > li ul a:hover {
  background-color: #23282f;
  color: #0f1221;
}

.nav-dark.header-nav .nav > li .mega-menu > li:after {
  background-color: rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 991px) {
  .nav-dark.header-nav {
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);
  }
  .nav-dark.header-nav .nav {
    background-color: #2d3239;
  }
  .nav-dark.header-nav .nav > li > a {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
  .nav-dark.header-nav .nav > li > a {
    color: #999;
  }
  .nav-dark.header-nav .nav > li > a:hover,
  .nav-dark.header-nav .nav > li > a:active,
  .nav-dark.header-nav .nav > li > a:focus {
    background-color: #23282f;
    color: #0f1221;
  }
  .nav-dark.header-nav .nav > li ul,
  .nav-dark.header-nav .nav > li .sub-menu,
  .nav-dark.header-nav .nav > li .mega-menu {
    background-color: #23282f;
  }
}

/*====Mobile Side =======*/
@media only screen and (max-width: 991px) {
  .mobile-sider-drawer-menu .header-nav {
    position: fixed;
    width: 250px !important;
    z-index: 999;
    height: 100vh !important;
    overflow-y: inherit !important;
    left: -250px;
    top: 0px;
    -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
    background-color: #fff;
  }
  .mobile-sider-drawer-menu.active .header-nav {
    left: 15px;
  }
  .is-fixed.mobile-sider-drawer-menu .header-nav .nav,
  .header-fixed.mobile-sider-drawer-menu .header-nav .nav {
    height: 100vh !important;
    width: 100%;
  }
  .is-fixed.mobile-sider-drawer-menu .header-nav .nav > li > a,
  .header-fixed.mobile-sider-drawer-menu .header-nav .nav > li > a {
    padding: 12px 15px !important;
  }

  .header-nav .nav > li:hover > .sub-menu,
  .header-nav .nav > li .sub-menu li:hover > .sub-menu {
    animation: none !important;
  }

  .mobile-sider-drawer-menu .icon-bar {
    -moz-transition: all 0.5s linear;
    -ms-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
  }

  #mobile-side-drawer {
    min-height: 41px;
  }

  .mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
    -moz-transform: rotate(45deg) translateX(3px) translateY(2px);
    -webkit-transform: rotate(45deg) translateX(3px) translateY(2px);
    -o-transform: rotate(45deg) translateX(3px) translateY(2px);
    -ms-transform: rotate(45deg) translateX(3px) translateY(2px);
    transform: rotate(45deg) translateX(3px) translateY(2px);
  }

  .mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
    -moz-transform: rotate(-45deg) translateX(3px) translateY(-2px);
    -webkit-transform: rotate(-45deg) translateX(3px) translateY(-2px);
    -o-transform: rotate(-45deg) translateX(3px) translateY(-2px);
    -ms-transform: rotate(-45deg) translateX(3px) translateY(-2px);
    transform: rotate(-45deg) translateX(3px) translateY(-2px);
  }

  .mobile-sider-drawer-menu .icon-bar.icon-bar-two {
    position: relative;
    right: 0%;
  }
  .mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
    position: absolute;
    right: 100px;
    opacity: 0;
  }
}
/*-------------------------------------
	3. when header is sticky
-------------------------------------*/

.sticky-no .main-bar {
  position: static !important;
}

/*-------------------------------------
	4. when header is sticky
-------------------------------------*/
.is-fixed .main-bar {
  position: fixed;
  top: 0;
  left: 0;
}
#page-boxed .main-bar {
  margin-left: auto;
  margin-right: auto;
  max-width: 1760px;
  width: 100%;
  left: auto;
  right: auto;
}
#page-boxed .nav-wide .container {
  padding: 0px 0px;
  max-width: 100%;
}

@media only screen and (max-width: 1760px) {
  #page-boxed .main-bar {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/*-------------------------------------
 5. header id Fixed
-------------------------------------*/
/*.is-fixed.color-fill .main-bar {    padding:0px 64px;}*/

.is-fixed.color-fill .header-nav .nav > li {
  margin: 0;
  padding: 0;
}

.is-fixed.color-fill .header-nav .nav > li > a {
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .main-bar,
  .is-fixed .main-bar,
  .is-fixed.color-fill .main-bar {
    padding: 0px;
  }
}

/*-------------------------------------
	6. header with bg primary color 1
-------------------------------------*/

.site-bg-primary .header-nav .nav > li > a {
  color: #fff;
}

.site-bg-primary .header-nav .nav > li:hover > a,
.site-bg-primary .header-nav .nav > li.active > a,
.site-bg-primary .header-nav .nav > li.current-menu-item > a {
  color: #1a1a1a;
}

.site-bg-primary .site-search-btn,
.site-bg-primary .wt-cart .woo-cart-total,
.site-bg-primary .wt-cart .woo-cart-count {
  color: #fff;
}

.site-bg-primary .wt-cart .woo-cart-count,
.site-bg-primary .wt-cart .woo-cart-count::before {
  border-color: #fff;
}

.site-bg-primary .navbar-toggler .icon-bar {
  background-color: #fff;
}

@media only screen and (max-width: 991px) {
  .site-bg-primary .header-nav .nav > li > a {
    color: #777;
  }
  .site-bg-primary .header-nav .nav > li:hover > a,
  .site-bg-primary .header-nav .nav > li.active > a,
  .site-bg-primary .header-nav .nav > li.current-menu-item > a {
    color: #0f1221;
  }
  .header-nav .nav > li {
    margin: 0;
    padding: 0;
  }
  .header-nav .nav > li > a {
    margin: 0;
    padding: 10px 12px;
  }
  .is-fixed.color-fill .header-nav .nav > li {
    margin: 0;
    padding: 0;
  }
  .is-fixed.color-fill .header-nav .nav > li > a {
    margin: 0;
  }
}

/*======================================
19. BANNER
======================================*/

/*-------------------------------------
	1. inner page banner 
-------------------------------------*/
.wt-bnr-inr {
  height: 500px;
  background-size: cover;
  background-position: center center;
  display: table;
  width: 100%;
}

.wt-bnr-inr .container {
  display: table;
  height: 100%;
}

.wt-bnr-inr-entry {
  display: table-cell;
  vertical-align: bottom;
  text-align: center;
  padding-bottom: 60px;
}

.wt-bnr-inr-entry .banner-title-outer {
  position: relative;
}

.wt-bnr-inr-entry .banner-title-outer .banner-title-name {
  background-color: transparent;
  display: inline-block;
  padding: 20px;
  margin-bottom: 15px;
}

.wt-bnr-inr-entry .banner-title-outer h2 {
  font-size: 62px;
  margin-bottom: 0px;
  line-height: 52px;
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
}

.wt-bnr-inr-entry .banner-title-outer h2:before {
  position: absolute;
  content: '';
  left: 0px;
  width: 20px;
  height: 2px;
  background-color: #fff;
  top: 50%;
  margin-top: -2px;
}

.wt-bnr-inr-entry .banner-title-outer h2:after {
  position: absolute;
  content: '';
  right: 0px;
  width: 20px;
  height: 2px;
  background-color: #fff;
  top: 50%;
  margin-top: -2px;
}

@media only screen and (max-width: 991px) {
  .wt-bnr-inr {
    height: 330px;
    padding-top: 120px;
  }

  .wt-bnr-inr-entry {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .wt-bnr-inr-entry .banner-title-name h1,
  .wt-bnr-inr-entry .banner-title-name h2,
  .wt-bnr-inr-entry .banner-title-name h3 {
    font-size: 56px;
    line-height: normal;
  }
}

@media only screen and (max-width: 540px) {
  .wt-bnr-inr-entry .banner-title-name h1,
  .wt-bnr-inr-entry .banner-title-name h2,
  .wt-bnr-inr-entry .banner-title-name h3 {
    font-size: 26px;
  }
}

.banner-inner-row h1,
.banner-inner-row h2,
.banner-inner-row h3,
.banner-inner-row h4,
.banner-inner-row h5,
.banner-inner-row h6 {
  color: #fff;
}

/*======================================
20. SLIDER
======================================*/

/*-------------------------------------
	1.1  next pre btn
-------------------------------------*/

.owl-carousel .owl-nav {
  margin-top: 40px;
  text-align: center;
}

.owl-carousel .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

/*-------------------------------------
	1.3 owl button top to center
-------------------------------------*/

.owl-btn-top-center .owl-nav,
.owl-btn-top-left .owl-nav,
.owl-btn-top-right .owl-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
}

.owl-btn-top-center .owl-stage-outer,
.owl-btn-top-left .owl-stage-outer,
.owl-btn-top-right .owl-stage-outer {
  padding-top: 40px;
}

.owl-btn-top-center .owl-nav {
  text-align: center;
}

/*-------------------------------------
	1.4 owl button top to left
-------------------------------------*/
.owl-btn-top-left .owl-nav {
  text-align: left;
}
/*-------------------------------------
	1.5 owl button top to right
-------------------------------------*/
.owl-btn-top-right .owl-nav {
  text-align: right;
}

/*-------------------------------------
	1.6 owl button bottom to center [it is default position ]
-------------------------------------*/
.owl-btn-bottom-center .owl-nav {
  text-align: center;
}
/*-------------------------------------
	1.7 owl button bottom to left
-------------------------------------*/
.owl-btn-bottom-left .owl-nav {
  text-align: left;
}
/*-------------------------------------
	1.8 owl button bottom to right
-------------------------------------*/
.owl-btn-bottom-right .owl-nav {
  text-align: right;
}
/*-------------------------------------
	1.9 owl button vertical to center
-------------------------------------*/
.owl-btn-vertical-center .owl-nav {
  margin: 0px;
}

.owl-btn-vertical-center .owl-nav .owl-prev,
.owl-btn-vertical-center .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  margin: -15px 0;
}

.owl-btn-vertical-center .owl-nav .owl-prev {
  left: 0;
}
.owl-btn-vertical-center .owl-nav .owl-next {
  right: 0;
}

/*-------------------------------------
	1.10 owl button show on hover
-------------------------------------*/
.owl-btn-hover .owl-nav {
  opacity: 0;
}
.owl-btn-hover:hover .owl-nav {
  opacity: 1;
}
.owl-carousel .owl-item img {
  transform-style: inherit;
}

/*-------------------------------------
    1.11 owl slider button
-------------------------------------*/
.owl-prev,
.owl-next {
  color: #fff;
  padding: 2px 10px;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  background-color: #1a1a1a;
  line-height: 35px;
}

.owl-prev {
  margin-right: 5px;
}
.owl-next {
  margin-left: 5px;
}

.owl-prev:hover,
.owl-prev:active,
.owl-prev:focus,
.owl-next:hover,
.owl-next:active,
.owl-next:focus {
  background-color: #d72323;
  border-color: #d72323;
  color: #ffffff;
  opacity: 0.9;
}

/*dark-next-prev*/
.dark-next-prev.owl-carousel .owl-next,
.dark-next-prev.owl-carousel .owl-prev {
  border: none;
  width: auto;
  height: auto;
}
.dark-next-prev.owl-carousel .owl-nav i {
  color: #fff;
  font-style: normal;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
}

.dark-next-prev.owl-carousel .owl-next,
.dark-next-prev.owl-carousel .owl-prev {
  position: relative;
  z-index: 1;
  background: none;
}
.dark-next-prev.owl-carousel .owl-next:hover,
.dark-next-prev.owl-carousel .owl-prev:hover {
  background: none;
}

.dark-next-prev.owl-carousel .owl-next:hover i,
.dark-next-prev.owl-carousel .owl-prev:hover i {
  color: #1a1a1a;
}

/*light-next-prev*/
.light-next-prev.owl-carousel .owl-next,
.light-next-prev.owl-carousel .owl-prev {
  border: none;
  width: auto;
  height: auto;
}
.light-next-prev.owl-carousel .owl-nav i {
  color: #1d2132;
  font-style: normal;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
}

.light-next-prev.owl-carousel .owl-next,
.light-next-prev.owl-carousel .owl-prev {
  position: relative;
  z-index: 1;
  background: none;
}

.light-next-prev.owl-carousel .owl-next:hover,
.light-next-prev.owl-carousel .owl-prev:hover {
  background: none;
}

.light-next-prev.owl-carousel .owl-next:hover i,
.light-next-prev.owl-carousel .owl-prev:hover i {
  color: #1a1a1a;
}

/*======================================
21. PAGINATION 
======================================*/

/*1. pagination style-1 */
.pagination-bx .pagination,
.cvf-universal-pagination .pagination {
  margin: 0;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: 0;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.pagination > li > a,
.pagination > li > span {
  background-color: #fff;
  border: none;
  color: #000;
  font-weight: 700;
  font-size: 14px;
  margin: 0px 5px;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: #d72323;
  border-color: transparent;
  color: #fff;
  box-shadow: none;
}

.pagination-bx button {
  background-color: transparent;
}

.pagination-bx .active-item {
  border: 2px solid #d72323 !important;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #0f1221;
  border-color: transparent;
}

.pagination > .previous > a,
.pagination > .next > a {
  font-size: 12px;
}

/*======================================
22. FOOTER 
======================================*/

/*-------------------------------------
	1. footer fixed on bottom css
-------------------------------------*/

.footer-fixed .site-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  border: none;
  z-index: 0;
}

.footer-fixed .page-content {
  position: relative;
  z-index: 1;
  background-color: #fff;
}

@media only screen and (max-width: 1200px) {
  .footer-fixed .site-footer {
    position: static;
  }
  .footer-fixed .page-wraper {
    padding-bottom: 0 !important;
  }
}

/*1.2 footer fixed with fullscreen view*/
.header-fixed.site-header {
  position: fixed;
  width: 100%;
  border: none;
  z-index: 990;
}
.header-fixed .page-content {
  position: relative;
  z-index: 1;
  background-color: #fff;
}

/*1.2 footer fixed with boxed view*/

.site-footer p,
.site-footer li {
  line-height: 26px;
}

.site-footer {
  background-position: center;
  background-size: cover;
}

.site-footer p {
  line-height: 26px;
}

.site-footer .widget ul {
  list-style: none;
  margin-top: 0px;
}

.site-footer .widget_address {
  padding-top: 5px;
}

.footer-dark .footer-middle .footer-middle-content {
  background-color: #000;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
}

.footer-dark .footer-bottom .copyrights-text {
  color: #999;
  font-size: 12px;
}

.footer-dark .copyrights-nav {
  list-style: none;
  display: block;
  margin-bottom: 0px;
}

.footer-dark .copyrights-nav li {
  display: inline-block;
  margin-right: 8px;
}

.footer-dark .copyrights-nav li:first-child {
  margin-left: 0;
}

.footer-dark .copyrights-nav li:last-child {
  margin-right: 0;
}

.footer-dark .copyrights-nav li:last-child:after {
  display: none;
}

.footer-dark .copyrights-nav li:after {
  content: '/';
  position: relative;
  right: -8px;
  color: #999;
}

.footer-dark .copyrights-nav li a {
  color: #999;
  font-size: 14px;
  font-weight: 600;
}

.footer-dark .copyrights-nav li a:hover,
.footer-dark .copyrights-nav li a:active,
.footer-dark .copyrights-nav li a:focus,
.footer-dark .copyrights-nav li.active a {
  color: #fff;
}

.footer-dark .widget_services ul li a {
  color: #999;
  font-weight: 500;
}

.footer-bottom {
  padding: 30px 0px;
  color: #787878;
  font-size: 13px;
  position: relative;
  z-index: 1;
}

.footer-bottom .copyrights-text {
  color: #fff;
  font-size: 14px;
}

.footer-light .footer-bottom .copyrights-text {
  color: #111;
}

.footer-bottom .copyrights-nav {
  list-style: none;
  display: block;
}

.footer-bottom .copyrights-nav li {
  display: inline-block;
  margin-right: 8px;
}

.footer-bottom .copyrights-nav li:first-child {
  margin-left: 0;
}

.footer-bottom .copyrights-nav li:last-child {
  margin-right: 0;
}

.footer-bottom .copyrights-nav li:last-child:after {
  display: none;
}

.footer-bottom .copyrights-nav li:after {
  content: '/';
  position: relative;
  right: -8px;
}

.footer-bottom .copyrights-nav li a {
  color: #9d9d9d;
}
.footer-light .footer-bottom .copyrights-nav li a {
  color: #111;
}

.footer-bottom .copyrights-nav li a:hover,
.footer-bottom .copyrights-nav li a:active,
.footer-bottom .copyrights-nav li a:focus,
.footer-bottom .copyrights-nav li.active a {
  color: #0f1221;
}

.footer-light .footer-bottom .copyrights-nav li a:hover,
.footer-light .footer-bottom .copyrights-nav li a:active,
.footer-light .footer-bottom .copyrights-nav li a:focus,
.footer-light .footer-bottom .copyrights-nav li.active a {
  color: #1a1a1a;
}

@media only screen and (max-width: 991px) {
  .site-footer {
    padding: 0px;
  }
  .copyright-block {
    padding-top: 5px;
  }
}

.footer-bottom .container {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .footer-dark .footer-middle .footer-middle-content {
    display: block;
  }
  .logo-footer {
    margin-bottom: 20px;
  }
  .footer-dark .copyrights-nav {
    margin-bottom: 15px;
  }
}
/*-------------------------------------
	2. Footer dark version css
-------------------------------------*/

.footer-dark {
  background-color: #101010;
  overflow: hidden;
}

.footer-dark .footer-top h1,
.footer-dark .footer-top h2,
.footer-dark .footer-top h3,
.footer-dark .footer-top h4,
.footer-dark .footer-top h5,
.footer-dark .footer-top h6,
.footer-dark .footer-top h1 a,
.footer-dark .footer-top h2 a,
.footer-dark .footer-top h3 a,
.footer-dark .footer-top h4 a,
.footer-dark .footer-top h5 a,
.footer-dark .footer-top h6 a {
  color: #fff;
}

.footer-dark .footer-top .widget-title::before {
  border-color: #fff;
}

.footer-dark .footer-top p,
.footer-dark .footer-top strong,
.footer-dark .footer-top b,
.footer-dark .footer-top {
  color: #fff;
}

.footer-dark .site-button {
  background-color: #0f1221;
}
.footer-dark .footer-bottom {
  background-color: #101010;
}
.footer-dark .social-icons li a {
  width: 30px;
  height: 30px;
  line-height: 30px;
  padding: 0px;
  color: #fff;
  font-size: 16px;
  border: 1px solid #484848;
}

.footer-dark .social-icons li a:hover {
  background-color: #fff;
  color: #0f1221;
}
.footer-dark .widget_address li {
  margin-bottom: 24px;
  position: relative;
  padding-left: 30px;
  font-size: 14px;
}
.footer-dark .widget_address li i {
  position: absolute;
  left: 0px;
  top: 5px;
  font-size: 18px;
  color: #d72323;
}

.footer-light .social-icons li a {
  width: 30px;
  height: 30px;
  line-height: 30px;
  padding: 0px;
  color: #0f1221;
  border: 1px solid #0f1221;
}

.footer-light .social-icons li a:hover {
  background-color: #0f1221;
  color: #fff;
}

.footer-dark .footer-top a:active,
.footer-dark .footer-top a:focus,
.footer-dark .footer-top a:hover {
  color: #d72323;
}

.footer-dark .footer-top p a {
  color: #fff;
}

.footer-dark .footer-top .widget_newsletter .form-control {
  background-color: #000;
  color: #fff;
  padding: 10px 15px;
}

/*-------------------------------------
	2. Footer dark version css
-------------------------------------*/
.site-footer {
  overflow: hidden;
}

.footer-dark.site-footer {
  border-top: 0px solid #212121;
  overflow: hidden;
}

.footer-light .footer-top {
  background-color: #0f1221;
}

.footer-light .footer-top h1,
.footer-light .footer-top h2,
.footer-light .footer-top h3,
.footer-light .footer-top h4,
.footer-light .footer-top h5,
.footer-light .footer-top h6,
.footer-light .footer-top h1 a,
.footer-light .footer-top h2 a,
.footer-light .footer-top h3 a,
.footer-light .footer-top h4 a,
.footer-light .footer-top h5 a,
.footer-light .footer-top h6 a {
  color: #000;
}

.footer-light .footer-top .widget-title::before {
  border-color: #000;
}

.footer-light .footer-top p,
.footer-light .footer-top strong,
.footer-light .footer-top b,
.footer-light .footer-top {
  color: #000;
}

.footer-light .footer-top {
  background-color: #fff6ef;
}

.footer-light .footer-bottom {
  background-color: #dfba9f;
}

/*-------widget service-------*/

.footer-light .widget_address li {
  margin-bottom: 12px;
  color: #000;
  position: relative;
  padding-left: 30px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}
.footer-light .widget_address li:last-child {
  border-bottom: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.footer-light .widget_address li i {
  position: absolute;
  left: 0px;
  top: 5px;
  font-size: 18px;
}

.footer-light .widget_address_outer .social-icons li {
  padding-right: 10px;
}

.footer-top {
  padding: 60px 0px 0px 0px;
}

.footer-h-left {
  padding: 50px 40px 40px 0px;
  position: relative;
  z-index: 1;
}

.footer-h-left:before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #111111;
  z-index: -1;
  width: 6000px;
  height: 150%;
}

.footer-h-right {
  padding-top: 80px;
}

.footer-wide .container-fluid {
  width: 100%;
}

@media only screen and (max-width: 1366px) {
  .footer-wide .container-fluid {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .footer-h-left:before {
    display: none;
  }
  .footer-h-left {
    padding: 50px 0px 0px 0px;
  }
  .footer-h-right {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 800px) {
  .footer-wide .container-fluid {
    padding: 0px 15px;
  }
}

.footer-payments li {
  display: inline-block;
  padding-top: 5px;
}

.footer-payments li:last-child a {
  margin-right: 0px;
}

.footer-payments li a {
  font-size: 36px;
  margin-right: 10px;
}

.footer-payments li a:hover {
  color: #1a1a1a;
}

/*-------------------------------------
	5. widget Getintuch
-------------------------------------*/

.footer-bottom ul {
  margin: 0;
}

@media only screen and (max-width: 991px) {
  footer .container {
    width: 100%;
  }
  .col-md-3.col-sm-6.footer-col-4:nth-child(3) {
    clear: both;
  }
  .wt-footer-bot-left,
  .wt-footer-bot-right {
    float: none;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .wt-footer-bot-right .copyrights-nav {
    float: none !important;
  }

  .footer-bottom .d-flex {
    display: block !important;
  }
}

@media only screen and (max-width: 767px) {
  .footer-clear {
    display: block !important;
    clear: both;
  }
  #footer .footer-4-col {
    width: 100%;
  }
  .footer-bottom [class*='clo-'] {
    width: 100%;
  }
}

.widget_address li {
  margin-bottom: 20px;
  position: relative;
  padding-left: 20px;
}

.widget.widget_about p {
  line-height: inherit;
  margin-bottom: 15px;
  font-size: 14px;
}

/*-----Footer style2------*/
.footer-dark.footer-style2 .footer-top {
  background-color: #1a1a1a;
}

.footer-dark.footer-style2 .footer-h-left::before {
  display: none;
}
.footer-dark.footer-style2 .footer-bottom {
  background-color: #001029;
  border-top: 1px solid #001029;
}
.footer-dark.footer-style2 .footer-bottom .copyrights-nav li a {
  color: #fff;
}
.footer-dark.footer-style2 .footer-bottom .copyrights-nav li a:hover {
  color: #d72323;
}
.footer-dark.footer-style2 .footer-bottom .copyrights-nav li::after {
  color: #fff;
}
.footer-dark.footer-style2 .footer-top .widget_newsletter .form-control {
  background-color: #fff;
  border-color: #fff;
}
/*-------------------------------------
	6. scroll top btn css
-------------------------------------*/

button.scroltop {
  height: 40px;
  width: 40px;
  background: #1d1b18;
  border: none;
  position: fixed;
  right: 15px;
  bottom: 15px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: none;
  text-align: center;
  z-index: 999;
  color: #fff;
}

button.scroltop span {
  display: block;
  font-size: 24px;
  line-height: 24px;
}

.string {
  position: relative;
}

#btn-vibrate {
  animation: vibrate 2s linear 0s infinite;
  -moz-animation: vibrate 2s linear 0s infinite;
  /* Firefox */
  -webkit-animation: vibrate 2s linear 0s infinite;
  /* Safari and Chrome */
  -o-animation: vibrate 2s linear 0s infinite;
  /* Opera */
}

@keyframes vibrate {
  0% {
    top: 0px;
  }
  25% {
    top: -3px;
  }
  50% {
    top: 0px;
  }
  75% {
    top: 3px;
  }
  100% {
    top: 0px;
  }
}

/* Firefox */

@-moz-keyframes vibrate {
  0% {
    top: 0px;
  }
  25% {
    top: -3px;
  }
  50% {
    top: 0px;
  }
  75% {
    top: 3px;
  }
  100% {
    top: 0px;
  }
}

/* Safari and Chrome */

@-webkit-keyframes vibrate {
  0% {
    top: 0px;
  }
  25% {
    top: -3px;
  }
  50% {
    top: 0px;
  }
  75% {
    top: 3px;
  }
  100% {
    top: 0px;
  }
}

/* Opera */

@-o-keyframes vibrate {
  0% {
    top: 0px;
  }
  25% {
    top: -3px;
  }
  50% {
    top: 0px;
  }
  75% {
    top: 3px;
  }
  100% {
    top: 0px;
  }
}

@media only screen and (max-width: 480px) {
  button.scroltop {
    font-size: 7px;
    height: 30px;
    width: 30px;
    line-height: 16px;
  }
  button.scroltop span {
    font-size: 10px;
    line-height: 10px;
  }
}

/*======================================
23. PAGE-CONTENT
======================================*/

#bg {
  background-attachment: fixed;
  background-size: cover;
}

.page-wraper {
  background: #fff;
}

#page-boxed {
  margin-left: auto;
  margin-right: auto;
  max-width: 1760px;
  width: 100%;
}

.page-content {
  position: relative;
}

.section-full {
  position: relative;
}

.shop-section .container {
  max-width: 1500px;
}

.currencySymbol {
  justify-content: right;
  align-items: right;
  right: 0;
  margin-left: 0px;
}

.icon-with-tooltip {
  position: relative;
  display: inline-block;
}

.price-slider-container {
  width: 100%;
  padding: 20px;
  text-align: center;
}

#amount {
  margin-bottom: 15px;
}

input[type='range'].slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.15s ease-in-out;
}

input[type='range'].slider:hover {
  opacity: 1;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #d72323;
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #d72323;
  cursor: pointer;
}

.price-inputs {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.price-inputs input[type='number'] {
  width: 45%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.icon-with-tooltip .tooltip-text {
  visibility: hidden;
  width: 100px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  bottom: 125%; /* Show above the icon */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.icon-with-tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/*-------------------------------------
	1. Section Head Part 
-------------------------------------*/

.section-head {
  margin-bottom: 60px;
}

.section-head h1.left,
.section-head h2.left,
.section-head h3.left,
.section-head h4.left,
.section-head h5.left,
.section-head h6.left {
  width: auto;
}

.section-head h1.right,
.section-head h2.right,
.section-head h3.right,
.section-head h4.right,
.section-head h5.right,
.section-head h6.right {
  width: auto;
}

.section-head h1.center,
.section-head h2.center,
.section-head h3.center,
.section-head h4.center,
.section-head h5.center,
.section-head h6.center {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.section-head.no-margin {
  margin-bottom: 0;
}

.section-head h1,
.section-head h2,
.section-head h3 {
  margin-top: 0;
}

.title-small {
  display: block;
  color: #7a959d;
  margin-bottom: 15px;
}

.section-head p {
  padding-top: 20px;
}

.section-head p:last-child {
  margin-bottom: 0;
}

.text-center.section-head p {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.text-white {
  color: #fff;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white .title-small {
  color: #fff;
}

/*-------------------------------------
	2. Section Content Part 
-------------------------------------*/

@media only screen and (max-width: 1024px) {
  .section-full {
    background-attachment: scroll !important;
  }
}

@media only screen and (max-width: 540px) {
  .section-head h3,
  h3 {
    font-size: 20px;
  }

  .section-head h1,
  .section-head h2,
  h1,
  h2 {
    font-size: 26px;
  }
}

/*======================================
24. AFTER-LOGIN-CONTENT
======================================*/

/*-------------------------------------
	2. Contact us page 
-------------------------------------*/

ul.contact-info li {
  padding: 0 0 15px 25px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e1e1e1;
  position: relative;
  line-height: 20px;
}

ul.contact-info li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

ul.contact-info li h4 ul.contact-info li h5,
ul.contact-info li h6 {
  margin: 0 0 10px;
}

ul.contact-info li p {
  line-height: 20px;
  margin: 0;
}

ul.contact-info li .fa {
  position: absolute;
  left: 0;
  top: 2px;
  color: #3396d1;
  font-size: 16px;
}

/*======================================
25. THEMEFOREST REQUIRED CLASSES
======================================*/

.sticky {
  clear: both;
}
.gallery-caption {
  clear: both;
}
.bypostauthor {
  clear: both;
}

/*-------------------------------------
	1. Text meant only for screen readers
-------------------------------------*/

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

/*======================================
27. BOX MAX-WIDTH css (  )
======================================*/

.max-w100 {
  max-width: 100px;
}
.max-w200 {
  max-width: 200px;
}
.max-w300 {
  max-width: 300px;
}
.max-w400 {
  max-width: 400px;
}
.max-w500 {
  max-width: 500px;
}
.max-w600 {
  max-width: 600px;
}
.max-w700 {
  max-width: 700px;
}
.max-w800 {
  max-width: 800px;
}
.max-w900 {
  max-width: 900px;
}
.max-w1000 {
  max-width: 1000px;
}

/*======================================
28. SHOP & PRODUCT DETAIL (Woocommerce)
======================================*/

/*-------------------------------------
	1. Product Box 
-------------------------------------*/

.comment-reply-title {
  margin-top: 0;
}

.comment-form [class*='comment-form'] {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .comment-form [class*='comment-form'] {
    width: 100%;
  }
}

.comment-form [class*='-form-author'],
.comment-form [class*='-form-email'] {
  float: left;
}

.comment-form [class*='-form-comment'] {
  width: 100%;
}

.comment-form label {
  display: block;
}

.star-Rating-input i {
  font-size: 18px;
}

/*.star-Rating-input .fa-star {	color:#0f1221;}
.star-Rating-input i:hover {	color:#0f1221;}*/

.comment-form [class*='comment-form'] input,
.comment-form [class*='comment-form'] textarea {
  border: 1px solid #ccc;
  padding: 8px 10px;
  width: 100%;
}

.comment-form {
  margin: 0px -15px;
}

.comment-form [class*='comment-form'] {
  margin-bottom: 20px;
  padding: 0px 15px;
}
.comment-form .form-submit {
  padding: 0px 15px;
}

.comment-form .form-submit input:active,
.comment-form .form-submit input:focus,
.comment-form .form-submit input:hover {
  background: #037a85;
}

/*======================================
29. content show on image box () 
======================================*/

@media only screen and (max-width: 768px) {
  .wt-media > img,
  .wt-media a > img {
    width: 100%;
  }
}

/*======================================
30. Customize  Grouping css 
======================================*/

/*-------------------------------------
	1.set-radius 
-------------------------------------*/

.btn,
.panel,
.form-control,
.img-thumbnail,
.panel-head ing,
.dropdown-menu,
.panel-group .panel,
.nav-tabs > li > a,
.modal-content,
.navbar-toggler,
.nav-pills > li > a,
.pager li > a,
.pager li > span,
.well,
.alert,
.list-group-item:first-child,
.list-group-item:last-child,
.input-group-addon,
.btn-group-divides
  > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle),
.btn-group-divides > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child),
.wt-booking-form .wt-extra-services li .wt-icon-box-lg,
.wt-booking-form .wt-extra-services li.active .wt-icon-box-lg:after,
.searchform input[type='text'],
.searchform input[type='submit'],
ol.comment-list li.comment .comment-body {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

/*------------------------------------- 
	3. float-clearfix 
-------------------------------------*/

.clearfix::after,
.filter-wrap::after,
.filter-wrap::after,
.wt-icon-box-wraper::after,
ol.commentlist li::after {
  content: '';
  display: table;
  clear: both;
}

/*======================================
32. Inner pages 
======================================*/

/*------------------------------------- 
	4. Product block
-------------------------------------*/
.product-block p {
  margin-bottom: 0px;
}

/*======================================
33. Background Effects
======================================*/

.bg-center {
  background-position: center;
}
.bg-top-left {
  background-position: top left;
}
.bg-top-right {
  background-position: top right;
}
.bg-top-center {
  background-position: top center;
}
.bg-bottom-left {
  background-position: bottom left;
}
.bg-bottom-right {
  background-position: bottom right;
}
.bg-bottom-center {
  background-position: bottom center;
}
.bg-left-center {
  background-position: left;
}
.bg-right-center {
  background-position: left;
}
.bg-auto {
  background-size: auto;
}
.bg-contain {
  background-size: contain;
}
.bg-cover {
  background-size: cover;
}
.bg-full-width {
  background-size: 100% auto;
}
.bg-full-height {
  background-size: auto 100%;
}
.bg-repeat {
  background-repeat: repeat;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.bg-repeat-x {
  background-repeat: repeat-x;
}
.bg-repeat-y {
  background-repeat: repeat-y;
}
.bg-fixed {
  background-attachment: fixed;
}
.bg-scroll {
  background-attachment: scroll;
}

/*======================================
34. Home Page
======================================*/

@media only screen and (max-width: 991px) {
  .v-text {
    position: inherit;
    right: auto;
    bottom: auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@media only screen and (max-width: 991px) {
  .section-full.p-tb90 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .section-full.p-tb80 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

/*===========================
36. Overlay Box 2
===========================*/

.overlay-bx-2 {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #000;
  opacity: 0;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.overlay-bx-2:hover {
  opacity: 0.9;
  bottom: 0px;
}

/*===========================
38. latest-blog-dark
===========================*/

/*=========================*/

.home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav {
  position: absolute;
  margin-top: 0px;
  top: -10px;
  left: -80px;
}

.home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav .owl-prev {
  left: 40px;
  position: relative;
  background-color: #1a1a1a;
  margin: 0px;
}

.home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav .owl-next {
  left: 5px;
  position: relative;
  top: 40px;
  background-color: #1a1a1a;
  margin: 0px;
}

@media only screen and (max-width: 800px) {
  .home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav {
    left: auto;
    right: 5px;
    top: auto;
    bottom: -47px;
  }
  .home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav .owl-prev {
    left: 0px;
  }
  .home-client-carousel.owl-btn-center-v.owl-carousel .owl-nav .owl-next {
    left: 5px;
    top: 0px;
  }
}

/*======================================
42. Contact Us
======================================*/
.home-contact-section {
  position: relative;
  background-color: #fff;
}
.home-contact-section .cons-contact-form {
  margin-left: 0px;
}
.home-contact-section input.form-control {
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #e2e2e2;
  padding: 10px 15px;
}
.home-contact-section textarea.form-control {
  border-style: solid;
  border-width: 0px 0px 2px 0px;
  border-color: #e2e2e2;
  padding: 10px 15px 10px 15px;
}

.contact-one {
  position: relative;
}

.home-contact-section .form-control::-webkit-input-placeholder,
.contact-one .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000;
  font-size: 14px;
}

.home-contact-section .form-control::-moz-placeholder,
.contact-one .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #000;
  font-size: 14px;
}

.home-contact-section .form-control:-ms-input-placeholder,
.contact-one .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #000;
  font-size: 14px;
}

.home-contact-section
  .form-control:-moz-placeholder
  .contact-one
  .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #000;
  font-size: 14px;
}

.contact-right-section {
  position: relative;
  z-index: 1;
  margin-left: 40px;
  margin-bottom: 70px;
}
.contact-right-section:before {
  position: absolute;
  content: '';
  z-index: -1;
  left: -40px;
  bottom: -40px;
  width: 100%;
  height: 90%;
  border: 12px solid #1a1a1a;
}

@media only screen and (max-width: 991px) {
  .home-contact-section {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-right-section {
    margin-bottom: 40px;
  }
}

.contact-info {
  position: relative;
  z-index: 1;
}

.contact-info .icon-content p {
  word-break: normal;
  margin-bottom: 5px;
}

.contact-info-section {
  padding: 30px;
  border: 2px solid #d72323;
}
.contact-info-section span {
  display: block;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 700;
  font-size: 18px;
}

.cons-contact-form {
  margin-left: 30px;
}
.cons-contact-form .form-control {
  border-bottom: 1px solid #ebebeb;
  height: 52px;
  padding: 10px 20px;
  background: #fafafa;
}
.cons-contact-form textarea.form-control {
  padding-top: 15px;
}

@media only screen and (max-width: 767px) {
  .cons-contact-form {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 576px) {
  .contact-one {
    padding: 0px;
    border-radius: 0px;
    box-shadow: none !important;
  }

  .contact-info-section h3 {
    font-size: 28px;
  }
}

/*===============================================
	Modal
=============================================== */

.button-model button {
  min-width: 200px;
  text-align: center !important;
  text-transform: uppercase;
}

.modal-footer button {
  min-width: inherit;
}

.button-model button.close {
  min-width: inherit;
}

@media only screen and (max-width: 640px) {
  h2.font-45 {
    font-size: 32px;
    line-height: 38px;
  }
  h2 a.font-45 {
    font-size: 32px;
    line-height: 38px;
  }
}

@media only screen and (max-width: 360px) {
  h2.font-45 {
    font-size: 22px;
    line-height: 28px;
  }
  h2 a.font-45 {
    font-size: 22px;
    line-height: 28px;
  }
}

/*===============================================
	Responsive container
=============================================== */

@media only screen and (max-width: 540px) {
  .is-fixed .navbar-toggler {
    margin-bottom: 0px;
  }
}

/*================ Social  css=======*/

/*====revolution slider start styles=======*/
.rev-btn.white .site-button {
  background-color: #fff;
  color: #000;
  padding: 15px 40px !important;
  letter-spacing: 1px;
  line-height: normal !important;
}
.rev-btn.white .site-button:hover {
  background-color: #000;
  color: #fff !important;
}

.rev-btn .site-button {
  padding: 15px 40px !important;
  letter-spacing: 1px;
  line-height: normal !important;
}
.rev-btn .site-button:hover {
  background-color: #000;
  color: #fff !important;
}

#welcome_wrapper .metis.tparrows {
  background-color: #d72323;
}
#welcome_wrapper .metis.tparrows::before {
  color: #fff;
}

.rev-title-left-border {
  border-left: 8px solid #d72323;
}

/*----revolution slider dotts style Start----*/
.bullet-bar.tp-bullets:before {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 10px;
  margin-left: -10px;
  margin-top: -10px;
  box-sizing: content-box;
}
.bullet-bar .tp-bullet {
  width: 60px;
  height: 3px;
  position: absolute;
  background: #aaa;
  background: rgba(204, 204, 204, 0.5);
  cursor: pointer;
  box-sizing: content-box;
}
.bullet-bar .tp-bullet:hover,
.bullet-bar .tp-bullet.selected {
  background: rgba(204, 204, 204, 1);
}

.hermes .tp-bullet:hover {
  background-color: #d72323 !important;
}
.hermes .tp-bullet.selected:after {
  height: 100%;
  background-color: #d72323 !important;
}

/*----revolution slider dotts style end----*/

.rev-slider-play .ripple-cirlcle,
.rev-slider-play .ripple-cirlcle:before,
.rev-slider-play .ripple-cirlcle:after {
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  height: 500px;
  width: 500px;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform-origin: center;
  transform-origin: center center;

  -webkit-box-shadow: 0 0 0 0 rgb(255, 255, 255, 0.1);
  -moz-box-shadow: 0 0 0 0 rgb(255, 255, 255, 0.1);
  -ms-box-shadow: 0 0 0 0 rgb(255, 255, 255, 0.1);
  -o-box-shadow: 0 0 0 0 rgb(255, 255, 255, 0.1);
  box-shadow: 0 0 0 0 rgb(255, 255, 255, 0.1);

  -webkit-animation: ripple-slider 3s infinite;
  -moz-animation: ripple-slider 3s infinite;
  -ms-animation: ripple-slider 3s infinite;
  -o-animation: ripple-slider 3s infinite;
  animation: ripple-slider 3s infinite;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.rev-slider-play .ripple-cirlcle:before {
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
  -ms-animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: '';
  position: absolute;
}

.rev-slider-play .ripple-cirlcle:after {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: '';
  position: absolute;
}

@-webkit-keyframes ripple-slider {
  70% {
    box-shadow: 0 0 0 150px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes ripple-slider {
  70% {
    box-shadow: 0 0 0 150px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@media only screen and (max-width: 480px) {
  .rev-slider-play .ripple-cirlcle,
  .rev-slider-play .ripple-cirlcle:before,
  .rev-slider-play .ripple-cirlcle:after {
    height: 300px;
    width: 300px;
  }
}

/*-------------------------*/
.rev-slider-play2 .ripple-cirlcle2,
.rev-slider-play2 .ripple-cirlcle2:before,
.rev-slider-play2 .ripple-cirlcle2:after {
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 50%;
  left: 50%;
  height: 500px;
  width: 500px;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform-origin: center;
  transform-origin: center center;

  -webkit-box-shadow: 0 0 0 0 rgb(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 0 0 rgb(0, 0, 0, 0.1);
  -ms-box-shadow: 0 0 0 0 rgb(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 0 0 rgb(0, 0, 0, 0.1);
  box-shadow: 0 0 0 0 rgb(0, 0, 0, 0.1);

  -webkit-animation: ripple-slider 3s infinite;
  -moz-animation: ripple-slider 3s infinite;
  -ms-animation: ripple-slider 3s infinite;
  -o-animation: ripple-slider 3s infinite;
  animation: ripple-slider 3s infinite;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.rev-slider-play2 .ripple-cirlcle2:before {
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
  -ms-animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: '';
  position: absolute;
}

.rev-slider-play2 .ripple-cirlcle2:after {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: '';
  position: absolute;
}

@-webkit-keyframes ripple-slider2 {
  70% {
    box-shadow: 0 0 0 150px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes ripple-slider2 {
  70% {
    box-shadow: 0 0 0 150px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@media only screen and (max-width: 480px) {
  .rev-slider-play2 .ripple-cirlcle2,
  .rev-slider-play2 .ripple-cirlcle2:before,
  .rev-slider-play2 .ripple-cirlcle2:after {
    height: 300px;
    width: 300px;
  }
}

@media only screen and (max-width: 480px) {
  #welcome_two .tp-bgimg {
    background-position: center right -480px !important;
  }
}

.light-number {
  font-size: 120px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.5);
}

.slider-block-black,
.slider-block-red,
.slider-block-blue {
  position: relative;
  z-index: 1;
}
.slider-block-black:after {
  content: '';
  position: absolute;
  border: 16px solid rgba(255, 255, 255, 0.3);
  left: -40px;
  top: -40px;
  right: 0px;
  bottom: -40px;
  width: 200px;
  z-index: -1;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.slider-block-black:before {
  content: '';
  position: absolute;
  background-color: #1a1a1a;
  left: -25px;
  top: -25px;
  right: 0px;
  bottom: -25px;
  width: 170px;
  z-index: -1;
}

.slider-block-red:after {
  content: '';
  position: absolute;
  border: 16px solid rgba(255, 255, 255, 0.3);
  left: -40px;
  top: -40px;
  right: 0px;
  bottom: -40px;
  width: 200px;
  z-index: -1;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.slider-block-red:before {
  content: '';
  position: absolute;
  background-color: #d3222a;
  left: -25px;
  top: -25px;
  right: 0px;
  bottom: -25px;
  width: 170px;
  z-index: -1;
}

.slider-block-sky:after {
  content: '';
  position: absolute;
  border: 16px solid #d72323;
  left: -40px;
  top: -40px;
  right: 0px;
  bottom: -40px;
  width: 200px;
  z-index: -1;
  border-right: 0px;
}
.slider-block-sky:before {
  content: '';
  position: absolute;
  background-color: transparent;
  left: -25px;
  top: -25px;
  right: 0px;
  bottom: -25px;
  width: 170px;
  z-index: -1;
}

.slider-block-video:after {
  content: '';
  position: absolute;
  border: 16px solid #d72323;
  right: -40px;
  top: -60px;
  bottom: -60px;
  width: 100px;
  z-index: -1;
  border-left: 0px;
}

.slider-block-video:before {
  content: '';
  position: absolute;
  border: 16px solid #d72323;
  left: -40px;
  top: -60px;
  bottom: -60px;
  width: 100px;
  z-index: -1;
  border-right: 0px;
}

@media only screen and (max-width: 778px) {
  .slider-block-black:after {
    display: none;
  }
  .slider-block-black:before {
    display: none;
  }

  .slider-block-red:after {
    display: none;
  }
  .slider-block-red:before {
    display: none;
  }

  .slider-block-sky:after {
    display: none;
  }
  .slider-block-sky:before {
    display: none;
  }
}

.slider-bg-large-text {
  color: rgb(255, 255, 255, 0.1);
  text-transform: uppercase;
}
.slider-block {
  border: 50px solid rgb(255, 255, 255, 0.1);
}

.slider-block-2 {
  border: 16px solid #1a1a1a;
}

.slider-outer {
  position: relative;
}

/*====revolution slider END styles=======*/
/*-------------------------------------
Spin circle
-------------------------------------*/

.spin-circle {
  -webkit-animation: fa-spin 25s infinite linear;
  animation: fa-spin 25s infinite linear;
}

@-webkit-keyframes spin-circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin-circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/*======================================
59. animated play btn
======================================*/

.play-now .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 65px;
  width: 65px;
  text-align: center;
  line-height: 65px;
  background-color: #fff;
  color: #d72323;
  z-index: 1;
  font-size: 20px;
  padding-left: 5px;
  display: block;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform-origin: center;
  transform-origin: center center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
  -ms-box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
  -o-box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
  box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
}

.play-now .ripple,
.play-now .ripple:before,
.play-now .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 65px;
  width: 65px;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform-origin: center;
  transform-origin: center center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgb(215, 35, 35);
  -moz-box-shadow: 0 0 0 0 rgb(215, 35, 35);
  -ms-box-shadow: 0 0 0 0 rgb(215, 35, 35);
  -o-box-shadow: 0 0 0 0 rgb(215, 35, 35);
  box-shadow: 0 0 0 0 rgb(215, 35, 35);
  -webkit-animation: ripple 3s infinite;
  -moz-animation: ripple 3s infinite;
  -ms-animation: ripple 3s infinite;
  -o-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}

.play-now .ripple:before {
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
  -ms-animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: '';
  position: absolute;
}

.play-now .ripple:after {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: '';
  position: absolute;
}

@-webkit-keyframes ripple {
  70% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes ripple {
  70% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

/*====video section====*/
.video-section {
  position: relative;
  z-index: 1;
  display: flex;
}

.how-it-work {
  position: relative;
}

.video-section img {
  width: 100%;
}

/*====Button Effect====*/

.site-btn-effect {
  color: rgba(0, 0, 0, 0) !important;
  transition: all 0.3s ease !important;
}

.pricing-btn {
  width: 90% !important;
}

.send-message-btn {
  width: 155%;
}

.site-btn-effect {
  text-shadow: 0 0 0 #fff, 400px 0 0 #fff;
}

.site-btn-effect:hover {
  text-shadow: -400px 0 0 #fff, 0 0 0 #fff;
}

/*====Filter option====*/

.filter-options {
  position: relative;
}

.filter-option-more {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.filter-option-title i.fa {
  transition: 0.2s;
  transform: rotate(0deg);
  padding-left: 10px;
}

.filter-options.active .filter-option-more {
  display: block;
}

.filter-options.active .filter-option-title i.fa {
  transform: rotate(180deg);
  padding-left: 0px;
  padding-right: 10px;
}

/*-----------effect-link--------*/

.site-button-link {
  position: relative;
  font-family: 'Rajdhani', sans-serif;
  font-size: 18px;
  line-height: 18px;
}

.site-button-link:before {
  position: absolute;
  content: '';
  right: -30px;
  top: 8px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  width: 20px;
  height: 2px;
  background-color: #d72323;
}

.site-button-link:hover:before {
  width: 30px;
  right: -40px;
  background-color: #404040;
}

/*===================
	Banner 1
===================*/

.banner-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0f1221;
}

.banner-wrap {
  position: relative;
}

.banner-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

.banner-content-area {
  padding: 250px 0 200px;
}

.banner-wrap .banner-textrow {
  color: #fff;
  text-align: center;
  padding: 0 0 50px;
}

.banner-wrap .banner-textrow strong {
  font-size: 50px;
  color: #fff;
  display: block;
  font-weight: 600;
  padding-bottom: 15px;
}

.banner-wrap .banner-textrow span {
  font-size: 20px;
  margin-bottom: 20px;
  display: block;
}

@media only screen and (max-width: 768px) {
  .banner-wrap .banner-textrow strong {
    font-size: 22px;
  }
  .banner-wrap .banner-textrow span {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .banner-content-area {
    padding: 130px 0px 60px;
  }

  .banner-wrap .banner-textrow {
    padding: 0 0 0px;
  }
}

/*select box*/

.dropdown-menu {
  font-size: 13px;
}

.bootstrap-select:not([class*='col-']):not([class*='form-control']):not(
    .input-group-btn
  ) {
  width: 100%;
}

.bootstrap-select .dropdown-toggle {
  background-color: #fff;
  height: 50px;
  font-size: 13px;
  color: #999;
  border: 1px solid #ddd;
}

@media only screen and (max-width: 991px) {
  .bootstrap-select .dropdown-toggle {
    height: 40px;
  }
}

.selectpicker + .dropdown-toggle {
  position: relative;
}
.selectpicker + .dropdown-toggle:after {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 8px;
  width: 0px;
  height: 0px;
  content: '';
  border-top: 6px solid #b6b6b6;
  border-right: 6px solid transparent;
  border-bottom: 0;
  border-left: 6px solid transparent;
}

.bootstrap-select .dropdown-toggle:active,
.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select .dropdown-toggle:hover {
  background-color: #fff !important;
  border: 1px solid #e1e6eb !important;
  box-shadow: none !important;
  outline: 0px !important;
}

.bootstrap-select:active,
.bootstrap-select:focus {
  border: 0px !important;
  box-shadow: none !important;
  outline: 0px !important;
}

.bootstrap-select.open {
  box-shadow: none !important;
  outline: 0px !important;
}

.bootstrap-select.open .dropdown-toggle {
  box-shadow: none !important;
  outline: 0px !important;
  background: #fff;
  border: 1px solid #e1e6eb;
}

.bootstrap-select div.dropdown-menu {
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  border: 1px solid #e9e9e9;
  border-radius: 0;
  box-shadow: none;
  margin-top: -1px;
  padding: 0;
  font-size: 13px;
  -webkit-box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.1);
}

.bootstrap-select div.dropdown-menu ul li {
  padding: 0;
}

.bootstrap-select div.dropdown-menu ul li a {
  padding: 10px 15px;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1a1a1a;
}

.bootstrap-select div.dropdown-menu ul li a:hover {
  background-color: #ededed;
}

.bootstrap-select div.dropdown-menu ul li a img {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  margin-right: 5px;
}

.bootstrap-select .bs-searchbox .form-control {
  padding: 5px 10px;
  height: 30px;
}

.bootstrap-select.btn-group .dropdown-toggle .caret {
  width: 12px;
  height: 12px;
  border: none;
  margin-top: -6px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.bootstrap-select.btn-group.open .dropdown-toggle .caret {
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.bootstrap-select.btn-group .dropdown-toggle .caret:after {
  content: '\f107';
  font-family: 'FontAwesome';
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
  height: 12px;
  line-height: 12px;
}
@media only screen and (max-width: 767px) {
  .p-b80 {
    padding-bottom: 30px;
  }
}

/*News Letter Section*/

.footer-dark .widget_newsletter p {
  color: #999;
  font-size: 14px;
  margin-bottom: 30px;
}

.newsletter-input .input-group-text {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  font-family: 'Muli', sans-serif;
}

.newsletter-input input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #999;
  font-size: 14px;
}

.newsletter-input input::-moz-placeholder {
  /* Firefox 19+ */
  color: #999;
  font-size: 14px;
}

.newsletter-input input:-ms-input-placeholder {
  /* IE 10+ */
  color: #999;
  font-size: 14px;
}

.newsletter-input input:-moz-placeholder {
  /* Firefox 18- */
  color: #999;
  font-size: 14px;
}

.nl-search-btn {
  border: none;
  outline: none;
  border-radius: 0px;
  padding: 0px 10px;
}
.nl-search-btn:hover {
  background-color: #1a1a1a;
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .newsletter-title span {
    margin-bottom: 20px;
    display: block;
  }
}

/*Error-search*/

.error-input {
  width: 100%;
  margin: 30px auto;
}

.error-input .form-control {
  background-color: #efefef;
}

.error-input span:first-child {
  padding: 0px;
}

.error-input span:last-child {
  overflow: hidden;
  padding: 0px;
}

.error-input .input-group-addon {
  border: 0px;
  border-radius: 0px;
}

.error-input input {
  border: 0px;
}

.error-search-btn {
  background: #111111;
  border: none;
  color: #fff;
  height: 50px;
  padding: 0px 20px;
}

/*-----Error Full Page-----*/
.error-full-page {
  display: table;
  width: 100%;
  height: 100vh;
}

.error-full-page-inner {
  display: table-cell;
  width: 100%;
  text-align: center;
  padding: 50px 50px;
  max-width: 700px;
  margin: auto;
  vertical-align: middle;
  position: relative;
}
.error-full-page-inner-info {
  padding: 50px;
  max-width: 800px;
  margin: 0px auto;
}
.error-full-page-inner-info p {
  color: #fff;
  margin-bottom: 40px;
}
.error-full-page-inner-info h3 {
  color: #fff;
}

.error-full-page-inner-info strong {
  font-size: 150px;
  line-height: 150px;
  font-weight: 900;
  color: #d72323;
  font-family: 'Rajdhani', sans-serif;
}
.error-full-page-inner-info h4 {
  font-size: 50px;
  color: #fff;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 991px) {
  .error-full-page-inner-info h4 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 640px) {
  .error-full-page-inner-info strong {
    font-size: 90px;
    line-height: 90px;
  }
}

/*===================
	INNER PAGE BANNER
====================*/
/*review form*/

.comment-form-rating label {
  display: inline-block;
}

.comment-form-rating .star-Rating-input {
  display: inline-block;
  color: #ffc100 !important;
  font-size: 14px;
  margin-right: 10px;
}

.wt-input-icon .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #707070;
  font-size: 12px;
}

.wt-input-icon .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #707070;
  font-size: 12px;
  text-transform: uppercase;
}

.wt-input-icon .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #707070;
  font-size: 12px;
  text-transform: uppercase;
}

.wt-input-icon .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #707070;
  font-size: 12px;
  text-transform: uppercase;
}

.site-button-small {
  color: #000;
  display: inline-block;
  white-space: nowrap;
  line-height: 20px;
  padding: 3px 15px;
  font-weight: 600;
  border: none;
  font-size: 14px;
  margin: 0 5px 5px 0px;
}

.site-button-small:hover {
  opacity: 0.8;
}

button {
  outline: none !important;
}

/*==========touchspin input type number===============*/

.bootstrap-touchspin .input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 10px 10px 9px;
  margin-left: -1px;
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: #e1e1e1;
  background: #fff;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0;
  border-top-right-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  margin-top: -2px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-bottom-right-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical i {
  position: absolute;
  top: 4px;
  left: 5px;
  font-size: 9px;
  font-weight: normal;
  color: #9fa0a1;
}

.btn-quantity {
  max-width: 150px;
  width: 100%;
  margin-right: 30px;
  display: inline-block;
}
.btn-quantity .form-control {
  height: 52px;
  border: 1px solid #ddd;
}

.filter-destination-slider .slider .tooltip {
  z-index: 1;
}

/*circle-animation Start*/
@media only screen and (max-width: 767px) {
  .section-full.p-b80 {
    padding-bottom: 30px;
  }
  .section-full.p-b50 {
    padding-bottom: 30px;
  }
  .section-full.p-b40 {
    padding-bottom: 30px;
  }
  .section-full.p-t80 {
    padding-top: 30px;
  }
  .section-full.p-tb80 {
    padding-top: 30px;
  }
  .section-full.p-b70 {
    padding-bottom: 20px;
  }
  .section-full.p-t50 {
    padding-top: 30px;
  }
  .section-head {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 640px) {
  .section-full.p-t80 {
    padding-top: 30px;
  }
  .section-full.p-b70 {
    padding-bottom: 20px;
  }
  .section-full.p-t50 {
    padding-top: 30px;
  }
  .section-full.p-b80 {
    padding-bottom: 30px;
  }
  .section-full.p-b50 {
    padding-bottom: 30px;
  }
}
/*==================================================
 * Effect 5
 * ===============================================*/

@media only screen and (max-width: 767px) {
  .row.d-flex [class*='col-']:last-child .icon-circle-box .wt-icon-box-wraper {
    margin-bottom: 0px;
  }
}

.form-group {
  margin-bottom: 25px;
}

.form-control {
  height: 50px;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 12px;
  border-radius: 0px;
  border: none;
}

.form-control:focus {
  border-color: #d7dce1;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  background-color: #f8f8f8;
}

.form-control2 {
  border: none;
  border-bottom: 1px solid #e8e8e8;
  width: 100%;
  height: 44px;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 12px 10px 20px;
}

.form-control2:focus {
  border-bottom-color: #f11749;
}

.wt-input-icon .input-group-addon2 {
  position: absolute;
  left: 0px;
  top: 12px;
  z-index: 4;
  border: none;
  padding: 0;
  color: #222;
  -webkit-border-radius: 0px;
  border-radius: 6px;
  background: none;
}

textarea.form-control2 {
  height: auto;
}

.form-control.kv-fileinput-caption {
  height: 39px;
}

.form-group.form-inline .radio,
.form-group.form-inline .checkbox {
  margin-right: 15px;
}

/*About us page*/
@media only screen and (max-width: 991px) {
  .how-it-right {
    margin-bottom: 30px;
  }
}

.site-list-style-one {
  list-style: none;
  margin-bottom: 30px;
}

.site-list-style-one li {
  font-family: 'Rajdhani', sans-serif;
  color: #1a1a1a;
  font-size: 24px;
  position: relative;
  font-weight: 700;
}

.site-list-style-one.icon-style {
  list-style: none;
  margin-bottom: 30px;
  margin-top: 40px;
}

.site-list-style-one.icon-style li {
  font-family: 'Rajdhani', sans-serif;
  color: #1a1a1a;
  font-size: 24px;
  position: relative;
  font-weight: 700;
  padding: 0px 0px 20px 60px;
}

.site-list-style-one.icon-style li i {
  font-size: 40px;
  font-weight: normal;
  position: absolute;
  left: 0px;
  top: 0px;
  line-height: 40px;
}

@media only screen and (max-width: 540px) {
  .site-list-style-one.icon-style li {
    font-size: 16px;
    padding: 8px 0px 10px 50px;
  }
  .site-list-style-one.icon-style li i {
    font-size: 32px;
    line-height: 32px;
  }
}

/*Large Title*/
.hilite-large-title {
  position: relative;
}
.hilite-large-title span {
  font-size: 120px;
  line-height: 100px;
  color: #1a1a1a;
  opacity: 0.1;
  font-family: 'Rajdhani', sans-serif;
  display: inline-block;
}
@media only screen and (max-width: 991px) {
  .hilite-large-title span {
    font-size: 72px;
    line-height: 76px;
  }
}
@media only screen and (max-width: 480px) {
  .hilite-large-title span {
    font-size: 40px;
    line-height: 46px;
  }
}

.counter-outer .counter {
  font-size: 80px;
  position: relative;
  display: inline-block;
  line-height: 60px;
}

.counter-outer span {
  font-size: 18px;
  line-height: 18px;
  display: block;
  font-weight: 600;
}

.counter-outer h2 span {
  font-size: 80px;
  line-height: 60px;
  display: block;
  font-weight: 700;
}
.counter-box {
  text-align: center;
  padding: 30px 20px;
  border: 1px solid #c6c6c6;
  position: relative;
}

.counter-box:after,
.counter-box:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
}

.counter-box:after {
  left: -6px;
  top: -6px;
  border-style: solid;
  border-width: 2px 0px 0px 2px;
  border-color: #d72323;
}
.counter-box:before {
  right: -6px;
  bottom: -6px;
  border-style: solid;
  border-width: 0px 2px 2px 0px;
  border-color: #d72323;
}

@media only screen and (max-width: 767px) {
  .counter-outer3.p-b50 {
    padding-bottom: 0px !important;
  }
}

@media only screen and (max-width: 540px) {
  .counter-outer .counter {
    font-size: 40px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 400px) {
  .counter-outer .col-6 {
    max-width: 100%;
    flex: auto;
  }
  .counter-outer3.p-b50 {
    padding-bottom: 30px !important;
  }
}

/*----Icon Box Style One*/
.d-icon-box-one {
  position: relative;
  padding: 50px 30px;
}
.d-icon-box-title {
  margin-bottom: 10px;
}
.d-icon-box-title span {
  font-size: 30px;
  display: block;
  text-align: left;
  line-height: 36px;
}

.services-slider.owl-btn-vertical-center .owl-nav .owl-next {
  right: -1px;
}
.services-slider.owl-btn-vertical-center .owl-nav .owl-next,
.services-slider.owl-btn-vertical-center .owl-nav .owl-prev {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.services-slider:hover.owl-btn-vertical-center .owl-nav .owl-next {
  right: 5%;
}
.services-slider:hover.owl-btn-vertical-center .owl-nav .owl-prev {
  left: 5%;
}

.services-slider .owl-nav {
  opacity: 0;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.services-slider:hover .owl-nav {
  opacity: 1;
}

.wt-icon-number {
  position: absolute;
  right: 0px;
  top: 0px;
}
.wt-icon-number span {
  font-size: 120px;
  line-height: 100px;
  color: rgba(0, 0, 0, 0.1);
  font-weight: 500;
  opacity: 0.5;
  display: block;
  font-family: 'Rajdhani', sans-serif;
}

/*----Team Style One--*/
.wt-team-1 {
  margin-right: 30px;
  position: relative;
}
.wt-team-1 .team-name a:hover {
  color: #d72323;
}

.wt-team-1 .team-position {
  font-size: 16px;
  line-height: 16px;
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  position: absolute;
  right: -20px;
  width: 0px;
  white-space: nowrap;
  top: -8px;
  letter-spacing: 1px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
}

.wt-team-1 .wt-media img {
  width: 100%;
}
.wt-team-1 .team-social-center {
  position: relative;
}
.wt-team-1 .team-social-center ul {
  list-style: none;
  margin-bottom: 0px;
}
.wt-team-1 .team-social-center ul li {
  display: inline-block;
  padding-right: 20px;
}
.wt-team-1 .team-social-center ul li:last-child {
  padding-right: 0px;
}
.wt-team-1 .team-social-center ul li a {
  font-size: 16px;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 500;
  position: relative;
}
.wt-team-1 .team-social-center ul li a:after {
  position: absolute;
  right: -18px;
  content: '';
  width: 10px;
  height: 1px;
  background-color: #1a1a1a;
  top: 10px;
}
.wt-team-1 .team-social-center ul li:last-child a:after {
  display: none;
}

.wt-team-1 .wt-info {
  position: static;
}
.wt-team-1:hover .team-social-center {
  top: 0px;
}

/*----Team Style Two--*/

.wt-team-2 {
  position: relative;
}
.wt-team-2 .team-name a:hover {
  color: #d72323;
}

.wt-team-2 .team-position {
  font-size: 16px;
  line-height: 16px;
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
}

.wt-team-2 .wt-media img {
  width: 100%;
}
.wt-team-2 .team-social-center {
  position: relative;
}
.wt-team-2 .team-social-center ul {
  list-style: none;
  margin-bottom: 0px;
}
.wt-team-2 .team-social-center ul li {
  display: inline-block;
  padding-right: 20px;
}
.wt-team-2 .team-social-center ul li:last-child {
  padding-right: 0px;
}
.wt-team-2 .team-social-center ul li a {
  font-size: 16px;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 500;
  position: relative;
}
.wt-team-2 .team-social-center ul li a:after {
  position: absolute;
  right: -18px;
  content: '';
  width: 10px;
  height: 1px;
  background-color: #1a1a1a;
  top: 10px;
}
.wt-team-2 .team-social-center ul li:last-child a:after {
  display: none;
}

.wt-team-2 .wt-info {
  position: static;
  padding: 30px;
  border-style: solid;
  border-color: #ddd;
  border-width: 0px 1px 1px 1px;
}
.wt-team-2:hover .team-social-center {
  top: 0px;
}

/*=========== Flat Icons ============*/

.icon-font-block {
  text-align: center;
}

.icon-font-block .flat-icon {
  padding: 10px 0px;
  display: block;
  font-size: 64px;
  line-height: 1;
}
@media only screen and (max-width: 767px) {
  .icon-font-block .flat-icon {
    font-size: 28px;
  }
  .icon-font [class*='col-'] {
    width: 33%;
  }
}

@media only screen and (max-width: 400px) {
  .icon-font [class*='col-'] {
    width: 50%;
  }
}
/*Service Detail*/
.service-detail-video .video-section-2 {
  position: relative;
  z-index: 1;
  display: flex;
  padding: 250px 0px;
}

/*Single Team*/

.wt-team-1-single {
  margin-left: 30px;
}
.wt-team-1-single-info {
  margin-bottom: 20px;
}

.wt-team-1-single-list li {
  margin-bottom: 5px;
  font-weight: 600;
  position: relative;
  list-style: none;
  padding-left: 35px;
}

.wt-team-1-single-info li {
  list-style: none;
  clear: both;
  padding-bottom: 10px;
}
.wt-team-1-single-info li:last-child {
  padding-bottom: 0px;
}
.wt-team-1-single-info li span:first-child {
  padding-right: 30px;
  color: #d72323;
  width: 140px;
  display: inline-block;
  font-weight: 700;
}

.wt-team-1-single-info li span:last-child {
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .wt-team-1-single {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 640px) {
  .wt-team-1-single-info li span:first-child {
    display: block;
  }
}
@media only screen and (max-width: 540px) {
  .wt-team-1-single-info li span:first-child {
    float: none;
  }
}

/*media margin css*/
@media only screen and (max-width: 767px) {
  .row.justify-content-center [class*='col-']:last-child.m-b30 {
    margin-bottom: 0px;
  }
  .row.justify-content-center [class*='col-']:last-child.m-b40 {
    margin-bottom: 0px;
  }
  .row.justify-content-center [class*='col-']:last-child.m-b50 {
    margin-bottom: 10px;
  }
  .wt-team-1-single p:last-child {
    margin-bottom: 0px;
  }
  .home-contact-section {
    margin-bottom: 0px;
    margin-left: 0px;
    padding: 30px;
  }
}

/*-----Top Half Section-----*/
.top-half-section {
  margin-top: -100px;
}

@media only screen and (max-width: 1024px) {
  .top-half-section {
    margin-top: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .top-half-section {
    margin-top: 40px;
  }
}

/*======================================
    SHOP & PRODUCT DETAIL (Woocommerce)
======================================*/
/*-------------------------------------
	1. Product Box 
-------------------------------------*/
.wt-product-box {
  border: 10px solid #efefef;
}
.wt-product-box .price {
  padding-top: 10px;
  display: block;
}
.wt-product-box .price del {
  font-size: 16px;
  color: #999999;
  margin: 4px 4px;
  font-weight: 600;
}
.wt-product-box .price ins {
  font-size: 24px;
  color: #d72323;
  text-decoration: none;
  font-weight: 600;
  font-family: 'Rajdhani', sans-serif;
}
.wt-product-box .rating-bx {
  padding-top: 10px;
  color: #ffa000;
}

.product-features-item {
  padding: 3px 0px;
}
.product-features-item span {
  font-weight: 700;
  display: inline-block;
  padding-right: 5px;
}
@media only screen and (max-width: 540px) {
  .wt-product-box .price ins {
    font-size: 20px;
  }
}

/*-------------------------------------
	3.  Product Review 
-------------------------------------*/
ol.commentlist {
  list-style: none;
  margin: 0;
}
ol.commentlist li {
  position: relative;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
}
ol.commentlist li img {
  float: left;
  border: 5px solid #fff;
  width: 80px;
  height: auto;
}
ol.commentlist li .comment-text {
  padding-left: 100px;
}
ol.commentlist li .meta {
  margin-bottom: 5px;
}
ol.commentlist li .meta strong {
  font-size: 16px;
}
ol.commentlist li .meta span {
  font-size: 12px;
  color: #d72323;
  font-weight: 700;
  float: right;
}
ol.commentlist li .meta .time {
  color: #999;
  display: block;
  font-size: 14px;
}
ol.commentlist li .description p {
  margin: 0;
}
ol.commentlist li .star-rating {
  position: absolute;
  top: 20px;
  right: 20px;
}
@media only screen and (max-width: 767px) {
  ol.commentlist li .star-rating {
    position: inherit;
  }
}
.comment-reply-title {
  margin-top: 0;
}
.comment-form [class*='comment-form'] {
  width: 50%;
}
@media only screen and (max-width: 767px) {
  .comment-form [class*='comment-form'] {
    width: 100%;
  }
}
.comment-form [class*='-form-author'],
.comment-form [class*='-form-email'] {
  float: left;
}
.comment-form [class*='-form-comment'] {
  width: 100%;
}
.comment-form label {
  display: block;
}
.star-Rating-input i {
  font-size: 18px;
}
.star-Rating-input .fa-star {
  color: #ffbc13;
}
.star-Rating-input i:hover {
  color: #ffbc13;
}
.comment-form [class*='comment-form'] input,
.comment-form [class*='comment-form'] textarea {
  border: 1px solid #ccc;
  padding: 8px 10px;
  width: 100%;
}
.comment-form {
  margin: 0 -15px;
}
.comment-form [class*='comment-form'],
.comment-form .form-submit {
  padding: 0 15px;
}
.comment-form .form-submit input:active,
.comment-form .form-submit input:focus,
.comment-form .form-submit input:hover {
  background: #037a85;
}

/*17.5 touchspin input type number */
.bootstrap-touchspin .input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}
.bootstrap-touchspin .input-group-btn-vertical > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 13px 10px 12px;
  margin-left: -1px;
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: #e1e1e1;
  background: #1a1a1a;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0;
  border-top-right-radius: 0;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  margin-top: -2px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-bottom-right-radius: 0;
}
.bootstrap-touchspin .input-group-btn-vertical i {
  position: absolute;
  top: 7px;
  left: 7px;
  font-size: 9px;
  font-weight: normal;
  color: #fff;
}

/*======================================

48. shopping-table

======================================*/
.shopping-table .cf th {
  color: #060000;
  border-bottom: 0px;
}

.shopping-cart-total .sub_total {
  position: relative;
  padding: 30px 30px;
  background: #fff;
  border-radius: 3px;
  max-width: 380px;
  margin-left: auto;
  border: 2px solid #1a1a1a;
}

.shopping-cart-total .sub_total .top {
  position: relative;
  overflow: auto;
  padding-bottom: 20px;
  border-bottom: 1px solid #d0d0d0;
  list-style: none;
}
.shopping-cart-total .sub_total .top {
  margin-bottom: 0px;
}
.shopping-cart-total .sub_total .top li {
  float: left;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  font-family: 'Rajdhani', sans-serif;
}

.shopping-cart-total .shipping {
  position: relative;
  padding: 20px 0px;
  border-bottom: 1px solid #d0d0d0;
}

.shopping-cart-total .shipping h2 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 10px;
}
.shopping-cart-total .shipping ul {
  margin-bottom: 0px;
}
.shopping-cart-total .shipping ul li {
  padding-left: 0;
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 8px;
}

.shopping-cart-total .shipping ul li span {
  float: right;
}
.shopping-cart-total .sub_total .top li:last-child {
  float: right;
  color: #dc2d4e;
  font-size: 24px;
}

.shopping-cart-total .total {
  position: relative;
  padding-top: 20px;
  font-family: 'Rajdhani', sans-serif;
}

.shopping-cart-total .total ul {
  margin-bottom: 0px;
}
.shopping-cart-total .total ul li {
  display: inline-block;
  width: 49%;
  font-size: 20px;
  line-height: 30px;
  color: #362f62;
  font-weight: 700;
}

.shopping-cart-total .total ul li:last-child {
  text-align: right;
  color: #dc2d4e;
  font-size: 24px;
  float: right;
}

@media only screen and (max-width: 767px) {
  .shopping-cart-total .sub_total {
    margin: 0px;
  }
  .proceed-to-checkout {
    width: 100% !important;
    text-align: center !important;
  }
}

.cart-buttons .site-button {
  background-color: #0e0e0e;
}

.cart-buttons .site-button:hover {
  background-color: #d72323;
}

.checkout-button {
  width: 100% !important;
  margin-top: 40px;
  text-align: center;
}

/*---How It Work Section---*/
.how-it-drone-outer {
  position: relative;
}

.how-it-drone-media {
  position: relative;
  z-index: 1;
  padding-top: 0px;
}
.how-it-drone-info {
  position: absolute;
  right: 15px;
  bottom: 0px;
}
.how-it-drone-info span {
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'Rajdhani', sans-serif;
  letter-spacing: 1px;
  font-weight: 700;
  padding-left: 60px;
}
.how-it-drone-info span:before {
  position: absolute;
  content: '';
  width: 50px;
  height: 2px;
  background-color: #1a1a1a;
  left: 0px;
  top: 13px;
}

@media only screen and (max-width: 991px) {
  .how-it-drone {
    margin-bottom: 100px;
  }

  .how-it-work-section-one [class*='col-']:last-child {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .how-it-work-section-one {
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 640px) {
  .how-it-drone-info {
    right: 15px;
    left: 15px;
  }
  .how-it-drone-info span {
    padding-left: 0px;
  }
  .how-it-drone-info span::before {
    display: none;
  }

  .how-it-drone-media img {
    width: 300px;
  }
}

@media only screen and (max-width: 400px) {
  .how-it-drone-media img {
    width: 200px;
  }
}

.how-it-drone3 .number-style {
  font-style: normal;
  font-weight: 700;
  font-family: 'Rajdhani', sans-serif;
  color: #ddd;
}

.ripple-cirlcle2,
.ripple-cirlcle2:before,
.ripple-cirlcle2:after {
  background-color: rgba(215, 35, 35, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  height: 280px;
  width: 280px;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform-origin: center;
  transform-origin: center center;
  z-index: 0;

  -webkit-box-shadow: 0 0 0 0 rgb(215, 35, 35, 0.5);
  -moz-box-shadow: 0 0 0 0 rgb(215, 35, 35, 0.5);
  -ms-box-shadow: 0 0 0 0 rgb(215, 35, 35, 0.5);
  -o-box-shadow: 0 0 0 0 rgb(215, 35, 35, 0.5);
  box-shadow: 0 0 0 0 rgb(215, 35, 35, 0.5);

  -webkit-animation: ripple-circle2 3s infinite;
  -moz-animation: ripple-circle2 3s infinite;
  -ms-animation: ripple-circle2 3s infinite;
  -o-animation: ripple-circle2 3s infinite;
  animation: ripple-circle2 3s infinite;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}

.ripple-cirlcle2:before {
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
  -ms-animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: '';
  position: absolute;
}

.ripple-cirlcle2:after {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: '';
  position: absolute;
}

@-webkit-keyframes ripple-circle2 {
  70% {
    box-shadow: 0 0 0 50px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes ripple-circle2 {
  70% {
    box-shadow: 0 0 0 50px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@media only screen and (max-width: 640px) {
  .ripple-cirlcle2,
  .ripple-cirlcle2::before,
  .ripple-cirlcle2::after {
    width: 200px;
    height: 200px;
  }
}

/*hover line animation*/
.hover-line-effect-one {
  position: relative;
  overflow: hidden;
}
.hover-line-effect-one:after,
.hover-line-effect-one:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 0px;
  background-color: #d72323;
  z-index: 1;

  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.hover-line-effect-one:before {
  left: 0px;
  top: -100%;
}
.hover-line-effect-one:after {
  right: 0px;
  bottom: -100%;
}

.hover-line-effect-one:hover:before {
  top: 0%;
  height: 100px;
}
.hover-line-effect-one:hover:after {
  bottom: 0%;
  height: 100px;
}

/*---red corner---*/

.corner-line {
  position: relative;
  z-index: 1;
}
.corner-line:after,
.corner-line:before {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #d72323;
  z-index: -1;

  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.corner-line:before {
  left: -4px;
  bottom: -4px;
}
.corner-line:after {
  right: -4px;
  bottom: -4px;
}

/*About Section One*/
.outline-title {
  font-family: 'Rajdhani', sans-serif;
  color: transparent;
  font-weight: 700;
  font-size: 100px;
  line-height: 70px;
  text-transform: uppercase;

  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  -ms-text-fill-color: rgba(0, 0, 0, 0);
  -moz-text-fill-color: rgba(0, 0, 0, 0);
  -o-text-fill-color: rgba(0, 0, 0, 0);

  -webkit-text-stroke-width: 1px;
  -ms-text-stroke-width: 1px;
  -moz-text-stroke-width: 1px;
  -o-text-stroke-width: 1px;

  -webkit-text-stroke-color: #000;
  -ms-text-stroke-color: #000;
  -moz-text-stroke-color: #000;
  -o-text-stroke-color: #000;

  display: block;
}

@media only screen and (max-width: 540px) {
  .outline-title {
    font-size: 70px;
    line-height: 50px;
  }
}

.outline-title2 {
  font-family: 'Rajdhani', sans-serif;
  color: #fff;
  font-weight: 700;
  font-size: 100px;
  line-height: 80px;
  text-transform: uppercase;

  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  -ms-text-fill-color: rgba(0, 0, 0, 0);
  -moz-text-fill-color: rgba(0, 0, 0, 0);
  -o-text-fill-color: rgba(0, 0, 0, 0);

  -webkit-text-stroke-width: 1px;
  -ms-text-stroke-width: 1px;
  -moz-text-stroke-width: 1px;
  -o-text-stroke-width: 1px;

  -webkit-text-stroke-color: #fff;
  -ms-text-stroke-color: #fff;
  -moz-text-stroke-color: #fff;
  -o-text-stroke-color: #fff;

  display: block;
}

@media only screen and (max-width: 540px) {
  .outline-title2 {
    font-size: 60px;
    line-height: 50px;
  }
}
/*--about style one----*/
.about-drone-one {
  border: 50px solid #d72323;
  padding: 20px;
  margin-right: 50px;
}
.about-drone-one-info strong {
  font-size: 48px;
  line-height: 48px;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  font-family: 'Rajdhani', sans-serif;
  color: #1a1a1a;
  letter-spacing: 5px;
}
.about-drone-one-media {
  height: 315px;
}
.about-drone-one-media img {
  position: absolute;
  right: 0px;
  top: 20%;
  width: auto;
}

/*--about style Two---*/
.about-drone-two {
  padding: 20px 50px 50px 20px;
  position: relative;
  z-index: 1;
}
.about-drone-two:before {
  content: '';
  left: 0px;
  top: 0px;
  z-index: -1;
  position: absolute;
  width: 210px;
  height: 180px;
  background-color: #d72323;
}
.about-drone-two:after {
  content: '';
  right: 0px;
  bottom: 0px;
  z-index: -1;
  position: absolute;
  width: 40%;
  height: 60%;
  background-image: none;
  background-repeat: repeat;
}
.about-drone-two-info {
  position: absolute;
  text-align: right;
  bottom: 50px;
  left: 20px;
  right: 50px;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 20px;
}
.about-drone-two-info strong {
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  font-family: 'Rajdhani', sans-serif;
  color: #fff;
  letter-spacing: 5px;
}
.about-drone-two-media img {
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .about-drone-one-info strong {
    font-size: 38px;
    line-height: 38px;
  }
}
@media only screen and (max-width: 767px) {
  .about-drone-one {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 540px) {
  .about-drone-two-info strong {
    font-size: 20px;
    line-height: 20px;
  }
  .about-drone-two-info {
    bottom: 0px;
    left: 20px;
    right: 0px;
  }
}
@media only screen and (max-width: 540px) {
  .about-drone-two {
    padding: 20px 0px 0px 20px;
  }
}

/*--about style Three----*/
.about-drone-three {
  padding: 20px 60px 60px 20px;
  position: relative;
  z-index: 1;
}
.about-drone-three:before {
  content: '';
  left: 0px;
  top: 0px;
  z-index: -1;
  position: absolute;
  width: 210px;
  height: 180px;
  background-color: #d72323;
}
.about-drone-three:after {
  content: '';
  right: 0px;
  bottom: 0px;
  z-index: -1;
  position: absolute;
  width: 40%;
  height: 60%;
  background-image: none;
  background-repeat: repeat;
}
.about-drone-three-info {
  position: absolute;
  text-align: right;
  bottom: 30px;
  right: 30px;
  background-color: #d72323;
  padding: 20px;
}
.about-drone-three-info:after {
  position: absolute;
  right: 0px;
  top: -30px;
  content: '';
  z-index: 0;
  width: 0;
  height: 0;
  border-bottom: 30px solid #801414;
  border-right: 30px solid transparent;
}

.about-drone-three-info span {
  font-size: 100px;
  font-weight: 700;
  font-family: 'Rajdhani', sans-serif;
  color: #fff;
  line-height: 90px;
  display: block;
}
.about-drone-three-info strong {
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  font-family: 'Rajdhani', sans-serif;
  color: #fff;
  letter-spacing: 5px;
}
.about-drone-three-media img {
  width: 100%;
}

.slide-top {
  -webkit-animation: slide-top 3s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite
    alternate-reverse both;
  animation: slide-top 3s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite
    alternate-reverse both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}

.wave-effects {
  -webkit-animation: slide 4s linear infinite backwards;
  animation: slide 4s linear infinite backwards;
}

@keyframes slide {
  0% {
    -moz-transform: translateX(0px) translateY(0px);
    -webkit-transform: translateX(0px) translateY(0px);
    -o-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
  25% {
    -moz-transform: translateX(40px) translateY(0px);
    -webkit-transform: translateX(40px) translateY(0px);
    -o-transform: translateX(40px) translateY(0px);
    -ms-transform: translateX(40px) translateY(0px);
    transform: translateX(40px) translateY(0px);
  }
  50% {
    -moz-transform: translateX(40px) translateY(60px);
    -webkit-transform: translateX(40px) translateY(60px);
    -o-transform: translateX(40px) translateY(60px);
    -ms-transform: translateX(40px) translateY(60px);
    transform: translateX(40px) translateY(60px);
  }
  75% {
    -moz-transform: translateX(0px) translateY(60px);
    -webkit-transform: translateX(0px) translateY(60px);
    -o-transform: translateX(0px) translateY(60px);
    -ms-transform: translateX(0px) translateY(60px);
    transform: translateX(0px) translateY(60px);
  }
  100% {
    -moz-transform: translateX(0px) translateY(0px);
    -webkit-transform: translateX(0px) translateY(0px);
    -o-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}

@-webkit-keyframes slide-top {
  0% {
    -moz-transform: translateX(0px) translateY(0px);
    -webkit-transform: translateX(0px) translateY(0px);
    -o-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
  25% {
    -moz-transform: translateX(40px) translateY(0px);
    -webkit-transform: translateX(40px) translateY(0px);
    -o-transform: translateX(40px) translateY(0px);
    -ms-transform: translateX(40px) translateY(0px);
    transform: translateX(40px) translateY(0px);
  }
  50% {
    -moz-transform: translateX(40px) translateY(60px);
    -webkit-transform: translateX(40px) translateY(60px);
    -o-transform: translateX(40px) translateY(60px);
    -ms-transform: translateX(40px) translateY(60px);
    transform: translateX(40px) translateY(60px);
  }
  75% {
    -moz-transform: translateX(0px) translateY(60px);
    -webkit-transform: translateX(0px) translateY(60px);
    -o-transform: translateX(0px) translateY(60px);
    -ms-transform: translateX(0px) translateY(60px);
    transform: translateX(0px) translateY(60px);
  }
  100% {
    -moz-transform: translateX(0px) translateY(0px);
    -webkit-transform: translateX(0px) translateY(0px);
    -o-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
}

@media only screen and (max-width: 540px) {
  .about-drone-one-info strong {
    font-size: 30px;
    line-height: 30px;
  }
  .about-drone-one {
    border: 20px solid #d72323;
    padding: 20px;
    margin-right: 0px;
  }
  .about-drone-one-media img {
    position: relative;
    right: auto;
    top: 20%;
    width: 100%;
  }

  .about-drone-three-info span {
    font-size: 40px;
    line-height: 40px;
  }
  .about-drone-three-info strong {
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 3px;
  }
}

@media only screen and (max-width: 400px) {
  .about-drone-one-media {
    height: 250px;
  }
}

/*----quality-section-outer----*/
.quality-section-outer {
  position: relative;
}

.quality-section-content {
  margin-top: 220px;
  position: relative;
  top: -160px;
  margin-bottom: -160px;
}

.quality-video-section {
  padding-bottom: 35px;
  padding-top: 50px;
}

.quality-video-section span {
  display: block;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'Rajdhani', sans-serif;
  letter-spacing: 2px;
  font-weight: 700;
}
.quality-video {
  position: relative;
  padding: 80px 0px;
}

@media only screen and (max-width: 540px) {
  .quality-section-content {
    margin-top: 130px;
    top: -100px;
    margin-bottom: -100px;
  }
  .quality-video {
    padding: 100px 0px 0px 0px;
    margin-top: 40px;
  }
}

/*----quality-section-outer2----*/
.quality-section-outer2 {
  position: relative;
}

.quality-section-content2 {
  margin-top: 220px;
  position: relative;
  top: -160px;
  margin-bottom: -160px;
}

.quality-video-section2 {
  padding-bottom: 35px;
  padding-top: 0px;
}

.quality-video-media {
  position: relative;
}
.quality-video-media img {
  width: 100%;
}

.quality-video2 {
  position: relative;
  margin: 0px 200px 0px 0px;
}
.quality-video2 .play-now-video {
  background-color: #d72323;
  display: inline-block;
  padding: 30px;
  position: relative;
  top: -50px;
  left: 50px;
}
.quality-video2 .play-now-video .icon {
  bottom: 0px;
  left: 0px;
  top: auto;
  position: inherit;
  padding: 0px;
  transform: inherit;
}

.quality-video2 .quality-video-info {
  max-width: 360px;
  position: absolute;
  right: -200px;
  top: 60px;
}

@media only screen and (max-width: 1199px) {
  .quality-video2 {
    margin: 0px;
  }
  .quality-video2 .quality-video-info {
    right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .quality-video2 .quality-video-info {
    position: inherit;
    right: auto;
    max-width: 100%;
    bottom: auto;
    margin-bottom: 30px;
    top: 30px;
  }

  .quality-video2 .play-now-video {
    margin-bottom: -50px;
  }
}

.play-now-video .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 65px;
  width: 65px;
  text-align: center;
  line-height: 65px;
  background-color: #fff;
  color: #d72323;
  z-index: 1;
  font-size: 20px;
  padding-left: 5px;
  display: block;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform-origin: center;
  transform-origin: center center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
  -moz-box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
  -ms-box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
  -o-box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
  box-shadow: 0 5px 10px 0 rgba(255, 255, 255, 0.5);
}

.play-now-video .ripple,
.play-now-video .ripple:before,
.play-now-video .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 65px;
  width: 65px;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform-origin: center;
  transform-origin: center center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgb(255, 255, 255);
  -moz-box-shadow: 0 0 0 0 rgb(255, 255, 255);
  -ms-box-shadow: 0 0 0 0 rgb(255, 255, 255);
  -o-box-shadow: 0 0 0 0 rgb(255, 255, 255);
  box-shadow: 0 0 0 0 rgb(255, 255, 255);
  -webkit-animation: ripple-video 3s infinite;
  -moz-animation: ripple-video 3s infinite;
  -ms-animation: ripple-video 3s infinite;
  -o-animation: ripple-video 3s infinite;
  animation: ripple-video 3s infinite;
  z-index: 1;
}

.play-now-video .ripple:before {
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
  -ms-animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: '';
  position: absolute;
}

.play-now-video .ripple:after {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: '';
  position: absolute;
}

@-webkit-keyframes ripple-video {
  70% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes ripple-video {
  70% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

/*----Product Showcase slider----*/
.product-showcase {
  position: relative;
  font-family: 'Rajdhani', sans-serif;
  padding: 100px 45px 45px 45px;
}

.product-showcase:after {
  content: '';
  position: absolute;
  max-width: 150px;
  border-color: #d72323;
  border-width: 20px 0px 20px 20px;
  border-style: solid;
  height: 100%;
  top: 0px;
  left: 0%;
  bottom: 0px;
  width: 100%;
}
.product-showcase:before {
  content: '';
  position: absolute;
  max-width: 150px;
  border-color: #d72323;
  border-width: 20px 20px 0px 0px;
  border-style: solid;
  top: 0px;
  right: 0%;
  bottom: 20px;
  width: 100%;
}

.product-show-block {
  position: absolute;
  right: 0px;
  bottom: 20px;
}
.product-show-info {
  padding: 30px;
  background-color: #fff;
  min-width: 250px;
}
.product-show-info .p-price {
  font-size: 24px;
  font-weight: 700;
  color: #d72323;
}
.product-show-info .p-price del {
  font-size: 16px;
  color: #404040;
  font-weight: 600;
  padding-left: 5px;
}
.product-show-info .p-specification ul {
  list-style: none;
  margin-bottom: 0px;
}

.product-show-info .p-specification ul li {
  font-size: 16px;
  color: #1a1a1a;
  font-weight: 600;
}
.product-show-info .p-title {
  margin-bottom: 5px;
}
.product-show-info .p-title .wt-title {
  margin-bottom: 0px;
}
.product-show-info .p-specification ul li .p-specification-title {
  font-size: 16px;
  color: #1a1a1a;
  font-weight: 600;
  width: 50%;
  display: inline-block;
}

.product-show-info .p-control {
  text-align: right;
  position: relative;
  top: 50px;
  margin-top: -30px;
}
.product-show-info .p-control ul {
  list-style: none;
  margin-bottom: 0px;
}
.product-show-info .p-control ul li {
  display: inline-block;
}
.product-show-info .p-control ul li a {
  display: block;
  color: #fff;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #1a1a1a;
}
.product-show-info .p-control ul li a:hover {
  background-color: #d72323;
}

.product-one-slider .owl-item .product-show-block {
  display: none;
}

.product-one-slider .owl-item .product-showcase:after,
.product-one-slider .owl-item .product-showcase:before {
  display: none;
}

.product-one-slider .owl-item.active.center .product-show-block {
  display: block;
}

.product-one-slider .owl-item.active.center .product-showcase:after,
.product-one-slider .owl-item.active.center .product-showcase:before {
  display: block;
}

@media only screen and (max-width: 991px) {
  .product-showcase {
    padding: 30px 45px 180px 45px;
  }
}

@media only screen and (max-width: 767px) {
  .product-one-slider {
    margin: 0px auto;
    max-width: 510px;
  }
  .product-show-block {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    z-index: 1;
  }

  .product-show-info .p-control {
    text-align: left;
    position: relative;
    top: auto;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .product-one-slider {
    margin: 0px 15px;
    max-width: 100%;
  }
}

/*----Product Showcase2 slider----*/
.product-showcase2 {
  position: relative;
  font-family: 'Rajdhani', sans-serif;
  padding: 0px;
  margin-bottom: 20px;
}
.product-showcase2 .product-show-media2 img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.product-show-block2 {
  position: absolute;
  right: 0px;
  bottom: 0px;
}
.product-show-info2 {
  padding: 30px;
  background-color: #fff;
  min-width: 250px;
}
.product-show-info2 .p-price2 {
  font-size: 24px;
  font-weight: 700;
  color: #d72323;
}
.product-show-info2 .p-price2 del {
  font-size: 16px;
  color: #404040;
  font-weight: 600;
  padding-left: 5px;
}
.product-show-info2 .p-specification2 ul {
  list-style: none;
  margin-bottom: 0px;
}

.product-show-info2 .p-specification2 ul li {
  font-size: 16px;
  color: #1a1a1a;
  font-weight: 600;
}
.product-show-info2 .p-title2 {
  margin-bottom: 5px;
}
.product-show-info2 .p-title2 .wt-title {
  margin-bottom: 0px;
}
.product-show-info2 .p-specification2 ul li .p-specification-title2 {
  font-size: 16px;
  color: #1a1a1a;
  font-weight: 600;
  width: 50%;
  display: inline-block;
}

.product-show-info2 .p-control2 {
  text-align: right;
  position: relative;
  top: 50px;
  margin-top: -30px;
}
.product-show-info2 .p-control2 ul {
  list-style: none;
  margin-bottom: 0px;
}
.product-show-info2 .p-control2 ul li {
  display: inline-block;
}
.product-show-info2 .p-control2 ul li a {
  display: block;
  color: #fff;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #1a1a1a;
}
.product-show-info2 .p-control2 ul li a:hover {
  background-color: #d72323;
}

.product-one-slider2 .owl-item .product-show-block2 {
  display: none;
}
.product-one-slider2 .owl-item.active.center .product-show-block2 {
  display: block;
}

.product-one-slider2
  .owl-item.active.center
  .product-showcase2
  .product-show-media2
  img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

@media only screen and (max-width: 767px) {
  .product-showcase2 {
    padding-left: 15px;
    padding-right: 15px;
  }
  .product-show-block2 {
    position: inherit;
    right: 0;
    bottom: 0px;
  }

  .product-show-info2 .p-control2 {
    text-align: left;
    top: 10px;
    margin-top: 0;
  }
}
/*----video gallery-----*/

.video-gallery-block-outer {
  left: calc((100% - 1140px) / 2);
  position: relative;
  padding-bottom: 55px;
}

.video-gallery-block {
  position: relative;
  overflow: hidden;
}

.video-media {
  background-color: #000;
}

.video-media-info {
  position: absolute;
  left: 30px;
  bottom: 30px;
}
.video-media-info:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 1px;
  background-color: #fff;
  left: 140%;
  top: 50%;
}

.video-gallery-block:hover .video-media-info:after {
  width: 300px;
}

.video-media-info a {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #fff;
}

.video-media-info a:after,
.video-media-info a:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
}

.video-media img,
.video-media-info:after,
.video-media-info a:after,
.video-media-info a:before {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.video-media-info a:after {
  left: -4px;
  top: -4px;
  border-style: solid;
  border-width: 1px 0px 0px 1px;
  border-color: #fff;
}
.video-media-info a:before {
  right: -4px;
  bottom: -4px;
  border-style: solid;
  border-width: 0px 1px 1px 0px;
  border-color: #fff;
}

.video-gallery-block:hover .video-media-info a:after,
.video-gallery-block:hover .video-media-info a:before {
  width: 100%;
  height: 100%;
}

.video-gallery-block:hover .video-media img {
  opacity: 0.6;
}

/*right*/
.video-gallery-one.owl-carousel.right .owl-dots {
  right: calc((100% - 1140px) / 2);
  left: auto;
  margin-right: 20px;
}

/*center*/
.video-gallery-one.owl-carousel.center .owl-dots {
  left: 0px;
  transform: none;
}
.video-gallery-one.owl-carousel.center .owl-dot.active:before {
  left: 0px;
  margin-left: 0px;
}
.video-gallery-one.owl-carousel.center .owl-dot.active:after {
  left: 24px;
}
.video-gallery-one.owl-carousel.center .owl-dots:after {
  left: 38px;
}

/*left*/
.video-gallery-one.owl-carousel.left .owl-dots {
  left: 0px;
}

@media only screen and (max-width: 1199px) {
  .video-gallery-block-outer {
    left: 0px;
    position: relative;
  }
  .video-gallery-block-outer .container-fluid {
    max-width: 960px;
  }
}

@media only screen and (max-width: 991px) {
  .video-gallery-block-outer .container-fluid {
    max-width: 720px;
  }
}
@media only screen and (max-width: 767px) {
  .video-gallery-block-outer .container-fluid {
    max-width: 540px;
  }
}
/*-----------------------------------------------------------
	owl slider dotts (line style) start
-------------------------------------------------------------*/

.owl-dots {
  counter-reset: slides-num;
  position: absolute;
  top: 100%;
  left: 0px;
  margin-top: 15px;
  display: table;
  width: 350px;
  color: #1a1a1a;
  overflow: hidden;
}
.owl-dots:after {
  content: counter(slides-num);
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  vertical-align: middle;
  padding-left: 0px;
  left: 34px;
  position: absolute;
  top: 0px;
}
.owl-dot {
  counter-increment: slides-num;
  margin-right: 70px;
}
.owl-dot span {
  display: none !important;
}
.owl-dot.active:before {
  content: counter(slides-num);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  left: 0;
  top: 0px;
}

.owl-dot.active:after {
  content: '/';
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  left: 16px;
  top: 0px;
}

.owl-carousel .owl-dots .owl-dot span {
  width: 100%;
  height: 2px;
  margin: 30px 1px 0px 1px;
  background: #d72323;
  display: block;
  -webkit-backface-visibility: visible;
  -webkit-transition: opacity 200ms ease;
  -moz-transition: opacity 200ms ease;
  -ms-transition: opacity 200ms ease;
  -o-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  border-radius: 0px;
  display: inline-block !important;
}

.owl-carousel .owl-dots .owl-dot.active span {
  background: #000;
}
.owl-dots .owl-dot {
  display: table-cell;
  margin-right: 0px;
}

@media only screen and (max-width: 420px) {
  .owl-dots {
    width: 100%;
    max-width: 260px;
  }
}

/* Owl carousel dots line background light */
.light-dotts-line.owl-carousel .owl-dots .owl-dot span {
  background: #fff;
}
.light-dotts-line.owl-carousel .owl-dots .owl-dot.active span {
  background: #000;
}
.light-dotts-line.owl-carousel .owl-dots .owl-dot.active:before,
.light-dotts-line.owl-carousel .owl-dots .owl-dot.active:after {
  color: #fff;
}
.light-dotts-line.owl-carousel .owl-dots:after {
  color: #fff;
}

/* Owl carousel dots line background light */
.dark-dotts-line.owl-carousel .owl-dots .owl-dot span {
  background: #000;
}
.dark-dotts-line.owl-carousel .owl-dots .owl-dot.active span {
  background: #d72323;
}
.dark-dotts-line.owl-carousel .owl-dots .owl-dot.active:before,
.dark-dotts-line.owl-carousel .owl-dots .owl-dot.active:after {
  color: #000;
}
.dark-dotts-line.owl-carousel .owl-dots:after {
  color: #000;
}

/*Center*/
.owl-carousel.center .owl-dots {
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
}
.owl-carousel.center .owl-dot.active:before {
  left: 50%;
  margin-left: -24px;
}
.owl-carousel.center .owl-dot.active:after {
  left: 50%;
  margin-left: 4px;
}
.owl-carousel.center .owl-dots:after {
  left: 50%;
  margin-left: 20px;
}

/*right*/
.owl-carousel.right .owl-dots {
  right: 20px;
  left: auto;
}
.owl-carousel.right .owl-dot.active:before {
  right: 38px;
  left: auto;
}
.owl-carousel.right .owl-dot.active:after {
  right: 24px;
  left: auto;
}
.owl-carousel.right .owl-dots:after {
  right: 0px;
  left: auto;
}

.image-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  grid-template-rows: repeat(3, auto); /* 3 rows */
  gap: 30px; /* Adjust the spacing between items */
}

.image-item {
  width: 100%;
  height: auto;
}

/*left*/
.owl-carousel.left .owl-dots {
  left: 20px;
}
.owl-carousel.left .owl-dot.active:before {
  left: 0px;
  margin-left: 0px;
}
.owl-carousel.left .owl-dot.active:after {
  left: 24px;
}
.owl-carousel.left .owl-dots:after {
  left: 38px;
}

/*-----------------------------------------------------------
	owl slider dotts (line style) End
-------------------------------------------------------------*/

/*how it work section two--*/
.how-it-drone2-left,
.how-it-drone2-right {
  margin-top: 40px;
}
.how-it-drone2-outer {
  position: relative;
}

.how-it-drone2-outer .how-it-drone2 {
  height: 100%;
}
.how-it-drone2-outer .how-it-drone2:after,
.how-it-drone2-outer .how-it-drone2:before {
  content: '';
  position: absolute;
}
.how-it-drone2-outer .how-it-drone2:after {
  right: 15px;
  left: 15px;
  top: 0px;
  border-style: solid;
  border-width: 15px 15px 0px 15px;
  border-color: #d72323;
  height: 110px;
}
.how-it-drone2-outer .how-it-drone2:before {
  right: 15px;
  left: 15px;
  bottom: 0px;
  border-style: solid;
  border-width: 0px 15px 15px 15px;
  border-color: #d72323;
  height: 110px;
}

.how-it-drone2-outer .how-it-drone2 .how-it-drone-media2 {
  text-align: center;
  position: absolute;
  top: 20%;
  width: 100%;
  left: 0px;
  z-index: 1;
}
.how-it-drone2-outer .how-it-drone2 .how-it-drone-media2 img {
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .how-it-drone2-outer .how-it-drone2 .how-it-drone-media2 {
    position: inherit;
    padding: 100px 0px;
  }
  .how-it-drone2-outer .how-it-drone2 .how-it-drone-media2 img {
    width: 100%;
    max-width: 300px;
  }
  .how-it-drone3-left .d-icon-box-two:last-child {
    margin-bottom: 0px;
  }
  .how-it-drone3-right {
    margin-bottom: 0px;
  }

  .how-it-drone3-right .d-icon-box-two:last-child {
    margin-bottom: 30px;
  }

  .how-it-drone3-left .wt-icon-box-wraper.right {
    text-align: left;
  }

  .how-it-drone3-left .wt-icon-box-wraper.right .icon-md {
    float: left;
    margin-left: 0px;
    margin-right: 20px;
  }

  .how-it-drone2-left {
    margin: 0px;
  }
  .how-it-drone2-right {
    margin: 0px;
  }

  .how-it-drone2-right .d-icon-box-two:last-child {
    margin-bottom: 0px;
  }

  .how-it-drone2-left .wt-icon-box-wraper.right {
    text-align: left;
  }
  .how-it-drone2-left .wt-icon-box-wraper.right .icon-md {
    float: left;
    margin-left: 0px;
    margin-right: 20px;
  }
}
@media only screen and (max-width: 400px) {
  .how-it-drone2-outer .how-it-drone2 .how-it-drone-media2 {
    position: inherit;
    padding: 100px 0px;
  }
  .how-it-drone2-outer .how-it-drone2 .how-it-drone-media2 img {
    max-width: 230px;
  }
}

/*----background half-right----*/
.bg-half-right {
  background-position: calc(50% + 1040px);
  background-size: cover;
}

@media only screen and (max-width: 991px) {
  .bg-half-right {
    background-image: none !important;
  }
}

/*----Video style2 section----*/
.video-style2-outer {
  overflow: hidden;
}

.video-style2-ring-outer {
  border: 1px solid #ddd;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  height: 100%;
  position: absolute;
  left: 15px;
  right: 15px;
  top: 0px;
  bottom: 0px;
}

.dot-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  background-color: #d72323;
}
.dot-circle.one,
.dot-circle.two,
.dot-circle.three {
  position: absolute;
}

.dot-circle.one {
  top: 10%;
  left: 16%;
}
.dot-circle.two {
  bottom: 40px;
  right: 78%;
}
.dot-circle.three {
  top: 35%;
  right: 0px;
}

.video-style2-media {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  overflow: hidden;
  margin: 35px;
}
.video-style2-media img {
  width: 100%;
}

.video-style2-left p {
  margin-bottom: 30px;
}

.video-style2-right {
  padding-left: 50px;
}
@media only screen and (max-width: 991px) {
  .video-style2-right {
    padding-left: 0px;
  }
  .dot-circle.two {
    right: 74%;
  }
}
@media only screen and (max-width: 991px) {
  .video-style2-media {
    margin: 15px;
  }
}

/*---spin animation---*/
.rotate-center {
  -webkit-animation: rotate-center 20s linear infinite both;
  animation: rotate-center 20s linear infinite both;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 767px) {
  .dot-circle.one {
    left: 16%;
  }
  .dot-circle.two {
    right: 78%;
  }
}
/*---- Gallery large Slider Start----*/
.gallery-large-block-outer {
  position: relative;
  padding: 50px 0px 0px 50px;
  z-index: 1;
  margin-top: 40px;
  margin-bottom: 40px;
}
.gallery-large-block-outer:before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  border: 10px solid #d52323;
  max-width: 320px;
  width: 100%;
  bottom: 100px;
}

.gallery-large-info {
  position: absolute;
  bottom: 40px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
}

.gallery-large-control {
  position: relative;
  margin-bottom: 15px;
}
.g-title {
  margin-bottom: 0px;
}
.gallery-large-control ul {
  list-style: none;
  margin-bottom: 0px;
}
.gallery-large-control ul li {
  display: inline-block;
}
.gallery-large-control ul li a {
  display: block;
  color: #fff;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #d72323;
}
.gallery-large-control ul li a:hover {
  background-color: #1a1a1a;
}

.gallery-large-thumb {
  max-width: 400px;
  width: 100%;
  position: absolute;
  right: 30px;
  bottom: -35px;
}
.gallery-large-thumb .gallery-thumb-media {
  cursor: pointer;
}

@media only screen and (max-width: 991px) {
  .gallery-large-thumb {
    position: inherit;
    right: auto;
    bottom: 0px;
  }
  .gallery-large-block-outer {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .site-button.site-btn-effect.desktop {
    display: none;
  }

  .logo-header-inner img {
    margin-top: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .gallery-large-block-outer {
    padding: 20px 0px 0px 0px;
    margin-bottom: 0px;
  }

  .gallery-large-info {
    left: 15px;
    bottom: 20px;
    right: 15px;
    position: inherit;
    background-color: #000;
    border: 1px solid #5a5a5a;
    margin: 10px 0px;
  }
  .gallery-large-block-outer:before {
    display: none;
  }

  .tp-caption.tp-resizeme.home-1-change-img-slide1.drone-img,
  .tp-caption.tp-resizeme.dron-img-2 {
    display: none;
  }
}

@media only screen and (max-width: 420px) {
  .gallery-large-info {
    position: inherit;
  }
  .gallery-large-block-outer:before {
    display: none;
  }

  .gallery-large-block-outer {
    padding: 0px 0px 0px 0px;
    margin-bottom: 0px;
  }
}

/*---- Gallery large Slider End----*/

/*---- Services detail large Slider Start----*/
.services-large-block-outer {
  position: relative;
  padding: 50px 0px 0px 50px;
  z-index: 1;
  margin-bottom: 70px;
}
.services-large-block-outer:before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  border: 10px solid #d52323;
  max-width: 320px;
  width: 100%;
  bottom: 100px;
}

.services-large-info {
  position: absolute;
  bottom: 40px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
}
.service-detail-for {
  background-color: #fff;
}

.services-large-control {
  position: relative;
  margin-bottom: 15px;
}
.services-title {
  margin-bottom: 0px;
}
.services-large-control ul {
  list-style: none;
  margin-bottom: 0px;
}
.services-large-control ul li {
  display: inline-block;
}
.services-large-control ul li a {
  display: block;
  color: #fff;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #d72323;
}
.services-large-control ul li a:hover {
  background-color: #1a1a1a;
}

.services-large-thumb {
  max-width: 400px;
  width: 100%;
  position: absolute;
  right: 30px;
  bottom: -35px;
}
.services-large-thumb .services-thumb-media {
  cursor: pointer;
}

.service-detail-single-list {
  padding: 0px 0px 0px 20px;
}
.service-detail-single-list li {
  font-size: 18px;
  font-weight: 600;
  padding: 0px 0px 0px 30px;
  margin-bottom: 15px;
}
.service-detail-single-list li:last-child {
  margin-bottom: 0px;
}
.service-detail-single-list ul.list-check li:before {
  color: #d72323;
}

.services-etc blockquote {
  max-width: 700px;
  margin: 40px auto;
}

@media only screen and (max-width: 991px) {
  .service-detail-single-list {
    padding: 30px 0px 0px 0px;
  }
  .services-large-thumb {
    max-width: 280px;
    right: 15px;
    bottom: -20px;
  }
  .services-large-info .services-title {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .services-large-block-outer {
    padding: 0px;
    margin-bottom: 40px;
  }
  .services-large-thumb {
    max-width: 280px;
    right: inherit;
    bottom: auto;
    position: inherit;
  }

  .services-large-info {
    position: absolute;
    left: 0px;
    bottom: 6px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
  }
  .services-large-block-outer:before {
    display: none;
  }
}
@media only screen and (max-width: 540px) {
  .service-detail-single-list li {
    font-size: 14px;
  }
}

/*---- Services detail large Slider End----*/

/*-----Services image effect-----*/
.grayscale-img img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.grayscale-img:hover img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

.imghvr-zoom-out-down {
  position: relative;
  display: block;
  margin: 0;
  max-width: 100%;
  background-color: #ddd;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.imghvr-zoom-out-down img {
  width: 100%;
  vertical-align: top;
  max-width: 100%;
}

.imghvr-zoom-out-down .imghvr-content {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 25px;
  position: absolute;
  top: 15px;
  bottom: 15px;
  left: 15px;
  right: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

[class*='imghvr-'],
[class^='imghvr-'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.imghvr-zoom-out-down .imghvr-content {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: 0;
}

[class*='imghvr-zoom-out-down']:hover .imghvr-content,
[class^='imghvr-zoom-out-down']:hover .imghvr-content {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.imghvr-mid {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 20px;
  right: 20px;
}

.imghvr-zoom-out-down:hover img {
  -webkit-animation: imghvr-zoom-out-down 0.4s linear;
  animation: imghvr-zoom-out-down 0.4s linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes imghvr-zoom-out-down {
  50% {
    -webkit-transform: scale(0.8) translateY(0);
    transform: scale(0.8) translateY(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.8) translateY(150%);
    transform: scale(0.8) translateY(150%);
    opacity: 0.5;
  }
}
@keyframes imghvr-zoom-out-down {
  50% {
    -webkit-transform: scale(0.8) translateY(0);
    transform: scale(0.8) translateY(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.8) translateY(150%);
    transform: scale(0.8) translateY(150%);
    opacity: 0.5;
  }
}

/*-----service style two----*/
.services-style-two .row {
  align-items: center;
  background-color: #f8f8f8;
}
.services-style-two .row .service-style2-detail {
  padding: 50px;
}

.services-style-two .row:nth-child(even) {
  flex-direction: row-reverse;
}

@media only screen and (max-width: 991px) {
  .services-style-two .row .service-style2-detail {
    padding: 30px;
  }
}

/*----Product Price Range Slider Start----*/
.product-range-slider1 #amount {
  margin-bottom: 20px;
}
.ui-progressbar {
  height: 2em;
  text-align: left;
  overflow: hidden;
}
.ui-progressbar .ui-progressbar-value {
  margin: -1px;
  height: 100%;
}

.ui-progressbar-indeterminate .ui-progressbar-value {
  background-image: none;
}
.ui-slider {
  position: relative;
  text-align: left;
}
.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 1.2em;
  height: 1.2em;
  cursor: default;
  -ms-touch-action: none;
  touch-action: none;
}
.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: 0.7em;
  display: block;
  border: 0;
  background-position: 0 0;
  color: red;
}

.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range {
  filter: inherit;
}

.ui-slider-horizontal {
  height: 0.8em;
}
.ui-slider-horizontal .ui-slider-handle {
  top: -0.3em;
  margin-left: -0.6em;
}
.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.ui-widget-content {
  border: 1px solid #dddddd;
  background: #fff;
}
.ui-widget-header {
  border: 1px solid #e78f08;
  background: #d72323;
}
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: 1px solid #cccccc;
  background: #f6f6f6;
}
/*----Prroduct Price Range Slider End----*/

/*-----Gallery Inner Page effect-----*/

.galleryhvr-zoom-out-down {
  position: relative;
  display: block;
  margin: 0;
  max-width: 100%;
  background-color: #d72323;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.galleryhvr-zoom-out-down img {
  vertical-align: top;
  max-width: 100%;
  width: 100%;
}

.galleryhvr-zoom-out-down .galleryhvr-content {
  background-color: #fff;
  padding: 25px;
  position: absolute;
  top: 15px;
  bottom: 15px;
  left: 15px;
  right: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

[class*='galleryhvr-'],
[class^='galleryhvr-'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.galleryhvr-zoom-out-down .galleryhvr-content {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  opacity: 0;
}

[class*='galleryhvr-zoom-out-down']:hover .galleryhvr-content,
[class^='galleryhvr-zoom-out-down']:hover .galleryhvr-content {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.galleryhvr-mid {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 20px;
  right: 20px;
}
.galleryhvr-mid a {
  display: inline-block;
  color: #fff;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #d72323;
}

.galleryhvr-mid a:hover {
  background-color: #1a1a1a;
}
.galleryhvr-zoom-out-down:hover img {
  -webkit-animation: galleryhvr-zoom-out-down 0.4s linear;
  animation: galleryhvr-zoom-out-down 0.4s linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes galleryhvr-zoom-out-down {
  50% {
    -webkit-transform: scale(0.8) translateY(0);
    transform: scale(0.8) translateY(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.8) translateY(150%);
    transform: scale(0.8) translateY(150%);
    opacity: 0.5;
  }
}
@keyframes galleryhvr-zoom-out-down {
  50% {
    -webkit-transform: scale(0.8) translateY(0);
    transform: scale(0.8) translateY(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.8) translateY(150%);
    transform: scale(0.8) translateY(150%);
    opacity: 0.5;
  }
}

/*---Single Deatil---*/
.gallery-detail-single-list {
  padding: 0px;
}
.gallery-detail-single-list li {
  font-size: 18px;
  font-weight: 600;
  padding: 0px 0px 0px 30px;
  margin-bottom: 15px;
}
.gallery-detail-single-list li:last-child {
  margin-bottom: 0px;
}
.gallery-detail-single-list ul.list-check li:before {
  color: #d72323;
}
@media only screen and (max-width: 540px) {
  .gallery-detail-single-list li {
    font-size: 14px;
  }
}

/*----Hover Background---*/

.card1 {
  z-index: 1;
  overflow: hidden;
}
.card1:before {
  content: '';
  position: absolute;
  z-index: -1;
  bottom: -32px;
  left: -32px;
  background: #d72323;
  height: 32px;
  width: 32px;
  border-radius: 32px;

  -webkit-transform: scale(1);
  transform: scale(1);

  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;

  -webkit-transition: -webkit-transform 1s ease-out;
  transition: -webkit-transform 1s ease-out;

  transition: transform 1s ease-out;
  transition: transform 1s ease-out, -webkit-transform 1s ease-out;
}
.card1:hover:before {
  -webkit-transform: scale(50);
  transform: scale(50);
}

.card1 .site-text-primary,
.card1 .s-title-one,
.card1 .d-icon-box-content p {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.wt-info .site-text-primary {
  text-decoration: underline;
  cursor: pointer;
}

.card1:hover .site-text-primary,
.card1:hover .s-title-one,
.card1:hover .d-icon-box-content p {
  color: #fff;
}

.card1:hover .site-button-link:before {
  background-color: #fff;
}
