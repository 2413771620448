/* Toast.css */
.toast-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
}

.toast {
    background: #fff;
    color: #333;
    border-radius: 4px;
    padding: 15px;
    margin: 10px 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s, fadeOut 0.5s 4.5s;
}

.toast-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toast-close {
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 16px;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}
